import create from "zustand";
import { Result } from "../interfaces/event-places-nearby.interface";
import {
  EventPlacesNearbyParam,
  GetEventPlacesNearby,
} from "../providers/event-places-nearby.provider";

interface EventPlacesNearbyState {
  eventPlacesNearbyItems: Result[];
  eventPlacesNearbyLoading: boolean;
  getEventPlacesNearby: (
    eventPlacesNearbyParam: EventPlacesNearbyParam
  ) => void;
}

export const useEventPlacesNearbyStore = create<EventPlacesNearbyState>(
  (set, get) => ({
    eventPlacesNearbyItems: [],
    eventPlacesNearbyLoading: false,
    getEventPlacesNearby: async (
      eventPlacesNearbyParam: EventPlacesNearbyParam
    ) => {
      set({ eventPlacesNearbyLoading: true });
      const result = await GetEventPlacesNearby(eventPlacesNearbyParam);
      set({ eventPlacesNearbyItems: result.data.results });
    },
  })
);

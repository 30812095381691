import GoogleMapReact from "google-map-react";
import { useEffect, useRef, useState } from "react";
import { useDisasterDetailStore } from "../../disaster/states/disaster.state";
import { useEventPhotoStore } from "../../disaster/states/event-photo.state";
import { useEventVideoStore } from "../../disaster/states/event-video.state";
import { useTrackingStore } from "../../trackings/states/tracking.state";
import { useDisasterMapStore } from "../states/disaster-maps.state";
import Marker, { MarkerHospital, MarkerUser } from "./marker.component";
import ModalPhotoVideoComponent from "./modal-photo-video.component";
import MarkerClusterer from "@google/markerclusterer";
import { useEventStore } from "../../home/states/disaster-event.state";
import { useModalPhotoVideoStore } from "../../disaster/states/modal-photo-video.state";
import { useResourceStore } from "../../home/states/resource.state";
import { MarkerResource } from "./resource-maps.component";
import { useEventPlacesNearbyStore } from "../../disaster/states/event-places-nearby.state";

let selectedShape = undefined;
let selectedCircle = undefined;
let selectedPolyline = undefined;
let selectedMarker = undefined;
let markers: google.maps.Marker[] = [];
let circles: google.maps.Circle[] = [];
let polylines: google.maps.Polyline[] = [];
let rectangles: google.maps.Rectangle[] = [];

const deleteSelectedShape = (e) => {
  e.preventDefault();
  if (selectedShape) {
    var circleIndex = circles.indexOf(selectedShape);
    if (circleIndex >= 0) {
      circles.splice(circleIndex, 1);
      selectedShape.setMap(null);
      console.log("successfully removed", selectedShape.getRadius());
      console.log(
        "circles",
        circles.map((item) => item.getRadius())
      );
    }
    var markerIndex = markers.indexOf(selectedShape);
    if (markerIndex >= 0) {
      markers.splice(markerIndex, 1);
      selectedShape.setMap(null);
      console.log("successfully removed", selectedShape);
      console.log(
        "markers",
        markers.map((item) => item)
      );
    }
    var polylineIndex = polylines.indexOf(selectedShape);
    if (polylineIndex >= 0) {
      polylines.splice(polylineIndex, 1);
      selectedShape.setMap(null);
      console.log("successfully removed", selectedShape);
      console.log(
        "polylines",
        polylines.map((item) => item)
      );
    }
    var rectangleIndex = rectangles.indexOf(selectedShape);
    if (rectangleIndex >= 0) {
      rectangles.splice(rectangleIndex, 1);
      selectedShape.setMap(null);
      console.log("successfully removed", selectedShape);
      console.log(
        "rectangles",
        rectangles.map((item) => item)
      );
    }
  }
};

const photoIcon = require("../../../img/marker-detail/photo.png");
const videoIcon = require("../../../img/marker-detail/video.png");

const DisasterMapComponent = () => {
  const { apiKey, defaultCoords, handleChange, setMap } = useDisasterMapStore();
  const {
    getResourceNearbyTni,
    getResourceNearbyPolri,
    getResourceNearbyPln,
    resourceNearbyTniItems,
    resourceNearbyPolriItems,
    resourceNearbyPlnItems,
  } = useResourceStore();
  const googleMapsRef = useRef<GoogleMapReact>(null);
  const mapRef = useRef();
  const { disasterData } = useDisasterDetailStore();
  const { getEventPhoto, eventPhotoData } = useEventPhotoStore();
  const { getEventVideo, eventVideoData } = useEventVideoStore();
  const { isOnDetail } = useEventStore();
  const { trackingUsers, trackingSingleUser } = useTrackingStore();
  const { getEventPlacesNearby, eventPlacesNearbyItems } =
    useEventPlacesNearbyStore();
  const [theMap, setTheMap] = useState<any>(undefined);
  const [theMaps, setTheMaps] = useState<any>(undefined);
  const { handleOpen } = useModalPhotoVideoStore();

  useEffect(() => {
    if (disasterData !== undefined) {
      getEventPlacesNearby({
        lat: disasterData?.lat,
        lng: disasterData?.lng,
        type: "hospital",
      });
    }
  }, [disasterData]);

  useEffect(() => {
    if (disasterData !== undefined) {
      getResourceNearbyTni({
        lat: disasterData.lat,
        lng: disasterData.lng,
        radius: 20,
        resource_type_group_id: "2ae0ae08-9712-11ed-a8fc-0242ac120002",
      });
      getResourceNearbyPolri({
        lat: disasterData.lat,
        lng: disasterData.lng,
        radius: 20,
        resource_type_group_id: "2ae0ab4c-9712-11ed-a8fc-0242ac120002",
      });
      getResourceNearbyPln({
        lat: disasterData.lat,
        lng: disasterData.lng,
        radius: 20,
        resource_type_group_id: "2ae0b632-9712-11ed-a8fc-0242ac120002",
      });
    }
  }, [disasterData]);

  useEffect(() => {
    console.log("zxczxc", trackingSingleUser);
  }, [trackingSingleUser]);

  useEffect(() => {
    if (disasterData !== undefined) {
      getEventPhoto({
        event_id: disasterData.id,
        page: 0,
        size: 1000,
      });
      getEventVideo({
        event_id: disasterData.id,
        page: 0,
        size: 1000,
      });
    }
  }, [disasterData]);

  useEffect(() => {
    if (theMap !== undefined && theMaps !== undefined) {
      if (eventPhotoData?.length > 0 || eventVideoData?.length > 0) {
        handleMarkers(theMap, theMaps);
      }
    }
  }, [theMap, theMaps, eventPhotoData, eventVideoData]);

  const handleMarkers = (map: any, maps: any) => {
    const OverlappingMarkerSpiderfier = require("overlapping-marker-spiderfier");
    var oms = new OverlappingMarkerSpiderfier(map, {
      markersWontMove: true,
      markersWontHide: true,
      basicFormatEvents: true,
      nearbyDistance: 40,
      circleSpiralSwitchover: 8,
      spiralFootSeparation: 20,
      spiralLengthStart: 16,
      spiralLengthFactor: 12,
      circleFootSeparation: 50,
      circleStartAngle: 180,
      keepSpiderfied: true,
    });

    let allMarkers = [];

    const openPhotoVideoMarkerModal = (marker) => {
      if (theMap !== undefined && theMaps !== undefined) {
        if (marker.markerType === "Foto") {
          oms.addListener("click", function (marker) {
            handleOpen(
              marker?.markerType,
              marker?.lat,
              marker?.lng,
              marker?.name,
              marker?.media,
              marker?.title,
              marker?.dateTime,
              marker?.userId,
              marker?.id,
              marker?.eventItems,
              marker?.district,
              marker?.subdistrict,
              marker?.village
            );
          });
        } else if (marker.markerType === "Video") {
          oms.addListener("click", function (marker) {
            handleOpen(
              marker?.markerType,
              marker?.lat,
              marker?.lng,
              marker?.name,
              marker?.media,
              marker?.title,
              marker?.dateTime,
              marker?.userId,
              marker?.id,
              marker?.eventItems,
              marker?.district,
              marker?.subdistrict,
              marker?.village
            );
          });
        }
      }
    };

    if (eventPhotoData?.length > 0) {
      eventPhotoData.map((item) => {
        const data = {
          lat: parseFloat(item?.lat),
          lng: parseFloat(item?.lng),
          url: photoIcon,
          name: item?.content,
          media: item?.media_id,
          title: item?.title,
          dateTime: item?.createdAt,
          userId: item?.user_id,
          id: item?.id,
          eventItems: item?.event_items,
          district: item?.district,
          subdistrict: item?.subdistrict,
          village: item?.village,
          markerType: "Foto",
        };
        allMarkers.push(data);
      });
    }

    if (eventVideoData?.length > 0) {
      eventVideoData.map((item) => {
        const data = {
          lat: parseFloat(item?.lat),
          lng: parseFloat(item?.lng),
          url: videoIcon,
          name: item?.content,
          media: item?.media_id,
          title: item?.title,
          dateTime: item?.createdAt,
          userId: item?.user_id,
          id: item?.id,
          eventItems: item?.event_items,
          district: item?.district,
          subdistrict: item?.subdistrict,
          village: item?.village,
          markerType: "Video",
        };
        allMarkers.push(data);
      });
    }

    const markerCluster = new MarkerClusterer(map, [], {
      imagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
      maxZoom: 15,
      minimumClusterSize: 2,
    });

    allMarkers?.map((item) => {
      const markerPhotoVideo = new maps.Marker({
        position: { lat: item?.lat, lng: item?.lng },
        icon: item?.url,
        markerType: item?.markerType,
        lat: item?.lat,
        lng: item?.lng,
        name: item?.name,
        media: item?.media,
        title: item?.title,
        dateTime: item?.dateTime,
        userId: item?.userId,
        id: item?.id,
        eventItems: item?.eventItems,
        district: item?.district,
        subdistrict: item?.subdistrict,
        village: item?.village,
      });
      openPhotoVideoMarkerModal(markerPhotoVideo);
      if (item?.lat !== 0 && item?.lng !== 0) {
        oms.addMarker(markerPhotoVideo);
        markerCluster.addMarker(markerPhotoVideo);
      }
    });
  };

  const handleGoogleMapApi = (map: any, maps: any) => {
    if (map !== undefined && maps !== undefined) {
      const polyOptions = {
        label: "asd",
        strokeWeight: 0,
        fillOpacity: 0.45,
        fillColor: "#FF8C00",
        editable: true,
        draggable: true,
      };
      const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      let labelIndex = 0;
      const drawingManager = new maps.drawing.DrawingManager({
        drawingControl: true,
        drawingControlOptions: {
          position: google.maps.ControlPosition.BOTTOM_CENTER,
          drawingModes: [
            google.maps.drawing.OverlayType.MARKER,
            google.maps.drawing.OverlayType.CIRCLE,
            google.maps.drawing.OverlayType.POLYLINE,
            google.maps.drawing.OverlayType.RECTANGLE,
          ],
        },
        markerOptions: {
          draggable: true,
          label: {
            color: "#fff",
            fontWeight: "bold",
            strokeColor: "#fff",
            strokeWeight: 2,
            text: "test label",
          },
          icon: {
            size: new maps.Size(50, 50),
            origin: new maps.Point(0, 0),
            anchor: new maps.Point(18, 15),
            url: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
          },
        },
        polylineOptions: {
          editable: false,
          draggable: false,
          strokeWeight: 4,
          strokeOpacity: 0.45,
          strokeColor: "#FF8C00",
        },
        rectangleOptions: polyOptions,
        circleOptions: polyOptions,
        polygonOptions: polyOptions,
      });
      drawingManager.setMap(map);

      const setSelection = (shape: any) => {
        if (shape.type !== "marker") {
          clearSelection();
          shape.setEditable(true);
        }
        selectedShape = shape;
        console.log(shape.type);
      };

      const clearSelection = () => {
        if (selectedShape) {
          if (selectedShape.type !== "marker") {
            selectedShape.setEditable(false);
          }
          selectedShape = null;
        }
      };

      //listen marker
      maps.event.addListener(
        drawingManager,
        "markercomplete",
        function (marker: any) {
          markers.push(marker);
          console.log("markers", markers);
        }
      );

      // listen circle
      maps.event.addListener(
        drawingManager,
        "circlecomplete",
        function (circle: any) {
          circles.push(circle);
          console.log(
            "circles",
            circles.map((item) => item.getRadius())
          );
        }
      );

      //listen polyline
      maps.event.addListener(
        drawingManager,
        "polylinecomplete",
        function (polyline: any) {
          polylines.push(polyline);
          console.log("polyline", polylines);
        }
      );

      //listen rectangle
      maps.event.addListener(
        drawingManager,
        "rectanglecomplete",
        function (rectangle: any) {
          rectangles.push(rectangle);
          console.log("rectangles", rectangles);
        }
      );

      //reset cursor after finished drawing
      maps.event.addListener(drawingManager, "overlaycomplete", function (e) {
        const newShape = e.overlay;
        newShape.type = e.type;

        if (e.type !== maps.drawing.OverlayType.MARKER) {
          drawingManager.setDrawingMode(null);

          maps.event.addListener(newShape, "click", function (e) {
            if (e.vertex !== undefined) {
              if (newShape.type === maps.drawing.OverlayType.POLYGON) {
                var path = newShape.getPaths().getAt(e.path);
                path.removeAt(e.vertex);
                if (path.length < 3) {
                  newShape.setMap(null);
                }
              }
              if (newShape.type === maps.drawing.OverlayType.POLYLINE) {
                var path = newShape.getPath();
                path.removeAt(e.vertex);
                if (path.length < 2) {
                  newShape.setMap(null);
                }
              }
            }
            setSelection(newShape);
          });
          setSelection(newShape);
        } else {
          maps.event.addListener(newShape, "click", function (e) {
            setSelection(newShape);
          });
          setSelection(newShape);
        }
      });
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: -999,
      }}
    >
      {apiKey !== undefined && disasterData !== undefined && isOnDetail ? (
        <GoogleMapReact
          ref={googleMapsRef}
          bootstrapURLKeys={{
            key: apiKey,
            libraries: "drawing",
          }}
          defaultCenter={{
            lat: defaultCoords.lat,
            lng: defaultCoords.lng,
          }}
          center={{
            lat: +disasterData?.lat,
            lng: +disasterData?.lng,
          }}
          zoom={15}
          options={(maps) => ({
            mapTypeId: "hybrid",
            fullscreenControl: false,
            zoomControl: true,
            zoomControlOptions: {
              position: maps.ControlPosition.RIGHT_BOTTOM,
            },
            mapTypeControl: true,
            mapTypeControlOptions: {
              style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
              position: maps.ControlPosition.RIGHT_BOTTOM,
            },
          })}
          yesIWantToUseGoogleMapApiInternals
          onChange={handleChange}
          onGoogleApiLoaded={({ map, maps }) => {
            setMap(map);
            setTheMap(map);
            setTheMaps(maps);
            handleGoogleMapApi(map, maps);
          }}
        >
          {/* Marker */}
          <Marker
            lat={parseFloat(disasterData?.lat)}
            lng={parseFloat(disasterData?.lng)}
            icon={disasterData?.event_type?.icon}
            icon2={disasterData?.event_type?.icon.replace(".png", "2.png")}
            isTerra={disasterData?.is_terra}
            event={disasterData?.name}
            desc={disasterData?.description}
            prov={disasterData?.province?.name}
            city={disasterData?.city?.name}
            dateTime={disasterData?.date_time}
            eventCode={disasterData?.event_code}
            eventDetail={disasterData}
            csrAssistance={disasterData?.total_csr_assistance}
            totalPhotos={disasterData?.total_photos}
          />

          {/* Marker Hospital */}
          {eventPlacesNearbyItems?.map((item, index) => {
            return (
              <MarkerHospital
                key={index}
                lat={item?.geometry?.location?.lat}
                lng={item?.geometry?.location?.lng}
                name={item?.name}
                location={item?.vicinity}
              />
            );
          })}

          {resourceNearbyPolriItems?.map((item, index) => {
            if (
              item?.lat === "" ||
              item?.lng === "" ||
              item?.lat === "0" ||
              item?.lng === "0"
            ) {
              return <></>;
            }
            return (
              <MarkerResource
                key={index}
                resourceName={item?.group_name}
                name={item?.resource_name}
                location={item?.resource_location}
                lat={+item?.lat ?? 0}
                lng={+item?.lng ?? 0}
                icon={
                  <img
                    src={require(`../../../img/icons_new/${item?.icon}`)}
                  ></img>
                }
              />
            );
          })}

          {resourceNearbyTniItems?.map((item, index) => {
            if (
              item?.lat === "" ||
              item?.lng === "" ||
              item?.lat === "0" ||
              item?.lng === "0"
            ) {
              return <></>;
            }
            return (
              <MarkerResource
                key={index}
                resourceName={item?.group_name}
                name={item?.resource_name}
                location={item?.resource_location}
                lat={+item?.lat ?? 0}
                lng={+item?.lng ?? 0}
                icon={
                  <img
                    src={require(`../../../img/icons_new/${item?.icon}`)}
                  ></img>
                }
              />
            );
          })}

          {resourceNearbyPlnItems?.map((item, index) => {
            if (
              item?.lat === "" ||
              item?.lng === "" ||
              item?.lat === "0" ||
              item?.lng === "0"
            ) {
              return <></>;
            }
            return (
              <MarkerResource
                key={index}
                resourceName={item?.group_name}
                name={item?.resource_name}
                location={item?.resource_location}
                lat={+item?.lat ?? 0}
                lng={+item?.lng ?? 0}
                icon={
                  <img
                    src={require(`../../../img/icons_new/${item?.icon}`)}
                  ></img>
                }
              />
            );
          })}

          {/* Photo */}
          {/* {eventPhotoData?.map((item, index) => {
            return (
              <MarkerPhoto
                key={index}
                lat={+item?.lat}
                lng={+item?.lng}
                name={item?.content}
                media={item?.media_id}
                title={item?.title}
                dateTime={item?.createdAt}
                userId={item?.user_id}
                id={item?.id}
              />
            );
          })} */}

          {/* Video */}
          {/* {eventVideoData?.map((item, index) => {
            return (
              <MarkerVideo
                key={index}
                lat={+item?.lat}
                lng={+item?.lng}
                name={item?.content}
                media={item?.media_id}
                title={item?.title}
                dateTime={item?.createdAt}
                userId={item?.user_id}
                id={item?.id}
              />
            );
          })} */}

          {trackingUsers?.map((item, index) => {
            return (
              <MarkerUser
                key={index}
                fullName={item.user?.fullName ?? "Fulan"}
                lat={item.lat}
                lng={item.lng}
                group={item.user?.user_detail?.user_organization_id?.name ?? ""}
                phone={item.user?.phone_number ?? ""}
                userId={item.user?.id}
              />
            );
          })}

          {trackingSingleUser !== undefined ? (
            <MarkerUser
              fullName={trackingSingleUser?.user?.fullName}
              lat={trackingSingleUser?.lat}
              lng={trackingSingleUser?.lng}
              group={
                trackingSingleUser?.user?.user_detail?.user_organization_id
                  ?.name ?? ""
              }
              phone={trackingSingleUser?.user?.phone_number ?? ""}
              userId={trackingSingleUser?.user?.id}
            />
          ) : (
            <></>
          )}
        </GoogleMapReact>
      ) : (
        <></>
      )}
      <ModalPhotoVideoComponent />
    </div>
  );
};

export const MapDrawingButton = () => {
  return (
    <>
      <div
        style={{
          marginBottom: 5,
          marginLeft: -3,
          bottom: 0,
          left: 748,
          cursor: "pointer",
          position: "absolute",
          backgroundColor: "#fff",
          border: "2px solid #fff",
          borderRadius: "4px",
        }}
        onClick={deleteSelectedShape}
      >
        <div
          style={{
            fontSize: 12,
            padding: "3px 8px",
          }}
        >
          Hapus
        </div>
      </div>
      {/* <div
        style={{
          marginBottom: 5,
          marginLeft: 2,
          bottom: 0,
          left: 800,
          cursor: "pointer",
          position: "absolute",
          backgroundColor: "#fff",
          border: "2px solid #fff",
          borderRadius: "4px",
        }}
      >
        <div
          style={{
            fontSize: 12,
            padding: "3px 8px",
          }}
        >
          Simpan
        </div>
      </div> */}
    </>
  );
};

export default DisasterMapComponent;

import Axios from "axios";

export const ApiAuth = () => {
  const token = localStorage.getItem("token");
  return Axios.create({
    baseURL: process.env.REACT_APP_AUTH_URL,
    responseType: "json",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const ApiPokemon = () => {
  return Axios.create({
    baseURL: "https://pokeapi.co/api/v2",
    responseType: "json",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const ApiDisaster = () => {
  const token = localStorage.getItem("token");
  return Axios.create({
    baseURL: process.env.REACT_APP_DISASTER_URL,
    responseType: "json",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const ApiTracking = () => {
  const token = localStorage.getItem("token");
  return Axios.create({
    baseURL: process.env.REACT_APP_TRACKING_URL,
    responseType: "json",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

// basic api call with token to Terra Assignment API
export const ApiAssignment = () => {
  const token = localStorage.getItem("token");
  return Axios.create({
    baseURL: process.env.REACT_APP_ASSIGNMENT_URL,
    responseType: "json",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const ApiExternal = () => {
  return Axios.create({
    baseURL: process.env.REACT_APP_APIEXTERNAL_URL,
    responseType: "json",
  });
};

export const ApiReverseGeocode = () => {
  return Axios.create({
    baseURL: "https://maps.googleapis.com/maps/api",
    responseType: "json",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const ApiResource = () => {
  const token = localStorage.getItem("token");
  return Axios.create({
    baseURL: process.env.REACT_APP_APIRESOURCE_URL,
    responseType: "json",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const ApiGoogle = () => {
  return Axios.create({
    baseURL: process.env.REACT_APP_APIGOOGLE_URL,
    responseType: "json",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const ApiChat = () => {
  const token = localStorage.getItem("token");
  return Axios.create({
    baseURL: process.env.REACT_APP_CHAT_URL,
    responseType: "json",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

export const ApiReport = () => {
  const token = localStorage.getItem("token");
  return Axios.create({
    baseURL: process.env.REACT_APP_REPORT_URL,
    responseType: "json",
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  });
};

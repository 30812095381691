import create from "zustand";
import { EventItemsData } from "../interfaces/event-items.interface";
import { GetEventItems } from "../providers/event-items.provider";

interface EventItemsState {
  eventItemsLoading: boolean;
  eventItems: EventItemsData[];
  getEventItems: (id: string) => void;
}

export const useEventItemsStore = create<EventItemsState>((set, get) => ({
  eventItemsLoading: false,
  eventItems: [],
  getEventItems: async (id: string) => {
    set({ eventItemsLoading: true, eventItems: [] });
    const response = await GetEventItems(id);
    set({
      eventItemsLoading: false,
      eventItems: response.data,
    });
  },
}));

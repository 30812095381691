import GoogleMapReact from "google-map-react";
import ModalPhotoVideoComponent from "./modal-photo-video.component";
import { useMapStore } from "../states/maps.state";
import { useEffect, useRef, useState } from "react";
import MarkerClusterer from "@google/markerclusterer";
import { useModalPhotoVideoStore } from "../../disaster/states/modal-photo-video.state";
import { usePotencyStore } from "../../potency/states/potency.state";

const photoIcon = require("../../../img/marker-detail/photo.png");
const videoIcon = require("../../../img/marker-detail/video.png");

const PotencyMapComponent = () => {
  const { apiKey, defaultCoords, zoom, center, handleChange, setMap } =
    useMapStore();
  const googleMapsRef = useRef<GoogleMapReact>(null);
  const [theMap, setTheMap] = useState<any>(undefined);
  const [theMaps, setTheMaps] = useState<any>(undefined);
  const {
    getPotencyPhoto,
    getPotencyVideo,
    potencyPhotoItems,
    potencyVideoItems,
    isOnPotency,
  } = usePotencyStore();
  const { handleOpen } = useModalPhotoVideoStore();

  useEffect(() => {
    getPotencyPhoto({ size: 50 });
    getPotencyVideo({ size: 50 });
  }, []);

  useEffect(() => {
    if (isOnPotency) {
      if (theMap !== undefined && theMaps !== undefined) {
        if (potencyPhotoItems?.length > 0 || potencyVideoItems?.length > 0) {
          handleMarkers(theMap, theMaps);
        }
      }
    }
  }, [theMap, theMaps, potencyPhotoItems, potencyVideoItems]);

  const handleMarkers = (map: any, maps: any) => {
    const OverlappingMarkerSpiderfier = require("overlapping-marker-spiderfier");
    var oms = new OverlappingMarkerSpiderfier(map, {
      markersWontMove: true,
      markersWontHide: true,
      basicFormatEvents: true,
      nearbyDistance: 40,
      circleSpiralSwitchover: 8,
      spiralFootSeparation: 20,
      spiralLengthStart: 16,
      spiralLengthFactor: 12,
      circleFootSeparation: 50,
      circleStartAngle: 180,
      keepSpiderfied: true,
    });

    let allMarkers = [];

    const openPhotoVideoMarkerModal = (marker) => {
      if (theMap !== undefined && theMaps !== undefined) {
        if (marker.markerType === "Foto") {
          oms.addListener("click", function (marker) {
            handleOpen(
              marker?.markerType,
              marker?.lat,
              marker?.lng,
              marker?.name,
              marker?.media,
              marker?.title,
              marker?.dateTime,
              marker?.userId,
              marker?.id
            );
          });
        } else if (marker.markerType === "Video") {
          oms.addListener("click", function (marker) {
            handleOpen(
              marker?.markerType,
              marker?.lat,
              marker?.lng,
              marker?.name,
              marker?.media,
              marker?.title,
              marker?.dateTime,
              marker?.userId,
              marker?.id
            );
          });
        }
      }
    };

    if (potencyPhotoItems?.length > 0) {
      potencyPhotoItems.map((item) => {
        const data = {
          lat: parseFloat(item?.lat),
          lng: parseFloat(item?.lng),
          url: photoIcon,
          name: item?.content,
          media: item?.media_id,
          title: item?.title,
          dateTime: item?.createdAt,
          userId: item?.user_id,
          id: item?.id,
          markerType: "Foto",
        };
        allMarkers.push(data);
      });
    }

    if (potencyVideoItems?.length > 0) {
      potencyVideoItems.map((item) => {
        const data = {
          lat: parseFloat(item?.lat),
          lng: parseFloat(item?.lng),
          url: videoIcon,
          name: item?.content,
          media: item?.media_id,
          title: item?.title,
          dateTime: item?.createdAt,
          userId: item?.user_id,
          id: item?.id,
          markerType: "Video",
        };
        allMarkers.push(data);
      });
    }

    const markerCluster = new MarkerClusterer(map, [], {
      imagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
      maxZoom: 15,
      minimumClusterSize: 2,
    });

    allMarkers?.map((item) => {
      const markerPhotoVideo = new maps.Marker({
        position: { lat: item?.lat, lng: item?.lng },
        icon: item?.url,
        markerType: item?.markerType,
        lat: item?.lat,
        lng: item?.lng,
        name: item?.name,
        media: item?.media,
        title: item?.title,
        dateTime: item?.dateTime,
        userId: item?.userId,
        id: item?.id,
      });
      openPhotoVideoMarkerModal(markerPhotoVideo);
      oms.addMarker(markerPhotoVideo);
      markerCluster.addMarker(markerPhotoVideo);
    });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: -999,
      }}
    >
      {apiKey !== undefined && isOnPotency === true ? (
        <>
          <GoogleMapReact
            ref={googleMapsRef}
            bootstrapURLKeys={{
              key: apiKey,
              libraries: "drawing",
            }}
            defaultCenter={{
              lat: defaultCoords.lat,
              lng: defaultCoords.lng,
            }}
            center={{
              lat: +defaultCoords.lat,
              lng: +defaultCoords.lng,
            }}
            zoom={zoom}
            options={(maps) => ({
              mapTypeId: "hybrid",
              fullscreenControl: false,
              zoomControl: true,
              zoomControlOptions: {
                position: maps.ControlPosition.RIGHT_BOTTOM,
              },
              mapTypeControl: true,
              mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.RIGHT_BOTTOM,
              },
            })}
            yesIWantToUseGoogleMapApiInternals={true}
            onChange={handleChange}
            onGoogleApiLoaded={({ map, maps }) => {
              setMap(map);
              setTheMap(map);
              setTheMaps(maps);
            }}
          ></GoogleMapReact>
          <ModalPhotoVideoComponent />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default PotencyMapComponent;

import { AxiosError } from "axios";
import { ApiGoogle } from "../../../api/api";
import { EventPlacesNearbyInterface } from "../interfaces/event-places-nearby.interface";

export interface EventPlacesNearbyParam {
  lat: string;
  lng: string;
  type: string;
}

export const GetEventPlacesNearby = async (
  param: EventPlacesNearbyParam
): Promise<EventPlacesNearbyInterface> => {
  try {
    const response = await ApiGoogle().get("/events-places-nearby", {
      params: {
        lat: param.lat,
        lng: param.lng,
        type: param.type,
      },
    });
    return response.data as EventPlacesNearbyInterface;
  } catch (e) {
    const error = e as AxiosError;
    throw error;
  }
};

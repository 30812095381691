import { UserDetailData } from "../interfaces/user-detail.interface";
import { getUserDetails } from "../providers/user-detail.provider";
import create from "zustand";

interface UserDetailState {
  userDetailLoading: boolean;
  userDetailData?: string;
  getUserDetail: (userId: string) => void;
}
export const useUserDetailStore = create<UserDetailState>((set, get) => ({
  userDetailLoading: false,
  getUserDetail: async (userId: string) => {
    set({ userDetailLoading: true });
    const result = await getUserDetails(userId);
    set({ userDetailData: result.data.fullName ?? "User tidak ditemukan..." });
    set({ userDetailLoading: false });
  },
}));

import { GeneralInfoData } from "../interfaces/general-info.interface";
import {
  GeneralInfoParam,
  getGeneralInfos,
} from "../providers/general-info.provider";
import create from "zustand";

interface GeneralInfoState {
  generalInfoLoading: boolean;
  generalInfoData?: GeneralInfoData[];
  beritaTvItems: GeneralInfoData[];
  siaranLangsungItems: GeneralInfoData[];
  informasiUmumItems: GeneralInfoData[];
  getGeneralInfo: (generalInfoParam: GeneralInfoParam) => void;
}

export const useGeneralInfoStore = create<GeneralInfoState>((set, get) => ({
  generalInfoLoading: false,
  generalInfoData: [],
  beritaTvItems: [],
  siaranLangsungItems: [],
  informasiUmumItems: [],
  getGeneralInfo: async (generalInfoParam: GeneralInfoParam) => {
    set({ generalInfoLoading: true });
    const result = await getGeneralInfos(generalInfoParam);
    if (generalInfoParam.code === 10) {
      set({ beritaTvItems: result.data.items });
    } else if (generalInfoParam.code === 11) {
      set({ siaranLangsungItems: result.data.items });
    } else {
      set({ informasiUmumItems: result.data.items });
    }
  },
}));

import React, { SyntheticEvent, useEffect, useState } from "react";
import styled from "styled-components";
import { useLayer, Arrow } from "react-laag";
import styles from "../../home/assets/home.module.css";
import TerraLogo from "../../../img/TERRA-Dashboard.png";
import { Link } from "react-router-dom";
import { DisasterData } from "../../home/interfaces/disaster-event.interface";
import moment from "moment";
import "moment/locale/id";
import { Button, Modal, Table } from "react-bootstrap";
import { Temperature } from "../../home/interfaces/weather-result.interface";
import { useEventStore } from "../../home/states/disaster-event.state";
import { useModalPhotoVideoStore } from "../../disaster/states/modal-photo-video.state";
import ChatModalComponent from "../../chats/components/chat-modal.component";

interface MarkerProps {
  lat: number;
  lng: number;
  icon: string;
  icon2: string;
  isTerra: boolean;
  event: string;
  desc: string;
  prov: string;
  city: string;
  dateTime: Date;
  eventCode: string;
  eventDetail: DisasterData;
  csrAssistance: number;
  totalPhotos: number;
  isActive?: boolean;
}

interface MarkerWeatherProps {
  lat: number;
  lng: number;
  prov: string;
  city: string;
  weather: Temperature[];
  temperature: Temperature[];
}

interface MarkerEarthquakeProps {
  lat: number;
  lng: number;
  location: string;
  dateTime: Date;
  potency: string;
  magnitude: string;
  kedalaman: string;
}

interface MarkerVolcanoProps {
  lat: number;
  lng: number;
  volcanoName: string;
  volcanoStatus: string;
  icon: React.ReactNode;
}

interface MarkerPhotoProps {
  lat: number;
  lng: number;
  name: string;
  media: string;
  title: string;
  dateTime: Date;
  userId: string;
  id: string;
}

interface MarkerVideoProps {
  lat: number;
  lng: number;
  name: string;
  media: string;
  title: string;
  dateTime: Date;
  userId: string;
  id: string;
}

interface MarkerHospitalProps {
  lat: number;
  lng: number;
  name: string;
  location: string;
}

const StyledMarker = {
  width: 24,
  height: 24,
  border: 0,
  padding: 0,
  margin: 0,
  maxWidth: "none",
  cursor: "pointer",
};

const InfoWindow = styled.div`
  padding: 5px;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border: 4px solid #da251c;
  border-radius: 5px;
`;

const InfoWindowVolcano = styled.div`
  padding: 5px;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
  background-color: #fff;
`;

export const MarkerCluster = ({ key, lat, lng, pointCount, points }) => {
  return (
    <div
      style={{
        color: "#fff",
        background: "#1978c8",
        borderRadius: "50%",
        padding: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: `${10 + (pointCount / points.length) * 30}px`,
        height: `${10 + (pointCount / points.length) * 30}px`,
      }}
    >
      {pointCount}
    </div>
  );
};

export const MarkerHospital: React.FC<MarkerHospitalProps> = ({
  lat,
  lng,
  name,
  location,
}) => {
  const [isOpen, setOpen] = useState(false);
  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen,
    triggerOffset: 10,
    auto: true,
    overflowContainer: false,
    onOutsideClick: () => setOpen(false),
  });
  return (
    <>
      <div
        style={StyledMarker}
        {...triggerProps}
        onClick={() => setOpen((prev) => !prev)}
      >
        <img
          alt="icon_marker"
          src={require(`../../../img/icons_new/rs.png`)}
        ></img>
      </div>
      {isOpen &&
        renderLayer(
          <InfoWindow
            {...layerProps}
            style={{ ...layerProps.style, width: 300, height: 170 }}
          >
            <div
              className={`${styles["si-frame"]} ${styles["si-content-wrapper"]}`}
            >
              <button
                type="button"
                className={`${styles["si-close-button"]}`}
                onClick={() => setOpen(false)}
              >
                ×
              </button>
              <div
                className={`${styles["si-content"]}`}
                style={{
                  maxWidth: 242,
                  maxHeight: 486,
                  fontSize: 12,
                }}
              >
                <h1>Rumah Sakit</h1>
                <p>
                  Nama: {name}
                  <br />
                  Lokasi: {location}
                </p>
              </div>
            </div>
            {/* @ts-ignore */}
            <Arrow {...arrowProps} roundness={0} />
          </InfoWindow>
        )}
    </>
  );
};

const Marker: React.FC<MarkerProps> = ({
  lat,
  lng,
  icon,
  icon2,
  isTerra,
  event,
  desc,
  prov,
  city,
  dateTime,
  eventCode,
  eventDetail,
  csrAssistance,
  totalPhotos,
  isActive,
}) => {
  // call useEventStore and setIsOnDetail, setEventDetail state
  const { setIsOnDetail, setEventDetail, isOnDetail } = useEventStore();
  const [isOpen, setOpen] = useState(false);
  const [isBlinking, setIsBlinking] = useState(false);

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval>;

    // Check if isActive is true and dateTime plus 5 days is greater than the current date
    const dateTimePlus5Days = new Date(dateTime);
    dateTimePlus5Days.setDate(dateTimePlus5Days.getDate() + 5);
    const isWithin5Days =
      isActive && dateTimePlus5Days.getTime() > new Date().getTime();

    if (isWithin5Days) {
      intervalId = setInterval(() => {
        setIsBlinking((prev) => !prev);
      }, 500); // Blinking interval in milliseconds
    } else {
      setIsBlinking(false);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [dateTime, isActive]);

  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen,
    triggerOffset: 10,
    auto: true,
    overflowContainer: false,
    onOutsideClick: () => setOpen(false),
  });

  const currencyFormatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  });

  if (isOnDetail) {
    return (
      <div style={StyledMarker}>
        {totalPhotos !== 0 ? (
          <img
            alt="icon_marker"
            src={require(`../../../img/marker/${icon2}`)}
          ></img>
        ) : (
          <img
            alt="icon_marker2"
            src={require(`../../../img/marker/${icon}`)}
          ></img>
        )}
        {isTerra ? (
          <img
            src={TerraLogo}
            alt=""
            style={{
              width: 26,
              height: 10,
            }}
          />
        ) : (
          ""
        )}
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          ...StyledMarker,
          opacity: isActive ? (isBlinking ? 0 : 1) : 1,
          transition: "opacity 0.5s ease-in-out",
        }}
        {...triggerProps}
        onClick={() => setOpen((prev) => !prev)}
      >
        {totalPhotos !== 0 ? (
          <img
            alt="icon_marker"
            src={require(`../../../img/marker/${icon2}`)}
          ></img>
        ) : (
          <img
            alt="icon_marker2"
            src={require(`../../../img/marker/${icon}`)}
          ></img>
        )}
        {isTerra ? (
          <img
            src={TerraLogo}
            alt=""
            style={{
              width: 26,
              height: 10,
            }}
          />
        ) : (
          ""
        )}
      </div>

      {isOpen &&
        renderLayer(
          <InfoWindow
            {...layerProps}
            style={{ ...layerProps.style, width: 300 }}
          >
            <div
              className={`${styles["si-frame"]} ${styles["si-content-wrapper"]}`}
            >
              <button
                type="button"
                className={`${styles["si-close-button"]}`}
                onClick={() => setOpen(false)}
              >
                ×
              </button>
              <div
                className={`${styles["si-content"]}`}
                style={{
                  maxWidth: 242,
                  maxHeight: 486,
                  fontSize: 12,
                }}
              >
                <h1>{event}</h1>
                <p>
                  <strong>{desc}</strong>
                  <br />
                  {city} {prov}
                </p>
                <p>
                  <strong>{eventCode}</strong>
                  <br />
                  {moment(dateTime).format("LL")}
                </p>
                <p>
                  <strong>
                    Total Bantuan: {currencyFormatter.format(csrAssistance)}
                  </strong>
                </p>
                <hr />
                <em>
                  <Link
                    type="a"
                    to={`/disaster/${eventDetail?.id}`}
                    state={{
                      disasterData: eventDetail,
                    }}
                    onClick={(e) => {
                      // set isonedetail state to true
                      setIsOnDetail(true);
                      setEventDetail(eventDetail);
                      setOpen(false);
                    }}
                  >
                    <br />
                    detail »
                  </Link>
                </em>
              </div>
            </div>
            {/* @ts-ignore */}
            <Arrow {...arrowProps} roundness={0} />
          </InfoWindow>
        )}
    </>
  );
};

export function MarkerWeather({
  lat,
  lng,
  prov,
  city,
  weather,
  temperature,
}: MarkerWeatherProps) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div
        style={{
          width: 15,
          height: 15,
          border: 0,
          padding: 0,
          margin: 0,
          maxWidth: "none",
          cursor: "pointer",
        }}
        onClick={handleShow}
      >
        <img src="https://img.icons8.com/offices/30/000000/cloud.png" />
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName={`${styles["modal-60w"]}`}
      >
        <Modal.Header className={`${styles["modal-header"]}`}>
          <Modal.Title>
            <h5 className={`${styles["modal-title"]}`}>Cuaca</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row">
              <div className="col-md-12">
                <div style={{ textAlign: "center" }}>
                  <h3>{city}</h3>
                </div>
                <br />
              </div>
              <div className="col-md-6">
                <h5>Cuaca</h5>
                <Table borderless hover size="sm">
                  <thead>
                    <tr>
                      <th>Hari</th>
                      <th>Kondisi</th>
                    </tr>
                  </thead>
                  <tbody
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {weather?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.datetime}</td>
                          <td>{item?.value}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
              <div className="col-md-6">
                <h5>Temperatur</h5>
                <Table borderless hover size="sm">
                  <thead>
                    <tr>
                      <th>Hari</th>
                      <th>Kondisi</th>
                    </tr>
                  </thead>
                  <tbody
                    style={{
                      fontSize: 12,
                    }}
                  >
                    {temperature?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.datetime}</td>
                          <td>{item.value}°</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Tutup
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export const MarkerEarthquake: React.FC<MarkerEarthquakeProps> = ({
  lat,
  lng,
  location,
  dateTime,
  potency,
  magnitude,
  kedalaman,
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const shakeMapDate = new Date(dateTime).toString();
  // const shakeMapDate = moment(dateTime).format("MMMM Do YYYY, h:mm:ss a");
  const date = ("0" + moment(shakeMapDate).date()).slice(-2);
  const month = ("0" + (moment(shakeMapDate).month() + 1)).slice(-2);
  const year = moment(shakeMapDate).year();
  const hour = ("0" + moment(shakeMapDate).hour()).slice(-2);
  const minutes = ("0" + moment(shakeMapDate).minutes()).slice(-2);
  const seconds = ("0" + moment(shakeMapDate).seconds()).slice(-2);

  const shakeMap = `https://data.bmkg.go.id/DataMKG/TEWS/${year}${month}${date}${hour}${minutes}${seconds}.mmi.jpg`;

  const FALLBACK_IMAGE = require("../../../img/no_images.png");
  const imageOnErrorHandler = (
    event: React.SyntheticEvent<HTMLImageElement, Event>
  ) => {
    event.currentTarget.src = FALLBACK_IMAGE;
  };

  return (
    <>
      <div
        style={{
          width: 15,
          height: 15,
          border: 0,
          padding: 0,
          margin: 0,
          maxWidth: "none",
          cursor: "pointer",
        }}
        onClick={handleShow}
      >
        <img src={require("../../../img/earthquake/gempa_marker.png")} />
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header className={`${styles["modal-header"]}`}>
          <Modal.Title>
            <h5 className={`${styles["modal-title"]}`}>Gempa Bumi</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div style={{ textAlign: "center" }}>
                <strong>{location}</strong>
                <img
                  className="img-responsive"
                  src={shakeMap}
                  onError={imageOnErrorHandler}
                  alt=""
                  style={{
                    display: "block",
                    marginLeft: "auto",
                    marginRight: "auto",
                    width: "65%",
                  }}
                />
                <div style={{ fontSize: 12 }}>
                  Hari/Tanggal: {moment(dateTime).format("LLL")}
                  <br />
                  Potensi: {potency}
                  <br />
                  Sumber: BMKG
                  <br />
                  Magnitude: {magnitude}
                  <br />
                  Kedalaman: {kedalaman}
                  <br />
                  Latitude: {lat}
                  <br />
                  Longitude: {lng}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Tutup
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export const MarkerVolcano: React.FC<MarkerVolcanoProps> = ({
  lat,
  lng,
  volcanoName,
  volcanoStatus,
  icon,
}) => {
  const [isOpen, setOpen] = useState(false);
  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen,
    triggerOffset: 10,
    auto: true,
    overflowContainer: false,
    onOutsideClick: () => setOpen(false),
  });
  return (
    <>
      <div
        style={StyledMarker}
        {...triggerProps}
        onClick={() => setOpen((prev) => !prev)}
      >
        {icon}
      </div>
      {isOpen &&
        renderLayer(
          <InfoWindowVolcano {...layerProps} onClick={() => setOpen(false)}>
            <div
              className={`${styles["gm-style-iw"]}`}
              style={{
                paddingRight: 0,
                paddingBottom: 0,
                maxWidth: 648,
                maxHeight: 470,
                minWidth: 0,
              }}
            >
              <div style={{ fontSize: 12, maxHeight: 452 }}>
                <div>
                  {volcanoName} - {volcanoStatus}
                </div>
              </div>
            </div>
          </InfoWindowVolcano>
        )}
    </>
  );
};

export const MarkerPhoto: React.FC<MarkerPhotoProps> = ({
  lat,
  lng,
  name,
  media,
  title,
  dateTime,
  userId,
  id,
}) => {
  const { handleOpen } = useModalPhotoVideoStore();
  return (
    <>
      <div
        style={{
          width: 15,
          height: 15,
          border: 0,
          padding: 0,
          margin: 0,
          maxWidth: "none",
          cursor: "pointer",
        }}
        onClick={(e) =>
          handleOpen("Foto", lat, lng, name, media, title, dateTime, userId, id)
        }
      >
        <img
          src={require("../../../img/marker-detail/photo.png")}
          alt="marker_photo"
        />
      </div>
    </>
  );
};

export const MarkerVideo: React.FC<MarkerVideoProps> = ({
  lat,
  lng,
  name,
  media,
  title,
  dateTime,
  userId,
  id,
}) => {
  const { handleOpen } = useModalPhotoVideoStore();
  return (
    <>
      <div
        style={{
          width: 15,
          height: 15,
          border: 0,
          padding: 0,
          margin: 0,
          maxWidth: "none",
          cursor: "pointer",
        }}
        onClick={(e) =>
          handleOpen(
            "Video",
            lat,
            lng,
            name,
            media,
            title,
            dateTime,
            userId,
            id
          )
        }
      >
        <img
          src={require("../../../img/marker-detail/video.png")}
          alt="marker_video"
        />
      </div>
    </>
  );
};

interface MarkerUserProps {
  fullName: string;
  lat: number;
  lng: number;
  group: string;
  phone: string;
  userId: string;
}

export const MarkerUser: React.FC<MarkerUserProps> = ({
  fullName,
  lat,
  lng,
  group,
  phone,
  userId,
}) => {
  const [isOpen, setOpen] = useState(false);
  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen,
    triggerOffset: 10,
    auto: true,
    overflowContainer: false,
    onOutsideClick: () => setOpen(false),
  });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div
        style={{
          height: 25,
          border: 0,
          padding: 0,
          margin: 0,
          maxWidth: "none",
          cursor: "pointer",
        }}
        {...triggerProps}
        onClick={() => setOpen(!isOpen)}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={require("../../../img/marker-detail/tracking.png")}
            alt="marker_user"
          />
          <div
            style={{
              width: 150,
              backgroundColor: "rgba(0, 0, 0, 0.2)",

              textAlign: "center",
              padding: 5,
              color: "white",
              fontWeight: 700,
            }}
          >
            {fullName}
          </div>
        </div>
      </div>
      {isOpen &&
        renderLayer(
          <InfoWindow
            {...layerProps}
            style={{ ...layerProps.style, width: 300 }}
          >
            <div
              className={`${styles["si-frame"]} ${styles["si-content-wrapper"]}`}
            >
              <button
                type="button"
                className={`${styles["si-close-button"]}`}
                onClick={() => setOpen(false)}
              >
                ×
              </button>
              <div
                className={`${styles["si-content"]}`}
                style={{
                  maxWidth: 242,
                  maxHeight: 486,
                  fontSize: 12,
                }}
              >
                <h1>{fullName}</h1>
                <p>
                  Group: {group}
                  <br />
                  Phone: {phone}
                </p>
                <hr />
                <p style={{ marginTop: 10 }}>
                  <img
                    src={require("../../../img/sms.png")}
                    alt=""
                    style={{
                      height: 25,
                      width: 25,
                      cursor: "pointer",
                    }}
                    onClick={handleShow}
                  />
                </p>
              </div>
            </div>
            {/* @ts-ignore */}
            <Arrow {...arrowProps} roundness={0} />
          </InfoWindow>
        )}
      <ChatModalComponent
        userId={userId}
        show={show}
        handleClose={handleClose}
      />
    </>
  );
};

export default Marker;

import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginScreen from "./modules/auth/screens/login.screen";
import ProtectedRoute from "./modules/auth/screens/protected-route.screen";
import HomeLayout from "./modules/home/screens/home.screen";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import { useAuthStore } from "./modules/auth/states/auth.state";

const WSS_FEED_URL: string = `${process.env.REACT_APP_TRACKING_URL_WS}/socket`;

function App() {
  const { checkAuth } = useAuthStore();

  useEffect(() => {
    checkAuth();
  }, []);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<LoginScreen />} />
          <Route element={<ProtectedRoute />}>
            <Route path="/*" element={<HomeLayout />} />
            <Route
              path="*"
              element={
                <main style={{ padding: "1rem" }}>
                  <p>There's nothing here!</p>
                </main>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;

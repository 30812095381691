import { toast } from "react-toastify";
import create from "zustand";
import { UserData, UserInterfaceV2 } from "../interfaces/user-v2.interface";
import {
  DeleteUser,
  GetUser,
  GetUsers,
  UserVolunteerFilter,
} from "../providers/user.provider";
import { UserSingleResultData } from "./user-single-result.interface";

interface UserState {
  userInterface: UserInterfaceV2;
  userInterfaceExport: UserInterfaceV2;
  userItems: UserData[];
  userItem: UserSingleResultData;
  userItemsExport: UserData[];
  loading: boolean;
  loadingExport: boolean;
  getUsers: (userFilter?: UserVolunteerFilter) => void;
  getUser: (id: string) => void;
  getUsersExport: (userFilter?: UserVolunteerFilter) => void;
  deleteUser: (id: string) => void;
}

export const useUserStore = create<UserState>((set, get) => ({
  userInterface: undefined,
  userInterfaceExport: undefined,
  userItems: [],
  userItem: undefined,
  userItemsExport: [],
  loading: false,
  loadingExport: false,
  getUsers: async (userFilter?: UserVolunteerFilter) => {
    set({ userItems: [], loading: true });
    const response = await GetUsers(userFilter);
    set({
      userInterface: response,
      userItems: response.data.items,
      loading: false,
    });
  },
  getUser: async (id: string) => {
    set({ userItem: undefined });
    const response = await GetUser(id);
    set({
      userItem: response.data,
    });
  },
  getUsersExport: async (userFilter?: UserVolunteerFilter) => {
    set({ userItemsExport: [], loadingExport: true });
    const response = await GetUsers(userFilter);
    set({
      userInterfaceExport: response,
      userItemsExport: response.data.items,
      loadingExport: false,
    });
  },
  deleteUser: async (id: string) => {
    set({ loading: true });
    const result = await DeleteUser(id);
    if (result.status) {
      toast.success(result.message);
      get().getUsers({ size: 15, page: 0 });
    } else {
      toast.error(result.message);
    }
    set({ loading: false });
  },
}));

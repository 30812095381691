import { AxiosError } from "axios";
import { ApiDisaster } from "../../../api/api";
import { EventTypeResultInterface } from "../interfaces/event-type-result.interface";

export interface GetEventTypeParams {}

export const GetEventType = async (): Promise<EventTypeResultInterface> => {
  try {
    const response = await ApiDisaster().get("/event-types-non-paging");
    return response.data as EventTypeResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    throw error;
  }
}
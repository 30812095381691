import { Link } from "react-router-dom";
import navHome from "../../../img/nav/home.png";
import { usePotencyStore } from "../../potency/states/potency.state";

const PotencyNavigationComponent = () => {
  const { setIsOnPotency } = usePotencyStore();
  return (
    <div>
      <nav
        id=""
        className="circle-nav-wrapper"
        style={{
          marginTop: 100,
          float: "right",
          marginRight: 10,
        }}
      >
        <Link type="a" to={"/"}>
          <img
            src={navHome}
            alt=""
            title="Beranda"
            style={{ height: 50 }}
            onClick={(e) => {
              setIsOnPotency(false);
            }}
          />
        </Link>
      </nav>
    </div>
  );
};

export default PotencyNavigationComponent;

import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Modal, Tab, Nav, Button, Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import { UserVolunteerFilter } from "../../home/providers/user.provider";
import { useCityStore } from "../../home/states/city.state";
import { useEventStore } from "../../home/states/disaster-event.state";
import { useProvinceStore } from "../../home/states/province.state";
import { useUserOrganizationStore } from "../../home/states/user-organization.state";
import { useUserStore } from "../../home/states/user.state";
import styles from "../assets/assignment.module.css";
import { AssignmentDto } from "../providers/assignments.provider";
import {
  useAssignmentStore,
  useAssignmentFormStore,
} from "../states/assignment.state";

interface ModalProps {
  show: boolean;
  onHide: () => void;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const ModalPenugasan: React.FC<ModalProps> = ({ show, onHide, onClick }) => {
  //   const { getAssignments, assignments } = useAssignmentStore();
  //   const { eventDetail } = useEventStore();
  //   const handleGetAssignments = (
  //     e: React.MouseEvent<HTMLElement, MouseEvent>
  //   ) => {
  //     if (eventDetail !== undefined) {
  //       getAssignments({
  //         event_id: eventDetail.id,
  //       });
  //     }
  //   };
  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName={`${styles["modal-90w"]}`}
    >
      <Modal.Header className={`${styles["modal-header"]}`}>
        <Modal.Title>
          <h5 className={`${styles["modal-title"]}`}>Penugasan</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tab.Container
          id="left-tabs-example"
          defaultActiveKey="hasil-penugasan"
        >
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link
                className={`${styles["nav-link"]} nav-link`}
                eventKey="hasil-penugasan"
                // onClick={handleGetAssignments}
              >
                Hasil Penugasan
              </Nav.Link>
            </Nav.Item>
            {localStorage.getItem("role") === "pusdalops" ? (
              <></>
            ) : (
              <>
                <Nav.Item>
                  <Nav.Link
                    className={`${styles["nav-link"]} nav-link`}
                    eventKey="kirim-penugasan"
                  >
                    Kirim Penugasan
                  </Nav.Link>
                </Nav.Item>
              </>
            )}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="hasil-penugasan">
              {/* <DataBencana /> */}
              <HasilPenugasanComponent />
            </Tab.Pane>
            <Tab.Pane eventKey="kirim-penugasan">
              <KirimPenugasanComponent />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClick}>
          Tutup
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const HasilPenugasanComponent = () => {
  const { getAssignments, assignments, assignmentLoading } =
    useAssignmentStore();
  const { eventDetail } = useEventStore();
  const { assignmentFormLoading } = useAssignmentFormStore();
  const responseCount = assignments.filter((item) => item.is_reponse);
  const denyCount = assignments.filter(
    (item) => !item.is_reponse && item.is_read
  );
  const noResponseCount = assignments.filter(
    (item) => !item.is_reponse && !item.is_read
  );

  useEffect(() => {
    if (eventDetail !== undefined || assignmentFormLoading === true) {
      getAssignments({
        size: 100,
        event_id: eventDetail.id,
      });
    }
  }, [eventDetail, assignmentFormLoading]);

  return (
    <>
      <div
        className="row"
        style={{
          padding: 20,
        }}
      >
        <table
          style={{
            width: "100%",
            fontSize: 12,
          }}
        >
          <tbody>
            <tr>
              <td style={{ width: 150 }}>Total Penugasan</td>
              <td>: {assignments.length}</td>
            </tr>
            <tr>
              <td style={{ width: 150 }}>Diterima</td>
              <td>: {responseCount.length}</td>
            </tr>
            <tr>
              <td style={{ width: 150 }}>Ditolak</td>
              <td>: {denyCount.length}</td>
            </tr>
            <tr>
              <td style={{ width: 150 }}>Tidak ada respon</td>
              <td>: {noResponseCount.length}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="row">
        <div className="col-12">
          <div
            style={{
              height: 300,
              overflowX: "hidden",
              overflowY: "scroll",
            }}
          >
            <Table
              striped
              bordered
              hover
              // className="Table-striped table-bordered table-advance table-hover"
              style={{ fontSize: 12 }}
            >
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>Grup</th>
                  <th style={{ textAlign: "center" }}>Nama</th>
                  <th style={{ textAlign: "center" }}>Jenis Kelamin</th>
                  <th style={{ textAlign: "center" }}>Telp</th>
                  <th style={{ textAlign: "center" }}>Kabupaten/Kota</th>
                  <th style={{ textAlign: "center" }}>Provinsi</th>
                  <th style={{ textAlign: "center" }}>Respon</th>
                  <th style={{ textAlign: "center" }}>Tgl Mulai</th>
                  <th style={{ textAlign: "center" }}>Tgl Berakhir</th>
                </tr>
              </thead>
              <tbody>
                {assignmentLoading && (
                  <tr>
                    <td colSpan={9} style={{ textAlign: "center" }}>
                      <img src={require(`../../../img/loading.gif`)}></img>
                    </td>
                  </tr>
                )}
                {assignments.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{item.user?.role?.name}</td>
                      <td>{item.user?.fullName}</td>
                      <td>
                        {item?.user?.user_detail?.gender === "m"
                          ? (item?.user?.user_detail?.gender).replace(
                              "m",
                              "Laki-laki"
                            )
                          : (item?.user?.user_detail?.gender).replace(
                              "f",
                              "Perempuan"
                            )}
                      </td>
                      <td>{item.user?.phone_number}</td>
                      <td>{item.user?.user_detail?.city?.name}</td>
                      <td>{item.user?.user_detail?.province?.name}</td>
                      <td>
                        {item.is_reponse === true && item.is_read === true
                          ? "Ya"
                          : item.is_reponse === false && item.is_read === true
                          ? "Tidak"
                          : "Belum merespon"}
                      </td>
                      <td>{moment(item?.start_date).format("LLL")}</td>
                      <td>{moment(item?.end_date).format("LLL")}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

const KirimPenugasanComponent = () => {
  const { eventDetail } = useEventStore();
  const { userInterface, loading, getUsers, userItems } = useUserStore();
  const tableRef = useRef(null);
  const { getProvince, provinceItems } = useProvinceStore();
  const { getCity, cityItems } = useCityStore();
  const { getUserOrganization, userOrganizationItems } =
    useUserOrganizationStore();
  const [userFilter, setUserFilter] = useState<UserVolunteerFilter>({
    user_organization_id: undefined,
    province_id: undefined,
    city_id: undefined,
    size: 15,
  });
  const [isCheckAll, setIsCheckAll] = useState<boolean>(false);
  const [isCheck, setIsCheck] = useState([]);
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(15);
  const dates = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  // assignment dto
  const [userIds, setUserIds] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const { getAssignments } = useAssignmentStore();
  const { submitAssignments, assignmentFormLoading } = useAssignmentFormStore();

  const handleSelectAll = (e: any) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(userInterface?.data?.items?.map((item) => item.id));
    setUserIds(userInterface?.data?.items?.map((item) => item.id));
    if (isCheckAll) {
      setIsCheck([]);
      setUserIds([]);
    }
  };

  useEffect(() => {
    console.log(userIds);
  }, [userIds]);

  const handleClick = (e: any) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    setUserIds([...userIds, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
      setUserIds(userIds.filter((item) => item !== id));
    }
  };

  const Checkbox = ({ id, type, name, handleClick, isChecked, style }) => {
    return (
      <input
        id={id}
        name={name}
        type={type}
        onChange={handleClick}
        checked={isChecked}
      />
    );
  };

  const handlePageClick = (e: any) => {
    const selectedPage = e.selected;
    setPage(selectedPage);
  };

  const onSubmitAssignment = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    let assignmentDtos: AssignmentDto[] = [];
    if (
      userIds.length > 0 &&
      (eventDetail !== null || eventDetail !== undefined)
    ) {
      for (const item of userIds) {
        assignmentDtos.push({
          event_id: eventDetail?.id,
          user_id: item,
          content: "Please help",
          start_date: startDate,
          end_date: endDate,
          is_response: false,
          is_read: false,
          is_life: false,
        });
      }
      submitAssignments(assignmentDtos);
    } else {
      toast.error("Tidak ada user yang dipilih atau event undefined");
    }
  };

  useEffect(() => {
    getUsers(userFilter);
  }, []);

  useEffect(() => {
    getUserOrganization();
    getProvince();
  }, []);

  useEffect(() => {
    getUsers({ page: page, size: size });
  }, [page]);

  return (
    <div className="tab-content">
      <div>
        <div
          className="form-row"
          style={{
            padding: "10px 0",
          }}
        >
          <form className="form-inline" action="">
            <div className={`${styles["w-150px"]}`}>
              <select
                className={`${styles["w-150px"]} form-control input`}
                style={{ fontSize: 12 }}
                onChange={(e) => {
                  setUserFilter({
                    ...userFilter,
                    user_organization_id:
                      e.target.value === "" ? undefined : e.target.value,
                  });
                }}
              >
                <option value="" selected>
                  Grup
                </option>
                {userOrganizationItems?.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className={`${styles["w-150px"]}`}>
              <select
                className={`${styles["w-150px"]} form-control input`}
                style={{ fontSize: 12 }}
                onChange={(e) => {
                  const provCode = provinceItems.find(
                    (el) => el.id === e.target.value
                  );
                  if (provCode) {
                    getCity({ prov_code: provCode?.prov_code });
                    setUserFilter({
                      ...userFilter,
                      province_id:
                        e.target.value === "" ? undefined : e.target.value,
                    });
                  } else {
                    setUserFilter({
                      ...userFilter,
                      province_id: undefined,
                    });
                  }
                }}
              >
                <option value="" selected>
                  Provinsi
                </option>
                {provinceItems?.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className={`${styles["w-150px"]}`}>
              <select
                className={`${styles["w-150px"]} form-control input`}
                style={{ fontSize: 12 }}
                onChange={(e) => {
                  setUserFilter({
                    ...userFilter,
                    city_id: e.target.value === "" ? undefined : e.target.value,
                  });
                }}
              >
                <option value="" selected>
                  Kabupaten/Kota
                </option>
                {cityItems?.map((item, index) => (
                  <option key={index} value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className={`${styles["w-150px"]}`}>
              <input
                className={`${styles["w-170px"]} form-control input`}
                type="text"
                placeholder="Nama"
                style={{
                  fontSize: 12,
                  height: 38,
                }}
                onChange={(e) => {
                  setUserFilter({
                    ...userFilter,
                    name: e.target.value === "" ? undefined : e.target.value,
                  });
                }}
              />
            </div>
            <div style={{ marginLeft: 30 }} className={`${styles["w-150px"]}`}>
              <Button
                variant="info"
                size="sm"
                onClick={(e) => {
                  getUsers(userFilter);
                }}
              >
                Tampilkan
              </Button>
            </div>
            <div className={`${styles["w-150px"]}`}>
              <select
                className={`${styles["w-150px"]} form-control input`}
                style={{ fontSize: 12 }}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
              >
                <option value="" selected>
                  Tgl Mulai
                </option>
                <option value={moment().format("YYYY-MM-DD")}>
                  {moment().format("L")}
                </option>
              </select>
            </div>
            <div style={{ marginLeft: 15 }}>-</div>
            <div className={`${styles["w-150px"]}`}>
              <select
                className={`${styles["w-150px"]} form-control input`}
                style={{ fontSize: 12 }}
                onChange={(e) => {
                  setEndDate(e.target.value);
                }}
              >
                <option value="" selected>
                  Tgl Berakhir
                </option>
                {dates?.map((item, index) => (
                  <option
                    key={index}
                    value={moment().add(item, "days").format("YYYY-MM-DD")}
                  >
                    {moment().add(item, "days").format("L")}
                  </option>
                ))}
              </select>
            </div>
            <div style={{ marginLeft: 30 }}>
              <Button
                variant="danger"
                size="sm"
                style={{ marginLeft: 5 }}
                onClick={onSubmitAssignment}
              >
                Kirim
              </Button>
            </div>
          </form>
          <Table
            className={`${styles["table"]}`}
            style={{
              marginTop: 10,
            }}
            striped
            bordered
            hover
            size="sm"
            ref={tableRef}
          >
            <thead>
              <tr style={{ textAlign: "center" }}>
                <th>
                  <Checkbox
                    style={{ width: 15, height: 15 }}
                    type="checkbox"
                    name="selectAll"
                    id="selectAll"
                    handleClick={handleSelectAll}
                    isChecked={isCheckAll}
                  />
                </th>
                <th>Grup</th>
                <th>Nama</th>
                <th>Jenis Kelamin</th>
                <th>Telp</th>
                <th>Kabupaten/Kota</th>
                <th>Provinsi</th>
              </tr>
            </thead>
            <tbody>
              {loading && (
                <tr>
                  <td colSpan={8} style={{ textAlign: "center" }}>
                    <img src={require(`../../../img/loading.gif`)}></img>
                  </td>
                </tr>
              )}
              {userItems?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td style={{ textAlign: "center" }}>
                      <Checkbox
                        style={{ width: 15, height: 15 }}
                        id={item?.id}
                        name={item?.fullName}
                        type="checkbox"
                        handleClick={handleClick}
                        isChecked={isCheck.includes(item?.id)}
                      />
                    </td>
                    <td>{item?.user_organization_name}</td>
                    <td>{item?.fullName}</td>
                    <td>
                      {item?.gender === "m"
                        ? (item?.gender).replace("m", "Laki-laki")
                        : (item?.gender).replace("f", "Perempuan")}
                    </td>
                    <td>{item?.phone_number}</td>
                    <td>{item?.city_name}</td>
                    <td>{item?.province_name}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div
          className="col-4"
          style={{
            textAlign: "left",
            fontSize: 12,
          }}
        >
          Halaman {page + 1} dari {userInterface?.data?.total_pages},
          menampilkan {userInterface?.data?.items.length} dari{" "}
          {userInterface?.data?.total}
        </div>
        <div className="col-12">
          <ReactPaginate
            previousLabel={"«"}
            nextLabel={"»"}
            breakLabel={"..."}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            pageCount={userInterface?.data?.total_pages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={10}
            onPageChange={handlePageClick}
            containerClassName={"pagination pagination-sm m-0 float-right"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  );
};

export default ModalPenugasan;

import { useEffect, useRef } from "react";
import GoogleMapReact from "google-map-react";
import { useMapStore } from "../states/maps.state";
import { useEventStore } from "../../home/states/disaster-event.state";
import Marker, {
  MarkerEarthquake,
  MarkerUser,
  MarkerVolcano,
  MarkerWeather,
} from "./marker.component";
import { useWeatherStore } from "../../home/states/weather.state";
import { useEarthquakeStore } from "../../home/states/earthquake.state";
import { useVolcanoStore } from "../../home/states/volcano.state";
import { usePatahanStore } from "../../home/states/patahan.state";
import { useSubduksiStore } from "../../home/states/subduksi.state";
import { useTrackingStore } from "../../trackings/states/tracking.state";

const MapComponent = () => {
  const { apiKey, defaultCoords, zoom, center, handleChange, setMap } =
    useMapStore();
  const { eventItems } = useEventStore();
  const googleMapsRef = useRef<GoogleMapReact>(null);
  const { weathers } = useWeatherStore();
  const { earthquakes } = useEarthquakeStore();
  const { volcanos } = useVolcanoStore();
  const { setPatahanMap } = usePatahanStore();
  const { setSubduksiMap } = useSubduksiStore();
  const { trackingUsers } = useTrackingStore();

  useEffect(() => {}, [trackingUsers]);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: -999,
      }}
    >
      <GoogleMapReact
        ref={googleMapsRef}
        bootstrapURLKeys={{
          key: apiKey,
          libraries: "drawing",
        }}
        defaultCenter={{
          lat: defaultCoords.lat,
          lng: defaultCoords.lng,
        }}
        center={{
          lat: +defaultCoords.lat,
          lng: +defaultCoords.lng,
        }}
        zoom={zoom}
        options={(maps) => ({
          mapTypeId: "hybrid",
          fullscreenControl: false,
          zoomControl: true,
          zoomControlOptions: {
            position: maps.ControlPosition.RIGHT_BOTTOM,
          },
          mapTypeControl: true,
          mapTypeControlOptions: {
            style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
            position: maps.ControlPosition.RIGHT_BOTTOM,
          },
        })}
        yesIWantToUseGoogleMapApiInternals={true}
        onChange={handleChange}
        onGoogleApiLoaded={({ map, maps }) => {
          // console.log(map, maps)
          // pertama set object map yang ada diparamater diatas
          // ke object map yang ada di useMapStore dan usePatahanStore
          // object map ini berguna untuk memunculkan geoJson
          setMap(map);

          // patahan
          setPatahanMap(map);
          // subduksi
          setSubduksiMap(map);
        }}
      >
        {/* Event */}
        {eventItems?.map((item, index) => {
          return (
            <Marker
              key={index}
              lat={parseFloat(item?.lat)}
              lng={parseFloat(item?.lng)}
              icon={item?.event_type?.icon}
              icon2={item?.event_type?.icon.replace(".png", "2.png")}
              isTerra={item?.is_terra}
              event={item?.name}
              desc={item?.description}
              prov={item?.province?.name}
              city={item?.city?.name}
              dateTime={item?.date_time}
              eventCode={item?.event_code}
              eventDetail={item}
              csrAssistance={item?.total_csr_assistance}
              totalPhotos={item?.total_photos}
              isActive={item?.is_active}
            />
          );
        })}

        {/* Weather */}
        {weathers?.map((item, index) => {
          return (
            <MarkerWeather
              key={index}
              lat={parseFloat(item.latitude)}
              lng={parseFloat(item.longitude)}
              prov={item.domain}
              city={item.name}
              weather={item.weather}
              temperature={item.temperature}
            />
          );
        })}

        {/* Earthquake */}
        {earthquakes?.map((item, index) => {
          const latLng = item.Coordinates.split(",");
          return (
            <MarkerEarthquake
              key={index}
              lat={+latLng[0]}
              lng={+latLng[1]}
              location={item.Wilayah}
              dateTime={item.DateTime}
              potency={item.Potensi}
              magnitude={item.Magnitude}
              kedalaman={item.Kedalaman}
            />
          );
        })}

        {/* Volcano */}
        {volcanos?.map((item, index) => {
          return (
            <MarkerVolcano
              key={index}
              lat={+item.lat}
              lng={+item.lng}
              volcanoName={item.volcanoName}
              volcanoStatus={item.statusName}
              icon={
                <img
                  style={{ cursor: "pointer" }}
                  src={require(`../../../img/magma/${item.icon.slice(11)}`)}
                ></img>
              }
            />
          );
        })}

        {/* Tracking */}
        {trackingUsers?.map((item, index) => {
          return (
            <MarkerUser
              userId={item.user?.id}
              key={index}
              fullName={item.user?.fullName ?? "Fulan"}
              lat={item.lat}
              lng={item.lng}
              group={item.user?.user_detail?.user_organization_id?.name ?? ""}
              phone={item.user?.phone_number ?? ""}
            />
          );
        })}
      </GoogleMapReact>

      {/* 
        Tempatnya modal photo video,
        modal akan muncul ketika value show pada useModalPhotoVideoStore adalah true
      */}
    </div>
  );
};

export default MapComponent;

import { AxiosError } from "axios";
import { ApiExternal } from "../../../api/api";
import { WeatherResultInterface } from "../interfaces/weather-result.interface";

export const GetWeatherExternalData =
  async (): Promise<WeatherResultInterface> => {
    try {
      const response = await ApiExternal().get(
        "/public/weather?page=0&size=50"
      );
      return response.data as WeatherResultInterface;
    } catch (e) {
      const error = e as AxiosError;
      throw error;
    }
  };

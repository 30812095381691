import { AxiosError } from "axios";
import { ApiResource } from "../../../api/api";
import { ResourceTypeGroupInterface } from "../interfaces/resource-type-group.interface";

export const getResourceTypeGroups =
  async (): Promise<ResourceTypeGroupInterface> => {
    try {
      const response = await ApiResource().get(
        "/resource-type-groups-non-paging"
      );
      return response.data as ResourceTypeGroupInterface;
    } catch (e) {
      const error = e as AxiosError;
      throw error;
    }
  };

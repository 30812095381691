import styles from "../assets/home.module.css";
import { Outlet, Route, Routes } from "react-router-dom";
import DashboardScreen from "./dashboard.screen";
import ResourceScreen from "./resource.screen";
import DisasterScreen from "../../disaster/screens/disaster.screen";
import TrackingScreen from "../../trackings/screens/trackings.screen";
import ReportScreen from "../../reports/screen/report.screen";
import PotencyScreen from "../../potency/screens/potency.screen";
import { useSocketStore } from "../states/socket.state";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { MessageData } from "../../chats/interfaces/message-result.interface";
import { GetUser } from "../providers/user.provider";

function HomeScreen() {
  return (
    <div className={styles.dashboard}>
      <Outlet />
    </div>
  );
}

const HomeLayout = () => {
  const { socket } = useSocketStore();

  const getSenderName = async (id) => {
    const response = await GetUser(id);
    if (response.data.fullName !== undefined) {
      console.log(response);
      toast.success(`Pesan baru dari ${response?.data?.fullName}`, {
        style: { top: 130 },
      });
    }
  };

  useEffect(() => {
    socket.connect();
    socket.on("receiveMessage", (msg) => {
      const data: MessageData = JSON.parse(msg);
      console.log("receiveMessage", data);
      console.log(
        "receiveMessage",
        localStorage.getItem("userId"),
        data.receiver_id
      );
      if (
        data.action === "create" &&
        localStorage.getItem("userId") === data.receiver_id
      ) {
        getSenderName(data.sender_id);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <Routes>
      <Route path="/" element={<HomeScreen />}>
        <Route index element={<DashboardScreen />} />
        <Route path="resource/:resourceId" element={<ResourceScreen />} />
        <Route path="disaster/:disasterId" element={<DisasterScreen />} />
        <Route path="tracking/:disasterId" element={<TrackingScreen />} />
        <Route path="report/:disasterId" element={<ReportScreen />} />
        <Route path="potency" element={<PotencyScreen />} />
      </Route>
    </Routes>
  );
};

export default HomeLayout;

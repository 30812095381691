import { toast } from 'react-toastify';
import create from 'zustand';
import { RoomData } from '../interfaces/room-single-result.interface';
import { CreateRoom, GetRoomBySenderReceiver } from '../providers/room.provider';

interface RoomState {
  roomLoading: boolean;
  roomData: RoomData;
  getRoom: (receiverId: string) => void;
  createRoom: (senderId: string, receiverId: string, content: string) => void;
  roomReset: () => void;
}

export const useRoomStore = create<RoomState>((set, get) => ({
  roomLoading: false,
  roomData: undefined,
  getRoom: async (receiverId: string) => {
    set({ roomLoading: true });
    const response = await GetRoomBySenderReceiver({ receiver_id: receiverId });
    if (response.status) {
      set({ roomData: response.data });
    } else {
      set({ roomData: undefined });
    }
    set({ roomLoading: false });
  },
  roomReset: () => {
    set({
      roomLoading: false,
      roomData: undefined,
    });
  },
  createRoom: async (senderId: string, receiverId: string, content: string) => {
    const form = {
      sender_id: senderId,
      receiver_id: receiverId,
      content: content,
      is_read: true
    };
    const response = await CreateRoom(form);
    if (response.status) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  }
}))
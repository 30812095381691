import { AxiosError } from "axios";
import { ApiTracking } from "../../../api/api";
import { TrackingResultV2Interface } from "../interfaces/tracking-result-v2.interface";

export interface GetTrackingParams {
  event_id: string;
  user_id: string;
  size?: number;
  page?: number;
}

export const GetTracking = async (
  params: GetTrackingParams
): Promise<TrackingResultV2Interface> => {
  try {
    const response = await ApiTracking().get("/trackings", {
      params: {
        event_id: params.event_id,
        user_id: params.user_id,
      },
    });
    return response.data as TrackingResultV2Interface;
  } catch (e) {
    const error = e as AxiosError;
    throw error;
  }
};

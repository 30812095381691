import { AxiosError } from "axios";
import { ApiAuth } from "../../../api/api";
import { GeneralInterface } from "../../../helpers/interfaces/general.interface";
import { UserInterface } from "../interfaces/user.interface";
import { UserInterfaceV2 } from "../interfaces/user-v2.interface";
import { UserSingleResultInterface } from "../states/user-single-result.interface";

export interface UserVolunteerFilter {
  user_organization_id?: string;
  province_id?: string;
  city_id?: string;
  name?: string;
  page?: number;
  size?: number;
}

export const GetUsers = async (
  params: UserVolunteerFilter
): Promise<UserInterfaceV2> => {
  try {
    const response = await ApiAuth().get("/users_volunteer_v2", { params });
    return response.data as UserInterfaceV2;
  } catch (e) {
    const error = e as AxiosError;
    throw error;
  }
};

export const GetUser = async (
  id: string
): Promise<UserSingleResultInterface> => {
  try {
    const response = await ApiAuth().get("/users/" + id);
    return response.data as UserSingleResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    throw error;
  }
};

export const DeleteUser = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiAuth().delete("/users/" + id);
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
};

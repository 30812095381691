import { AxiosError } from "axios";
import { ApiExternal } from "../../../api/api";
import { VolcanoResultInterface } from "../interfaces/volcano-result.interface";

export const GetVolcanoExternalData =
  async (): Promise<VolcanoResultInterface> => {
    try {
      const response = await ApiExternal().get("/public/volcano");
      return response.data as VolcanoResultInterface;
    } catch (e) {
      const error = e as AxiosError;
      throw error;
    }
  };

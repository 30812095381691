import { Modal, Button } from "react-bootstrap";
import { useUserStore } from "../states/user.state";
import { Nav, Tab } from "react-bootstrap";
import styles from "../assets/home.module.css";
import {
  DataBencana,
  DataByTelkomsel,
  DataRelawan,
  DataSumberDaya,
} from "./data-modal.component";
import { useEffect, useState } from "react";
import { useWeatherStore } from "../states/weather.state";
import { useEarthquakeStore } from "../states/earthquake.state";
import { useVolcanoStore } from "../states/volcano.state";
import { usePatahanStore } from "../states/patahan.state";
import { useSubduksiStore } from "../states/subduksi.state";
import { useMapStore } from "../../maps/states/maps.state";
import logoBmkg from "../../../img/data-eksternal/bmkg.png";
import logoPvmbg from "../../../img/data-eksternal/pvmbg.png";
import { useResourceTypeGroupStore } from "../states/resource-type-group.state";
import { useNavigate } from "react-router-dom";

interface ModalProps {
  show: boolean;
  onHide: () => void;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const ModalData: React.FC<ModalProps> = ({ show, onHide, onClick }) => {
  const { userInterface, loading, getUsers } = useUserStore();

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Modal
      show={show}
      onHide={onHide}
      dialogClassName={`${styles["modal-90w"]}`}
    >
      <Modal.Header className={`${styles["modal-header"]}`}>
        <Modal.Title>
          <h5 className={`${styles["modal-title"]}`}>Data</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tab.Container id="left-tabs-example" defaultActiveKey="bencana">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link
                className={`${styles["nav-link"]} nav-link`}
                eventKey="bencana"
              >
                Bencana
              </Nav.Link>
            </Nav.Item>
            {localStorage.getItem("role") === "pusdalops" ? (
              <></>
            ) : (
              <>
                <Nav.Item>
                  <Nav.Link
                    className={`${styles["nav-link"]} nav-link`}
                    eventKey="relawan"
                  >
                    Relawan
                  </Nav.Link>
                </Nav.Item>
              </>
            )}

            <Nav.Item>
              <Nav.Link
                className={`${styles["nav-link"]} nav-link`}
                eventKey="sumberdaya"
              >
                Sumber Daya
              </Nav.Link>
            </Nav.Item>
            {localStorage.getItem("role") === "superadmin" ||
            localStorage.getItem("role") === "pusdalops-terra" ? (
              <Nav.Item>
                <Nav.Link
                  className={`${styles["nav-link"]} nav-link`}
                  eventKey="by-telkomsel"
                >
                  By Telkomsel
                </Nav.Link>
              </Nav.Item>
            ) : (
              <></>
            )}
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="bencana">
              <DataBencana />
            </Tab.Pane>
            <Tab.Pane eventKey="relawan">
              <DataRelawan />
            </Tab.Pane>
            <Tab.Pane eventKey="sumberdaya">
              <DataSumberDaya />
            </Tab.Pane>
            <Tab.Pane eventKey="by-telkomsel">
              <DataByTelkomsel />
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClick}>
          Tutup
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ModalJaringan: React.FC<ModalProps> = ({ show, onHide, onClick }) => {
  const { getResourceTypeGroup, resourceTypeGroupItems } =
    useResourceTypeGroupStore();
  const navigate = useNavigate();
  const [resourceId, setResourceId] = useState<string>();

  useEffect(() => {
    getResourceTypeGroup();
  }, []);

  return (
    <Modal show={show} onHide={onHide} size="sm" centered>
      <Modal.Header className={`${styles["modal-header"]}`}>
        <Modal.Title>
          <h5 className={`${styles["modal-title"]}`}>Jaringan</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form>
          <div className="form-group">
            <select
              name=""
              id=""
              className="form-control"
              onChange={(e) => {
                setResourceId(e.target.value);
              }}
            >
              {localStorage.getItem("role") === "pusdalops" ? (
                <>
                  <option value="" disabled selected>
                    Select one..
                  </option>
                  {resourceTypeGroupItems?.slice(0, 4).map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </>
              ) : (
                <>
                  <option value="" disabled selected>
                    Select one..
                  </option>
                  {resourceTypeGroupItems?.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="info"
          onClick={(e) => {
            navigate(`/resource/${resourceId}`);
          }}
        >
          Tampilkan
        </Button>
        <Button variant="secondary" onClick={onClick}>
          Tutup
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ModalDataEksternal: React.FC<ModalProps> = ({
  show,
  onHide,
  onClick,
}) => {
  const { getWeather, closeWeather, weatherActive } = useWeatherStore();
  const { getEarthquake, closeEarthquake, earthquakeActive } =
    useEarthquakeStore();
  const { getVolcano, closeVolcano, volcanoActive } = useVolcanoStore();
  const { getPatahan, closePatahan, patahanActive } = usePatahanStore();
  const { getSubduksi, closeSubduksi, subduksiActive } = useSubduksiStore();
  const { map } = useMapStore();

  return (
    <>
      <Modal show={show} onHide={onHide} size="sm" centered>
        <Modal.Header className={`${styles["modal-header"]}`}>
          <Modal.Title>
            <h5 className={`${styles["modal-title"]}`}>Data Eksternal</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <input
                type="checkbox"
                checked={weatherActive}
                onChange={(e) => {
                  if (e.target.checked === true) {
                    getWeather();
                  } else {
                    closeWeather();
                  }
                }}
              />
              <label style={{ fontSize: 14 }}>&nbsp;BMKG Cuaca</label>
              <br />
              <input
                type="checkbox"
                checked={earthquakeActive}
                onChange={(e) => {
                  if (e.target.checked === true) {
                    getEarthquake();
                  } else {
                    closeEarthquake();
                  }
                }}
              />
              <label style={{ fontSize: 14 }}>
                &nbsp;BMKG Gempa Terkini M &gt; 5.0
              </label>
              <br />
              <input
                type="checkbox"
                checked={volcanoActive}
                onChange={(e) => {
                  if (e.target.checked === true) {
                    getVolcano();
                  } else {
                    closeVolcano();
                  }
                }}
              />
              <label style={{ fontSize: 14 }}>&nbsp;PVMBG Gunung Api</label>
              <br />
              <input
                type="checkbox"
                checked={patahanActive}
                onChange={(e) => {
                  if (e.target.checked === true) {
                    getPatahan(map);
                  } else {
                    closePatahan();
                  }
                }}
              />
              <label style={{ fontSize: 14 }}>&nbsp;Patahan</label>
              <br />
              <input
                type="checkbox"
                checked={subduksiActive}
                onChange={(e) => {
                  if (e.target.checked === true) {
                    getSubduksi(map);
                  } else {
                    closeSubduksi();
                  }
                }}
              />
              <label style={{ fontSize: 14 }}>
                &nbsp;Subduksi/Pertemuan Lempeng Benua
              </label>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className={`${styles["modal-footer"]}`}>
          <Button
            variant="secondary"
            onClick={onClick}
            className={`${styles["modal-footer"]} btn`}
          >
            Tutup
          </Button>
        </Modal.Footer>
      </Modal>
      <LogoEksternal />
    </>
  );
};

const LogoEksternal = () => {
  const { weatherActive } = useWeatherStore();
  const { earthquakeActive } = useEarthquakeStore();
  const { volcanoActive } = useVolcanoStore();
  const { patahanActive } = usePatahanStore();
  const { subduksiActive } = useSubduksiStore();
  return (
    <>
      {/* Logo BMKG */}
      {weatherActive === true || earthquakeActive === true ? (
        <>
          <img
            src={logoBmkg}
            alt=""
            style={{
              marginBottom: 25,
              marginLeft: 5,
              width: 65,
              height: 65,
              position: "fixed",
              bottom: 0,
            }}
          />
        </>
      ) : (
        <></>
      )}
      {/* Logo PVMBG */}
      {patahanActive === true ||
      subduksiActive === true ||
      volcanoActive === true ? (
        <img
          src={logoPvmbg}
          alt=""
          style={{
            marginBottom: 25,
            marginLeft: 5,
            width: 65,
            height: 65,
            position: "fixed",
            bottom: 0,
          }}
        />
      ) : (
        <></>
      )}
      {/* Logo PVMBG Volcano */}
      {(volcanoActive === true && weatherActive === true) ||
      (volcanoActive === true && earthquakeActive === true) ? (
        <>
          <img
            src={logoBmkg}
            alt=""
            style={{
              marginBottom: 25,
              marginLeft: 5,
              width: 65,
              height: 65,
              position: "fixed",
              bottom: 0,
            }}
          />
          <img
            src={logoPvmbg}
            alt=""
            style={{
              marginBottom: 25,
              marginLeft: 65,
              width: 65,
              height: 65,
              position: "fixed",
              bottom: 0,
            }}
          />
        </>
      ) : (
        <></>
      )}
      {/* Logo PVMBG Patahan */}
      {(patahanActive === true && weatherActive === true) ||
      (patahanActive === true && earthquakeActive === true) ? (
        <>
          <img
            src={logoBmkg}
            alt=""
            style={{
              marginBottom: 25,
              marginLeft: 5,
              width: 65,
              height: 65,
              position: "fixed",
              bottom: 0,
            }}
          />
          <img
            src={logoPvmbg}
            alt=""
            style={{
              marginBottom: 25,
              marginLeft: 65,
              width: 65,
              height: 65,
              position: "fixed",
              bottom: 0,
            }}
          />
        </>
      ) : (
        <></>
      )}
      {/* Logo PVMBG Subduksi */}
      {(subduksiActive === true && weatherActive === true) ||
      (subduksiActive === true && earthquakeActive === true) ? (
        <>
          <img
            src={logoBmkg}
            alt=""
            style={{
              marginBottom: 25,
              marginLeft: 5,
              width: 65,
              height: 65,
              position: "fixed",
              bottom: 0,
            }}
          />
          <img
            src={logoPvmbg}
            alt=""
            style={{
              marginBottom: 25,
              marginLeft: 65,
              width: 65,
              height: 65,
              position: "fixed",
              bottom: 0,
            }}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export { ModalData, ModalJaringan, ModalDataEksternal };

import create from "zustand";
import { EventItem } from "../interfaces/event-photo.interface";

interface ModalPhotoVideoInterface {
  show: boolean;
  handleOpen: (
    modalTitle: "Foto" | "Video",
    lat: number,
    lng: number,
    name: string,
    media: string,
    title: string,
    dateTime: Date,
    userId: string,
    id: string,
    eventItems?: EventItem[],
    district?: string,
    subdistrict?: string,
    village?: string
  ) => void;
  handleChange: (
    modalTitle: "Foto" | "Video",
    lat: number,
    lng: number,
    name: string,
    media: string,
    title: string,
    dateTime: Date,
    userId: string,
    id: string,
    eventItems?: EventItem[],
    district?: string,
    subdistrict?: string,
    village?: string
  ) => void;
  handleClose: () => void;
  modalTitle?: "Foto" | "Video";
  lat?: number;
  lng?: number;
  name?: string;
  media?: string;
  title?: string;
  dateTime?: Date;
  userId?: string;
  id?: string;
  eventItems?: EventItem[];
  district?: string;
  subdistrict?: string;
  village?: string;
}

export const useModalPhotoVideoStore = create<ModalPhotoVideoInterface>(
  (set, get) => ({
    show: false,
    handleClose: () => {
      set({ show: false });
    },
    handleOpen: (
      modalTitle: "Foto" | "Video",
      lat: number,
      lng: number,
      name: string,
      media: string,
      title: string,
      dateTime: Date,
      userId: string,
      id: string,
      eventItems: EventItem[],
      district?: string,
      subdistrict?: string,
      village?: string
    ) => {
      // set state from given params
      set({
        show: true,
        modalTitle: modalTitle,
        lat: lat,
        lng: lng,
        name: name,
        media: media,
        title: title,
        dateTime: dateTime,
        userId: userId,
        id: id,
        eventItems: eventItems,
        district: district,
        subdistrict: subdistrict,
        village: village,
      });
    },
    handleChange: (
      modalTitle: "Foto" | "Video",
      lat: number,
      lng: number,
      name: string,
      media: string,
      title: string,
      dateTime: Date,
      userId: string,
      id: string,
      eventItems?: EventItem[],
      district?: string,
      subdistrict?: string,
      village?: string
    ) => {
      // set state from given params
      set({
        modalTitle: modalTitle,
        lat: lat,
        lng: lng,
        name: name,
        media: media,
        title: title,
        dateTime: dateTime,
        userId: userId,
        id: id,
        eventItems: eventItems,
        district: district,
        subdistrict: subdistrict,
        village: village,
      });
    },
  })
);

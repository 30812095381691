import { UserOrganizationData } from "../interfaces/user-organization.interface";
import create from "zustand";
import { GetUserOrganization } from "../providers/user-organization.provider";

interface UserOrganizationState {
  userOrganizationLoading: boolean;
  getUserOrganization: () => void;
  userOrganizationItems: UserOrganizationData[];
}

export const useUserOrganizationStore = create<UserOrganizationState>(
  (set, get) => ({
    userOrganizationLoading: false,
    getUserOrganization: async () => {
      set({ userOrganizationLoading: true });
      const response = await GetUserOrganization();
      if (response.status) {
        if (response.data.length > 0) {
          set({ userOrganizationItems: response.data });
        } else {
          set({ userOrganizationItems: [] });
        }
      } else {
        set({ userOrganizationItems: [] });
      }
      set({ userOrganizationLoading: false });
    },
    userOrganizationItems: [],
  })
);

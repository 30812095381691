import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "../states/auth.state";
import styles from "../assets/login.module.css";
import TerraLogo from "../../../img/TERRA.png";

function LoginScreen() {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { message, login, loading, isLoggedIn } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoggedIn) {
      navigate("/");
    }
  }, [isLoggedIn]);

  return (
    <section className={styles.hero}>
      <div className={`${styles.container} container`}>
        <div className="row">
          <div className="col-md-6 col-sm-8 mx-auto">
            <div
              className={`card ${styles["border-none"]}`}
              style={{ backgroundColor: "transparent" }}
            >
              <div className="card-body">
                <div className="mt-2">
                  <img
                    src={TerraLogo}
                    alt="logo"
                    className="mx-auto d-block img-fluid"
                    style={{
                      height: 120,
                    }}
                  />
                </div>
                <p
                  className="mt-4 text-white lead text-center"
                  style={{
                    fontSize: 14,
                  }}
                >
                  Masukkan akun untuk mendapatkan akses
                </p>
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  {loading ? (
                    <strong>Logging in</strong>
                  ) : (
                    <strong>{message}</strong>
                  )}
                </div>
                <div className="mt-4"></div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    login(username, password);
                  }}
                >
                  <div className={`${styles["form-group"]} form-group`}>
                    <input
                      placeholder="Nama"
                      className={`${styles["form-control"]} form-control`}
                      type="text"
                      autoFocus
                      onChange={(e) => {
                        setUsername(e.target.value);
                      }}
                    />
                  </div>
                  <div className={`${styles["form-group"]} form-group`}>
                    <input
                      placeholder="Kata Sandi"
                      className={`${styles["form-control"]} form-control`}
                      type="password"
                      onChange={(e) => {
                        setPassword(e.target.value);
                      }}
                    />
                  </div>
                  <button
                    className={`${styles.btn} ${styles["btn-primary"]} btn btn-primary float-right`}
                    style={{
                      fontSize: 14,
                    }}
                  >
                    Masuk
                  </button>
                </form>
                <div className="clearfix"></div>
              </div>
            </div>
            <div
              style={{
                marginTop: 20,
                textAlign: "center",
                fontSize: 12,
              }}
            >
              Aplikasi ini dioptimasi untuk browser Google Chrome, <br />
              mohon gunakan Google Chrome minimal versi 67 atau yang terbaru.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LoginScreen;

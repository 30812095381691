import { AxiosError } from "axios";
import { ApiDisaster } from "../../../api/api";
import { PotencyResultInterface } from "../interfaces/potency-result.interface";

export interface PotencyParams {
  size?: number;
}

export const getPotencyPhotos = async (
  params: PotencyParams
): Promise<PotencyResultInterface> => {
  try {
    const response = await ApiDisaster().get("/potency-photos", { params });
    return response.data as PotencyResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    throw error;
  }
};

export const getPotencyVideos = async (
  params: PotencyParams
): Promise<PotencyResultInterface> => {
  try {
    const response = await ApiDisaster().get("/potency-videos", { params });
    return response.data as PotencyResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    throw error;
  }
};

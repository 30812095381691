import create from "zustand";
import {
  PotencyData,
  PotencyResultInterface,
} from "../interfaces/potency-result.interface";
import {
  PotencyParams,
  getPotencyPhotos,
  getPotencyVideos,
} from "../providers/potency.provider";

interface PotencyState {
  potencyLoading: boolean;
  potencyData: PotencyResultInterface;
  potencyPhotoItems: PotencyData[];
  getPotencyPhoto: (potencyParams: PotencyParams) => void;
  potencyVideoItems: PotencyData[];
  getPotencyVideo: (potencyParams: PotencyParams) => void;
  isOnPotency: boolean;
  setIsOnPotency: (isOnPotency: boolean) => void;
}

export const usePotencyStore = create<PotencyState>((set, get) => ({
  potencyLoading: false,
  potencyData: undefined,
  potencyPhotoItems: [],
  getPotencyPhoto: async (potencyParams: PotencyParams) => {
    set({ potencyPhotoItems: [], potencyLoading: true });
    const result = await getPotencyPhotos(potencyParams);
    set({
      potencyData: result,
      potencyPhotoItems: result.data.items,
      potencyLoading: false,
    });
  },
  potencyVideoItems: [],
  getPotencyVideo: async (potencyParams: PotencyParams) => {
    set({ potencyVideoItems: [], potencyLoading: true });
    const result = await getPotencyVideos(potencyParams);
    set({
      potencyData: result,
      potencyVideoItems: result.data.items,
      potencyLoading: false,
    });
  },
  isOnPotency: false,
  setIsOnPotency: (isOnPotency: boolean) => {
    set({ isOnPotency: isOnPotency });
  },
}));

import TerraLogo from "../../../img/TERRA-Dashboard.png";
import TelkomselLogo from "../../../img/Telkomsel.png";
import styles from "../assets/home.module.css";

const HeaderComponent = () => {
  return (
    <div>
      <div
        id=""
        style={{
          display: "block",
          position: "absolute",
          top: 0,
          width: "100%",
          height: 90,
          color: "#fff",
          zIndex: 99,
          backgroundColor: "#da251c",
        }}
      >
        <img
          style={{
            height: 70,
            marginTop: 10,
            float: "left",
          }}
          className="mx-auto d-block"
          src={TerraLogo}
          alt="TERRA"
        />
        <div className={`${styles["header-text"]}`}>
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            TELKOMSEL EMERGENCY RESPONSE & RECOVERY ACTIVITY
          </span>
          <br />
          <span
            style={{
              fontSize: 30,
              fontWeight: "bold",
            }}
          >
            TACTICAL DISASTER HANDLING SYSTEM
          </span>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          right: 50,
          top: 0,
          zIndex: 100,
          fontSize: 24,
          color: "white",
        }}
      >
        <img
          style={{
            height: 70,
            marginTop: 10,
            float: "right",
          }}
          src={TelkomselLogo}
          alt=""
        />
      </div>
    </div>
  );
};

export default HeaderComponent;

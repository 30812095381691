import { AxiosError } from "axios";
import { ApiDisaster } from "../../../api/api";
import { GeneralInterface } from "../../../helpers/interfaces/general.interface";
import { DisasterEventDetailInterface } from "../interfaces/disaster-event-detail.interface";
import {
  DisasterEventInterface,
  DisasterEventNonPagingInterface,
} from "../interfaces/disaster-event.interface";

export interface DisasterEventFilter {
  is_terra?: boolean;
  province_code?: string;
  city_code?: string;
  event_type_id?: string;
  time_filter?: string;
  name?: string;
  page?: number;
  size?: number;
}

export const GetEvents = async (
  params: DisasterEventFilter
): Promise<DisasterEventInterface> => {
  try {
    const response = await ApiDisaster().get("/events", { params });
    return response.data as DisasterEventInterface;
  } catch (e) {
    const error = e as AxiosError;
    throw error;
  }
};

// for home
export const GetEventsNonPaging = async (
  params: DisasterEventFilter
): Promise<DisasterEventNonPagingInterface> => {
  try {
    const response = await ApiDisaster().get("/events-non-paging", {
      params: {
        is_terra: params.is_terra ?? undefined,
        province_code: params.province_code,
        city_code: params.city_code,
        event_type_id: params.event_type_id,
        time_filter: params.time_filter,
        name: params.name,
        page: params.page,
        size: params.size,
      },
    });
    return response.data as DisasterEventNonPagingInterface;
  } catch (e) {
    const error = e as AxiosError;
    throw error;
  }
};

// detail
export const GetEvent = async (
  eventId: string
): Promise<DisasterEventDetailInterface> => {
  try {
    const response = await ApiDisaster().get(`/events/${eventId}`);
    return response.data as DisasterEventDetailInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error.response?.data as DisasterEventDetailInterface;
  }
};

export const DeleteEvent = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiDisaster().delete("/events/" + id);
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
};

import { EventTypeData } from "../interfaces/event-type-result.interface";
import create from 'zustand';
import { GetEventType } from "../providers/event-type.provider";

interface EventTypeState {
  eventTypeLoading: boolean;
  getEventType: () => void;
  eventTypeItems: EventTypeData[];
}

export const useEventTypeStore = create<EventTypeState>((set, get) => ({
  eventTypeLoading: false,
  getEventType: async () => {
    set({ eventTypeLoading: true });
    const response = await GetEventType();
    if (response.status) {
      if (response.data.length > 0) {
        set({ eventTypeItems: response.data });
      } else {
        set({ eventTypeItems: [] });
      }
    } else {
      set({ eventTypeItems: [] });
    }
    set({ eventTypeLoading: false });
  },
  eventTypeItems: [],
}))
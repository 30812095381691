import { AxiosError } from "axios";
import { ApiDisaster } from "../../../api/api";
import { EventItemsInterface } from "../interfaces/event-items.interface";

export const GetEventItems = async (
  id: string
): Promise<EventItemsInterface> => {
  try {
    const response = await ApiDisaster().get("/event-items-recap/" + id);
    return response.data as EventItemsInterface;
  } catch (e) {
    const error = e as AxiosError;
    throw error;
  }
};

import { AxiosError } from "axios";
import { ApiAuth } from "../../../api/api";
import { UserDetailInterface } from "../interfaces/user-detail.interface";

export const getUserDetails = async (
  userId: string
): Promise<UserDetailInterface> => {
  try {
    const response = await ApiAuth().get(`/users/${userId}`);
    return response.data as UserDetailInterface;
  } catch (e) {
    const error = e as AxiosError;
    throw error;
  }
};

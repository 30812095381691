import { AxiosError } from "axios";
import { ApiChat } from "../../../api/api";
import { RoomSingleResultInterface } from "../interfaces/room-single-result.interface";

export interface GetRoomParams {
  receiver_id: string;
}

export const GetRoomBySenderReceiver = async (params: GetRoomParams): Promise<RoomSingleResultInterface> => {
  try {
    const response = await ApiChat().get("/rooms-by-sender-receiver", { 
      params: params
     });
    return response.data as RoomSingleResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error?.response?.data as RoomSingleResultInterface;
  }
}

export const CreateRoom = async (form: any): Promise<RoomSingleResultInterface> => {
  try {
    const response = await ApiChat().post("/rooms", form);
    return response.data as RoomSingleResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error?.response?.data as RoomSingleResultInterface;
  }
}
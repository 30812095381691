import { toast } from "react-toastify";
import { EventPhotoData } from "../interfaces/event-photo.interface";
import {
  EventPhotoParam,
  TrackingPhotoParam,
  getEventPhotos,
  getTrackingPhotos,
} from "../providers/event-photo.provider";
import create from "zustand";

const toastCustomId = "photo-toast";

interface EventPhotoState {
  eventPhotoLoading: boolean;
  trackingPhotoLoading: boolean;
  eventPhotoData?: EventPhotoData[];
  trackingPhotoData?: EventPhotoData[];
  getEventPhoto: (eventPhotoParam: EventPhotoParam) => void;
  getTrackingPhoto: (trackingPhotoParam: TrackingPhotoParam) => void;
  appendEventPhotos?: (data: EventPhotoData) => void;
}

export const useEventPhotoStore = create<EventPhotoState>((set, get) => ({
  eventPhotoLoading: false,
  trackingPhotoLoading: false,
  eventPhotoData: [],
  trackingPhotoData: [],
  getEventPhoto: async (eventPhotoParam: EventPhotoParam) => {
    set({ eventPhotoLoading: true });
    const result = await getEventPhotos(eventPhotoParam);
    set({ eventPhotoData: result.data.items });
  },
  getTrackingPhoto: async (trackingPhotoParam: TrackingPhotoParam) => {
    set({ trackingPhotoLoading: true });
    const result = await getTrackingPhotos(trackingPhotoParam);
    set({ trackingPhotoData: result.data.items });
  },
  appendEventPhotos: (data: EventPhotoData) => {
    // check if data exist
    const index = get().eventPhotoData.findIndex((el) => el.id === data.id);
    if (index === -1) {
      const datas = [data, ...get().eventPhotoData];
      set({ eventPhotoData: datas });
      toast.success("Foto baru telah diupload", {
        toastId: toastCustomId,
        style: { top: 130 },
      });
    }
  },
}));

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalPenugasan from "../../assignments/components/assignment-modal.component";
import { MapDrawingButton } from "../../maps/components/disaster-maps.component";
import { useDisasterDetailStore } from "../states/disaster.state";

const ControlUiComponent = () => {
  const [showPenugasan, setShowPenugasan] = useState(false);
  const handleClose = () => setShowPenugasan(false);
  const handleShow = () => setShowPenugasan(true);
  const navigate = useNavigate();
  const { disasterData } = useDisasterDetailStore();

  return (
    <>
      {/* Button Penugasan */}
      <div
        style={{
          marginBottom: 5,
          marginLeft: 5,
          bottom: 0,
          left: 76,
          cursor: "pointer",
          position: "absolute",
          backgroundColor: "#fff",
          border: "2px solid #fff",
          borderRadius: "4px",
          textAlign: "center",
        }}
        onClick={handleShow}
      >
        <div
          style={{
            fontSize: 12,
            padding: "2px 8px",
          }}
        >
          Penugasan
        </div>
      </div>
      {/* Modal Penugasan */}
      <ModalPenugasan
        show={showPenugasan}
        onHide={handleClose}
        onClick={handleClose}
      />
      {/* Button Lacak Posisi */}
      <div
        style={{
          marginBottom: 5,
          marginLeft: 5,
          bottom: 0,
          left: 161,
          cursor: "pointer",
          position: "absolute",
          backgroundColor: "#fff",
          border: "2px solid #fff",
          borderRadius: "4px",
        }}
        onClick={(e) => {
          if (disasterData !== undefined) {
            navigate(`/tracking/${disasterData.id}`);
          }
        }}
      >
        <div
          style={{
            fontSize: 12,
            padding: "2px 8px",
          }}
        >
          Lacak Posisi
        </div>
      </div>
      <MapDrawingButton />
    </>
  );
};

export default ControlUiComponent;

import { useEffect, useState } from "react";
import { usePotencyStore } from "../../potency/states/potency.state";
import { DisasterEventFilter } from "../providers/disaster-event.provider";
import { useCityStore } from "../states/city.state";
import { useEventStore } from "../states/disaster-event.state";
import { useEventTypeStore } from "../states/event-type.state";
import { useProvinceStore } from "../states/province.state";
import moment from "moment";

const DataComponent = () => {
  const { getEvents } = useEventStore();
  const { setIsOnPotency, isOnPotency } = usePotencyStore();
  const { getProvince, provinceItems } = useProvinceStore();
  const { getCity, cityItems } = useCityStore();
  const { getEventType, eventTypeItems } = useEventTypeStore();
  const [eventFilter, setEventFilter] = useState<DisasterEventFilter>({
    is_terra: undefined,
    province_code: undefined,
    city_code: undefined,
    time_filter: "-1 month",
    event_type_id: undefined,
  });

  const currentYear = moment().year();
  const startYear = currentYear - 4;
  const yearsRange = [];

  for (let year = currentYear; year >= startYear; year--) {
    yearsRange.push({ value: String(year), label: String(year) });
  }

  const times = [
    { value: "today", label: "Hari ini" },
    { value: "-3 days", label: "3 Hari" },
    { value: "-7 days", label: "7 Hari" },
    { value: "-1 month", label: "1 Bulan" },
    { value: "-1 year", label: "1 Tahun" },
    ...yearsRange,
  ];

  useEffect(() => {
    getProvince();
    getEventType();
  }, []);

  useEffect(() => {
    if (eventFilter) {
      getEvents(eventFilter);
    }
  }, [eventFilter]);

  return (
    <div>
      <div
        id=""
        style={{
          display: "block",
          position: "absolute",
          top: 320,
          left: 10,
          backgroundColor: "#fff",
          width: 130,
          height: 40,
          padding: 10,
        }}
      >
        <a
          href="/potency"
          onClick={(e) => {
            if (isOnPotency === false) {
              setIsOnPotency(true);
            } else {
              setIsOnPotency(false);
            }
          }}
          style={{ fontSize: 11, marginBottom: 10, color: "black" }}
        >
          Potensi/Resiko
        </a>
      </div>
      <div
        style={{
          position: "absolute",
          top: 100,
          left: 10,
          backgroundColor: "#fff",
          width: 130,
          height: 215,
          padding: 10,
        }}
      >
        <div
          style={{
            fontSize: 11,
            marginBottom: 10,
          }}
        >
          Data Bencana
        </div>
        <select
          className="form-control"
          style={{
            marginBottom: 5,
            fontSize: 10,
            height: "2rem",
          }}
          onChange={(e) => {
            const provCode = provinceItems.find(
              (el) => el.id === e.target.value
            );
            if (provCode) {
              getCity({ prov_code: provCode?.prov_code });
              setEventFilter({
                ...eventFilter,
                province_code:
                  e.target.value === "" ? undefined : e.target.value,
              });
            } else {
              setEventFilter({
                ...eventFilter,
                province_code: undefined,
              });
            }
          }}
        >
          <option value={""}>PROVINSI</option>
          {provinceItems?.map((item, index) => (
            <option key={index} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
        <select
          className="form-control"
          style={{
            marginBottom: 5,
            fontSize: 10,
            height: "2rem",
          }}
          onChange={(e) => {
            setEventFilter({
              ...eventFilter,
              city_code: e.target.value === "" ? undefined : e.target.value,
            });
          }}
        >
          <option value={""}>KAB/KOTA</option>
          {cityItems?.map((item, index) => (
            <option key={index} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
        <select
          className="form-control"
          style={{
            marginBottom: 5,
            fontSize: 10,
            height: "2rem",
          }}
          onChange={(e) => {
            setEventFilter({
              ...eventFilter,
              event_type_id: e.target.value === "" ? undefined : e.target.value,
            });
          }}
        >
          <option value={""}>JENIS</option>
          {eventTypeItems?.map((item, index) => (
            <option key={index} value={item.id}>
              {item.name}
            </option>
          ))}
        </select>
        <select
          className="form-control"
          style={{
            marginBottom: 5,
            fontSize: 10,
            height: "2rem",
          }}
          onChange={(e) => {
            setEventFilter({
              ...eventFilter,
              time_filter: e.target.value,
            });
          }}
          defaultValue="-1 month"
        >
          {times.map((item, index) => (
            <option key={index} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
        {localStorage.getItem("role") === "superadmin" ||
        localStorage.getItem("role") === "pusdalops-terra" ? (
          <>
            <input
              type="checkbox"
              onChange={(e) => {
                setEventFilter({
                  ...eventFilter,
                  is_terra: e.target.checked === false ? undefined : true,
                });
              }}
            />{" "}
            By Telkomsel
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default DataComponent;

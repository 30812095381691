import { ReverseGeocodeData } from "../interfaces/reverse-geocode.interface";
import {
  ReverseGeocodeParam,
  getReverseGeocodes,
} from "../providers/reverse-geocode.provider";
import create from "zustand";

interface ReverseGeocodeState {
  reverseGeocodeLoading: boolean;
  reverseGeocodeData?: string;
  getReverseGeocode: (reverseGeocodeParam: ReverseGeocodeParam) => void;
}

export const useReverseGeocodeStore = create<ReverseGeocodeState>(
  (set, get) => ({
    reverseGeocodeLoading: false,
    // reverseGeocodeData: [],
    getReverseGeocode: async (reverseGeocodeParam: ReverseGeocodeParam) => {
      // Set loading to true
      set({ reverseGeocodeLoading: true });

      const result = await getReverseGeocodes(reverseGeocodeParam);

      // set failover, prevent data is not exists
      set({
        reverseGeocodeData:
          result.results[0].formatted_address ?? "Lokasi tidak ditemukan...",
      });

      // set loading to false when data fetched
      set({ reverseGeocodeLoading: false });
    },
  })
);

import { ResourceTypeGroupData } from "../interfaces/resource-type-group.interface";
import create from "zustand";
import { getResourceTypeGroups } from "../providers/resource-type-group.provider";

interface ResourceTypeGroupState {
  resourceTypeGroupLoading: boolean;
  getResourceTypeGroup: () => void;
  resourceTypeGroupItems: ResourceTypeGroupData[];
}

export const useResourceTypeGroupStore = create<ResourceTypeGroupState>(
  (set, get) => ({
    resourceTypeGroupLoading: false,
    getResourceTypeGroup: async () => {
      set({ resourceTypeGroupLoading: true });
      const response = await getResourceTypeGroups();
      if (response.status) {
        if (response.data.length > 0) {
          set({ resourceTypeGroupItems: response.data });
        } else {
          set({ resourceTypeGroupItems: [] });
        }
      } else {
        set({ resourceTypeGroupItems: [] });
      }
      set({ resourceTypeGroupLoading: false });
    },
    resourceTypeGroupItems: [],
  })
);

import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import styles from "../../home/assets/home.module.css";
import { useMessageStore } from "../states/message.state";
import { useRoomStore } from "../states/room.state";
import Skeleton from "react-loading-skeleton";
import { useAuthStore } from "../../auth/states/auth.state";
import { dateTimeFormatter } from "../../../helpers/datetime-formatter";
import useWebSocket from "react-use-websocket";
import "react-loading-skeleton/dist/skeleton.css";

interface ChatModalComponentProps {
  userId: string;
  show: boolean;
  handleClose: () => void;
}

interface MessageItem {
  userId: string;
  message: string;
  isSender: boolean;
  createdAt: Date;
}

interface ChatSocketModel {
  room_id: string;
  sender_id: string;
  receiver_id: string;
  message: string;
}

const WSS_FEED_URL: string = `${process.env.REACT_APP_CHAT_URL_WS}/socket`;

const ChatModalComponent: React.FC<ChatModalComponentProps> = ({
  userId,
  show,
  handleClose,
}) => {
  const { getRoom, roomLoading, roomData, roomReset, createRoom } =
    useRoomStore();
  const {
    getMessages,
    messageLoading,
    messageItems,
    messageReset,
    sendMessage,
  } = useMessageStore();
  const [messages, setMessages] = useState<MessageItem[]>([]);
  const { meModel } = useAuthStore();
  const [content, setContent] = useState<string>();

  const { sendJsonMessage, getWebSocket } = useWebSocket(WSS_FEED_URL, {
    onOpen: () => console.log("WebSocket connection opened."),
    onClose: () => console.log("WebSocket connection closed."),
    shouldReconnect: (closeEvent) => true,
    onMessage: (event: WebSocketEventMap["message"]) => {
      try {
        const chatSocketModel = JSON.parse(event.data) as ChatSocketModel;
        if (meModel?.data?.id !== chatSocketModel.sender_id) {
          const msgs = [...messages];
          msgs.push({
            userId: chatSocketModel?.sender_id,
            message: chatSocketModel?.message,
            isSender: false,
            createdAt: new Date(),
          });
          setMessages(msgs);
        }
      } catch (e) {
        console.log("skipped");
      }
    },
  });

  useEffect(() => {
    setMessages([]);
    return () => {
      roomReset();
      messageReset();
      sendJsonMessage({
        action: "unsubscribe",
        topic: roomData?.id,
      });
      setMessages([]);
    };
  }, []);

  useEffect(() => {
    console.log("userId", userId);
    if (show === true && userId !== undefined) {
      getRoom(userId);
    }
  }, [userId, show]);

  useEffect(() => {
    console.log(roomData);
    if (roomData !== undefined) {
      getMessages(roomData.id);
      sendJsonMessage({
        action: "subscribe",
        topic: roomData.id,
      });
    } else {
      setMessages([]);
    }
  }, [roomData]);

  useEffect(() => {
    console.log("asdasd", messageItems);
    if (messageItems.length > 0) {
      const msgs: MessageItem[] = [];
      for (const item of messageItems) {
        msgs.push({
          userId: item.sender_id,
          message: item.content,
          isSender: item.sender_id === meModel?.data?.id ? true : false,
          createdAt: item.created_at,
        });
      }
      setMessages(msgs);
    }
  }, [messageItems]);

  return (
    <Modal
      show={show}
      onHide={handleClose}
      dialogClassName={`${styles["modal-content"]}`}
    >
      <Modal.Header className={`${styles["modal-header"]}`}>
        <Modal.Title>
          <h5 className={`${styles["modal-title"]}`}>Pesan</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ height: 300 }}>
        {messageLoading ? (
          <>
            <div>
              <div className={`${styles["chat"]}`}>
                <Skeleton count={10} />
              </div>
            </div>
          </>
        ) : (
          <div>
            <div className={`${styles["chat"]}`}>
              {messages?.map((item, index) => {
                if (item.isSender) {
                  return (
                    <div
                      key={index}
                      data-time={dateTimeFormatter(
                        Date.parse(item.createdAt.toString())
                      )}
                      className={`${styles["msg"]} ${styles["msg-sent"]}`}
                    >
                      {item.message}
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={index}
                      data-time={dateTimeFormatter(
                        Date.parse(item.createdAt.toString())
                      )}
                      className={`${styles["msg"]} ${styles["msg-rcvd"]}`}
                    >
                      {item.message}
                    </div>
                  );
                }
              })}
              {/* <div
                data-time="16:35"
                className={`${styles["msg"]} ${styles["msg-sent"]}`}>
                Hi!
                <br />
                What's up?
              </div>
              <div
                data-time="Ghazi 16:36"
                className={`${styles["msg"]} ${styles["msg-rcvd"]}`}>
                Hi! <br />
                Doing some CSS research, you?
              </div>
              <div
                data-time="16:38"
                className={`${styles["msg"]} ${styles["msg-sent"]}`}>
                Also learning some cool CSS stuff 🦄
              </div>
              <div
                data-time="16:38"
                className={`${styles["msg"]} ${styles["msg-sent"]}`}>
                !!
              </div>
              <div
                data-time="16:38"
                className={`${styles["msg"]} ${styles["msg-sent"]}`}>
                Up for a coffee today? ☕
              </div>
              <div
                data-time="Ghazi 16:40"
                className={`${styles["msg"]} ${styles["msg-rcvd"]}`}>
                It would be a pleasure!
              </div>
              <div
                data-time="Ghazi 16:40"
                className={`${styles["msg"]} ${styles["msg-rcvd"]}`}>
                😍
              </div> */}
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <input
          type="text"
          placeholder="Ketik pesan ..."
          className="form-control input"
          value={content}
          onChange={(e) => {
            setContent(e.target.value);
          }}
        />
        <Button
          variant="danger"
          onClick={(e) => {
            e.preventDefault();
            let receiverId = "";

            if (roomData !== undefined) {
              if (meModel?.data?.id === roomData.sender_id) {
                receiverId = roomData.receiver_id ?? "";
              } else {
              }

              if (meModel?.data?.id === roomData.receiver_id) {
                receiverId = roomData.sender_id ?? "";
              }

              sendMessage(meModel?.data?.id, receiverId, content, roomData?.id);
            } else {
              receiverId = userId;

              createRoom(meModel?.data?.id, receiverId, content);
            }

            // add content to array messages
            const msgs = [...messages];
            msgs.push({
              userId: meModel?.data?.id,
              message: content,
              isSender: true,
              createdAt: new Date(),
            });
            setMessages(msgs);
            setContent("");
          }}
        >
          Kirim
        </Button>
      </Modal.Footer>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Tutup
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChatModalComponent;

import { useEffect } from "react";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import HeaderComponent from "../../home/components/header.component";
import { DisasterData } from "../../home/interfaces/disaster-event.interface";
import { useEventStore } from "../../home/states/disaster-event.state";
import DisasterMapComponent from "../../maps/components/disaster-maps.component";
import MapComponent from "../../maps/components/maps.component";
import ControlUiComponent from "../components/control-ui.component";
import DisasterDataComponent from "../components/disaster-data.component";
import DisasterNavigationComponent from "../components/disaster-navigation.component";
import { useDisasterDetailStore } from "../states/disaster.state";
import { useGeneralInfoStore } from "../states/general-info.state";

const DisasterScreen = () => {
  const { getDisasterDetail, disasterData } = useDisasterDetailStore();
  const { disasterId } = useParams();
  const { setEventDetail, setIsOnDetail } = useEventStore();

  useEffect(() => {
    if (disasterId !== undefined) {
      getDisasterDetail(disasterId);
      setIsOnDetail(true);
      localStorage.setItem("eventId", disasterId);
    }
  }, [disasterId]);

  useEffect(() => {
    if (disasterData !== undefined) {
      setEventDetail(disasterData);
    }
  }, [disasterData]);

  return (
    <>
      <HeaderComponent />
      <DisasterMapComponent />
      <DisasterDataComponent />
      <DisasterNavigationComponent />
      <ControlUiComponent />
    </>
  );
};

export default DisasterScreen;

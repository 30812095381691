import create from "zustand";
import {
  AssignmentData,
  AssignmentResultInterface,
} from "../interfaces/assignment-result.interface";
import {
  AssignmentParam,
  GetAssignments,
  DeleteAssignment,
  AssignmentDto,
  SubmitAssignment,
} from "../providers/assignments.provider";
import { toast } from "react-toastify";

interface AssignmentState {
  assignments: AssignmentData[];
  assignmentModel?: AssignmentResultInterface;
  assignmentLoading: boolean;
  getAssignments: (assignmentParam: AssignmentParam) => void;
  deleteAssignment: (id: string, assignmentParam: AssignmentParam) => void;
}

export const useAssignmentStore = create<AssignmentState>((set, get) => ({
  assignments: [],
  assignmentSelectDatas: [],
  assignmentModel: undefined,
  assignmentLoading: false,
  getAssignments: async (assignmentParam: AssignmentParam) => {
    set({ assignments: [], assignmentLoading: true });
    const result = await GetAssignments(assignmentParam);
    set({ assignmentModel: result });
    if (result) {
      if (result.status) {
        if (result.data.items.length > 0) {
          set({
            assignments: result.data.items,
          });
        } else {
          set({
            assignments: [],
          });
        }
      } else {
        set({
          assignments: [],
        });
      }
    } else {
      set({
        assignments: [],
      });
    }
    set({ assignmentLoading: false });
  },
  deleteAssignment: async (id: string, param: AssignmentParam) => {
    set({ assignmentLoading: true });
    const result = await DeleteAssignment(id);
    if (result.status) {
      toast.success(result.message);
      get().getAssignments(param);
    } else {
      toast.error(result.message);
    }
    set({ assignmentLoading: false });
  },
}));

interface AssignmentFormState {
  assignmentFormLoading: boolean;
  submitAssignments: (assignmentDtos: AssignmentDto[]) => void;
}

export const useAssignmentFormStore = create<AssignmentFormState>(
  (set, get) => ({
    assignmentFormLoading: false,
    submitAssignments: async (assignmentDtos: AssignmentDto[]) => {
      set({ assignmentFormLoading: true });

      for (const item of assignmentDtos) {
        const response = await SubmitAssignment(item);
        if (response.status) {
          toast.success(`Assignment berhasil dikirim`);
        } else {
          toast.error(`Assignment gagal dikirim`);
        }
      }

      set({ assignmentFormLoading: false });
    },
  })
);

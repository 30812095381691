import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { Button } from "react-bootstrap";
import styles from "../assets/report.module.css";
import { useReportFormStore, useReportStore } from "../states/report.state";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import html2pdf from "html2pdf.js";
import { useDisasterDetailStore } from "../../disaster/states/disaster.state";
import { useEventPhotoStore } from "../../disaster/states/event-photo.state";
import { generateImageUrl } from "../../../helpers/generate-img-url";

const ReportComponent = () => {
  const { disasterId } = useParams();
  const { getReports, report, setDescription, description } = useReportStore();
  const { submitReport, updateReport } = useReportFormStore();
  const [page, setPage] = useState<number>(0);
  const [size, setSize] = useState<number>(10);
  const { getDisasterDetail, disasterData } = useDisasterDetailStore();
  const { getEventPhoto, eventPhotoData } = useEventPhotoStore();
  const navigate = useNavigate();

  const generatePdf = () => {
    const dateTime = Date.now();
    const fileName = disasterData?.name.toLowerCase().replaceAll(" ", "_");
    var pdf = `<div class="ck-content">${description}</div>`;
    const opt = {
      margin: 10,
      filename: `report_${fileName}_${dateTime}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: {
        useCORS: true,
      },
    };
    html2pdf().set(opt).from(pdf).save();
  };

  const onSubmitReport = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (
      report === undefined &&
      (disasterId !== null || disasterId !== undefined)
    ) {
      submitReport({
        description: description,
        fatalities: "",
        material_loss: "",
        signal_condition: "",
        response_handling: "",
        issued_at: "",
        event_id: disasterId,
      });
    } else {
      updateReport(report?.id, {
        description: description,
        fatalities: "",
        material_loss: "",
        signal_condition: "",
        response_handling: "",
        issued_at: "",
        event_id: disasterId,
      });
    }
  };

  const pickPhoto = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const pickPhotoEl = document.getElementById("photo-picker");
    if (pickPhotoEl.style.display === "none") {
      pickPhotoEl.style.display = "block";
    } else {
      pickPhotoEl.style.display = "none";
    }
  };

  useEffect(() => {
    if (disasterId !== undefined) {
      getReports({
        size: size,
        page: page,
        event_id: disasterId,
      });
      getEventPhoto({
        size: 1000,
        page: page,
        event_id: disasterId,
      });
      getDisasterDetail(disasterId);
    }
  }, [page, disasterId]);

  // useEffect(() => {
  //   if (report !== undefined) {
  //     getEventPhoto({
  //       size: 100,
  //       page: page,
  //       event_id: disasterId,
  //     });
  //   }
  //   console.log(eventPhotoData);
  // }, [report]);

  useEffect(() => {
    if (report === undefined) {
      setDescription(`
      <p>
        <img style=width:123px; src=https://storage.solusinet.id/storage/1642870719283--image.png>
        <img class=image-style-align-right style=width:218px; src=https://storage.app-terra.net/storage/1642870719239--image.png>
        
      </p>
      <figure class=table>
          <table>
              <tbody>
                  <tr>
                      <td>Nama Kejadian</td>
                      <td>&nbsp;</td>
                  </tr>
                  <tr>
                      <td>Kronologis / Latar Belakang Kejadian</td>
                      <td>&nbsp;</td>
                  </tr>
                  <tr>
                      <td>Lokasi</td>
                      <td>&nbsp;</td>
                  </tr>
                  <tr>
                      <td>Korban</td>
                      <td>&nbsp;</td>
                  </tr>
                  <tr>
                      <td>Kerugian Materil</td>
                      <td>&nbsp;</td>
                  </tr>
                  <tr>
                      <td>Kondisi Sinyal Telkomsel</td>
                      <td>&nbsp;</td>
                  </tr>
                  <tr>
                      <td>Penanganan</td>
                      <td>&nbsp;</td>
                  </tr>
                  <tr>
                      <td>Pembuat Laporan</td>
                      <td>&nbsp;</td>
                  </tr>
                  <tr>
                      <td>Tanggal / Jam</td>
                      <td>&nbsp;</td>
                  </tr>
              </tbody>
          </table>
      </figure>
      <p>&nbsp;</p>`);
    } else {
      setDescription(report?.description);
    }
  }, [report]);

  return (
    <>
      <div>
        <h1 style={{ textAlign: "center" }}>Laporan Kejadian Bencana</h1>
        <div style={{ marginLeft: 30 }}>
          <Button variant="secondary" onClick={onSubmitReport}>
            Simpan
          </Button>{" "}
          <Button variant="secondary" onClick={generatePdf}>
            Download PDF
          </Button>{" "}
          <Button variant="secondary" onClick={pickPhoto}>
            Pilih Foto
          </Button>{" "}
          <div style={{ float: "right", marginRight: 30 }}>
            <Button variant="danger" onClick={() => navigate(-1)}>
              Kembali Ke Detail Bencana
            </Button>
          </div>
        </div>
        <div style={{ margin: 10 }}></div>
        <div className={`${styles["document-editor"]}`}>
          <div className={`${styles["document-editor__toolbar"]}`}></div>
          <div
            id="photo-picker"
            className={`${styles["scrollimages"]}`}
            style={{ display: "none" }}
          >
            {eventPhotoData?.map((item, index) => (
              <a
                key={index}
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  const insert = `
                          <p>&nbsp;</p>
                          <br/>
                          <figure class="image ck-widget ck-widget_with-resizer ck-widget_selected" contenteditable="false">
                            <img src="${generateImageUrl(
                              item.media_id
                            )}" alt="${item.title}">
                            <figcaption class="ck-editor__editable ck-editor__nested-editable image__caption_highlighted" data-placeholder="Enter image caption" contenteditable="true">${
                              item.title
                            }</figcaption>
                              <div class="ck ck-reset_all ck-widget__type-around">
                                <div class="ck ck-widget__type-around__button ck-widget__type-around__button_before" title="Insert paragraph before block">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 8">
                                  <path d="M9.055.263v3.972h-6.77M1 4.216l2-2.038m-2 2 2 2.038"></path>
                                </svg>
                              </div>
                              <div class="ck ck-widget__type-around__button ck-widget__type-around__button_after" title="Insert paragraph after block">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 8">
                                  <path d="M9.055.263v3.972h-6.77M1 4.216l2-2.038m-2 2 2 2.038"></path>
                                </svg>
                              </div>
                              <div class="ck ck-widget__type-around__fake-caret"></div>
                            </div>
                            <div class="ck ck-reset_all ck-widget__resizer" style="height: 126px; left: 470px; top: 0px; width: 313px;">
                              <div class="ck-widget__resizer__handle ck-widget__resizer__handle-top-left"></div>
                              <div class="ck-widget__resizer__handle ck-widget__resizer__handle-top-right"></div>
                              <div class="ck-widget__resizer__handle ck-widget__resizer__handle-bottom-right"></div>
                              <div class="ck-widget__resizer__handle ck-widget__resizer__handle-bottom-left"></div>
                              <div class="ck ck-size-view" style="display: none;"></div>
                            </div>
                          </figure>
                          <p>&nbsp;</p>
                          <br/>
                          `;
                  let currentData = description;
                  currentData += insert;
                  setDescription(currentData);
                }}
              >
                <img src={generateImageUrl(item.media_id)} alt={item.title} />
              </a>
            ))}
          </div>
          <div className={`${styles["document-editor__editable-container"]}`}>
            <div
              className={`${styles["document-editor__editable"]}`}
              style={{ border: "1px solid black" }}
            >
              <CKEditor
                editor={DecoupledEditor}
                onReady={(editor) => {
                  console.log("Editor is ready to use!", editor.ui);
                  // Insert the toolbar before the editable area.
                  editor.ui
                    .getEditableElement()
                    .parentElement.insertBefore(
                      editor.ui.view.toolbar.element,
                      editor.ui.getEditableElement()
                    );
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setDescription(data);
                }}
                data={description}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportComponent;

import { toast } from "react-toastify";
import { EventVideoData } from "../interfaces/event-video.interface";
import {
  EventVideoParam,
  TrackingVideoParam,
  getEventVideos,
  getTrackingVideos,
} from "../providers/event-video.provider";
import create from "zustand";

const toastCustomId = "video-toast";

interface EventVideoState {
  eventVideoLoading: boolean;
  trackingVideoLoading: boolean;
  eventVideoData?: EventVideoData[];
  trackingVideoData?: EventVideoData[];
  getEventVideo: (eventVideoParam: EventVideoParam) => void;
  getTrackingVideo: (trackingVideoParam: TrackingVideoParam) => void;
  appendEventVideos?: (data: EventVideoData) => void;
}

export const useEventVideoStore = create<EventVideoState>((set, get) => ({
  eventVideoLoading: false,
  trackingVideoLoading: false,
  eventVideoData: [],
  trackingVideoData: [],
  getEventVideo: async (eventVideoParam: EventVideoParam) => {
    set({ eventVideoLoading: true });
    const result = await getEventVideos(eventVideoParam);
    set({ eventVideoData: result.data.items });
  },
  getTrackingVideo: async (trackingVideoparam: TrackingVideoParam) => {
    set({ trackingVideoLoading: true });
    const result = await getTrackingVideos(trackingVideoparam);
    set({ trackingVideoData: result.data.items });
  },
  appendEventVideos: (data: EventVideoData) => {
    // check if data exist
    const index = get().eventVideoData.findIndex((el) => el.id === data.id);
    if (index === -1) {
      const datas = [data, ...get().eventVideoData];
      set({ eventVideoData: datas });
      toast.success("Video baru telah diupload", {
        toastId: toastCustomId,
        style: { top: 130 },
      });
    }
  },
}));

import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Button, Modal, Nav, Tab, Table } from "react-bootstrap";
import { useModalPhotoVideoStore } from "../../disaster/states/modal-photo-video.state";
import { useReverseGeocodeStore } from "../../disaster/states/reverse-geocode.state";
import styles from "../../home/assets/home.module.css";
import { useMapStore } from "../states/maps.state";
import { useUserDetailStore } from "../../disaster/states/user-detail.state";
import { useEventPhotoStore } from "../../disaster/states/event-photo.state";
import { useEventVideoStore } from "../../disaster/states/event-video.state";
import { usePotencyStore } from "../../potency/states/potency.state";
import {
  DataBencana,
  DataRelawan,
  DataSumberDaya,
} from "../../home/components/data-modal.component";
import { event } from "jquery";

const ModalPhotoVideoComponent = () => {
  const {
    show,
    handleClose,
    handleChange,
    title,
    name,
    lat,
    lng,
    dateTime,
    media,
    modalTitle,
    userId,
    id,
    eventItems,
    district,
    subdistrict,
    village,
  } = useModalPhotoVideoStore();

  // reverse geocode logic
  const { apiKey } = useMapStore();
  const { reverseGeocodeLoading, reverseGeocodeData, getReverseGeocode } =
    useReverseGeocodeStore();
  const { userDetailData, getUserDetail } = useUserDetailStore();
  const { eventPhotoData } = useEventPhotoStore();
  const { eventVideoData } = useEventVideoStore();
  const { potencyPhotoItems, potencyVideoItems, isOnPotency } =
    usePotencyStore();
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState<number>();
  const [currentVideoIndex, setCurrentVideoIndex] = useState<number>();
  const [currentPotencyPhotoIndex, setCurrentPotencyPhotoIndex] =
    useState<number>();
  const [currentPotencyVideoIndex, setCurrentPotencyVideoIndex] =
    useState<number>();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    videoRef.current?.load();
  }, [media]);

  useEffect(() => {
    if (id) {
      const photoIndex = eventPhotoData.findIndex((data) => data.id === id);
      setCurrentPhotoIndex(photoIndex);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      const videoIndex = eventVideoData.findIndex((data) => data.id === id);
      setCurrentVideoIndex(videoIndex);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      const potencyPhotoIndex = potencyPhotoItems.findIndex(
        (data) => data.id === id
      );
      setCurrentPotencyPhotoIndex(potencyPhotoIndex);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      const potencyVideoIndex = potencyVideoItems.findIndex(
        (data) => data.id === id
      );
      setCurrentPotencyVideoIndex(potencyVideoIndex);
    }
  }, [id]);

  useEffect(() => {
    // get reverse geocode
    if (lat !== undefined && lng !== undefined) {
      getReverseGeocode({
        key: apiKey,
        latlng: `${lat},${lng}`,
      });
      getUserDetail(`${userId}`);
    }
  }, [lat, lng]);

  const nextPhoto = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const nextIndex = (currentPhotoIndex + 1) % eventPhotoData.length;
    const photoNext = eventPhotoData[nextIndex];
    handleChange(
      "Foto",
      +photoNext.lat,
      +photoNext.lng,
      photoNext.content,
      photoNext.media_id,
      photoNext.title,
      photoNext.createdAt,
      photoNext.user_id,
      photoNext.id,
      photoNext.event_items,
      photoNext.district,
      photoNext.subdistrict,
      photoNext.village
    );
  };

  const nextPotencyPhoto = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const nextIndex = (currentPotencyPhotoIndex + 1) % potencyPhotoItems.length;
    const photoNext = potencyPhotoItems[nextIndex];
    handleChange(
      "Foto",
      +photoNext.lat,
      +photoNext.lng,
      photoNext.content,
      photoNext.media_id,
      photoNext.title,
      photoNext.createdAt,
      photoNext.user_id,
      photoNext.id
    );
  };

  const prevPhoto = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    let prevIndex = 0;
    if (currentPhotoIndex === 0) {
      prevIndex = eventPhotoData.length - 1;
    } else {
      prevIndex = currentPhotoIndex - 1;
    }
    const photoPrev = eventPhotoData[prevIndex];
    handleChange(
      "Foto",
      +photoPrev.lat,
      +photoPrev.lng,
      photoPrev.content,
      photoPrev.media_id,
      photoPrev.title,
      photoPrev.createdAt,
      photoPrev.user_id,
      photoPrev.id,
      photoPrev.event_items,
      photoPrev.district,
      photoPrev.subdistrict,
      photoPrev.village
    );
  };

  const prevPotencyPhoto = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    let prevIndex = 0;
    if (currentPotencyPhotoIndex === 0) {
      prevIndex = potencyPhotoItems.length - 1;
    } else {
      prevIndex = currentPotencyPhotoIndex - 1;
    }
    const photoPrev = potencyPhotoItems[prevIndex];
    handleChange(
      "Foto",
      +photoPrev.lat,
      +photoPrev.lng,
      photoPrev.content,
      photoPrev.media_id,
      photoPrev.title,
      photoPrev.createdAt,
      photoPrev.user_id,
      photoPrev.id
    );
  };

  const nextVideo = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const nextIndex = (currentVideoIndex + 1) % eventVideoData.length;
    const videoNext = eventVideoData[nextIndex];
    handleChange(
      "Video",
      +videoNext.lat,
      +videoNext.lng,
      videoNext.content,
      videoNext.media_id,
      videoNext.title,
      videoNext.createdAt,
      videoNext.user_id,
      videoNext.id,
      videoNext.event_items,
      videoNext.district,
      videoNext.subdistrict,
      videoNext.village
    );
  };

  const nextPotencyVideo = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const nextIndex = (currentPotencyVideoIndex + 1) % potencyVideoItems.length;
    const videoNext = potencyVideoItems[nextIndex];
    handleChange(
      "Video",
      +videoNext.lat,
      +videoNext.lng,
      videoNext.content,
      videoNext.media_id,
      videoNext.title,
      videoNext.createdAt,
      videoNext.user_id,
      videoNext.id
    );
  };

  const prevVideo = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    let prevIndex = 0;
    if (currentPotencyVideoIndex === 0) {
      prevIndex = eventVideoData.length - 1;
    } else {
      prevIndex = currentPotencyVideoIndex - 1;
    }
    const videoPrev = eventVideoData[prevIndex];
    handleChange(
      "Video",
      +videoPrev.lat,
      +videoPrev.lng,
      videoPrev.content,
      videoPrev.media_id,
      videoPrev.title,
      videoPrev.createdAt,
      videoPrev.user_id,
      videoPrev.id,
      videoPrev.event_items,
      videoPrev.district,
      videoPrev.subdistrict,
      videoPrev.village
    );
  };

  const prevPotencyVideo = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    let prevIndex = 0;
    if (currentVideoIndex === 0) {
      prevIndex = potencyVideoItems.length - 1;
    } else {
      prevIndex = currentVideoIndex - 1;
    }
    const videoPrev = potencyVideoItems[prevIndex];
    handleChange(
      "Video",
      +videoPrev?.lat,
      +videoPrev?.lng,
      videoPrev?.content,
      videoPrev?.media_id,
      videoPrev?.title,
      videoPrev?.createdAt,
      videoPrev?.user_id,
      videoPrev?.id
    );
  };

  return (
    <>
      {isOnPotency === true ? (
        <>
          <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header className={`${styles["modal-header"]}`}>
              <Modal.Title>
                <h5 className={`${styles["modal-title"]}`}>{modalTitle}</h5>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ height: 460 }}>
                <div className="row">
                  <div className="col-md-12">
                    {modalTitle === "Foto" ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <i
                          className="material-symbols-outlined"
                          style={{
                            fontSize: 34,
                            marginRight: 10,
                            cursor: "pointer",
                          }}
                          onClick={prevPotencyPhoto}
                        >
                          chevron_left
                        </i>
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "12px",
                          }}
                        >
                          <img
                            className="img-responsive"
                            src={`https://storage.solusinet.id/assets/${media}`}
                            alt=""
                            style={{
                              width: "600px",
                              height: "320px",
                              objectFit: "contain",
                            }}
                          />
                        </div>
                        <i
                          className="material-symbols-outlined"
                          style={{
                            fontSize: 34,
                            marginLeft: 10,
                            cursor: "pointer",
                          }}
                          onClick={nextPotencyPhoto}
                        >
                          chevron_right
                        </i>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <i
                          className="material-symbols-outlined"
                          style={{
                            fontSize: 34,
                            marginRight: 10,
                            cursor: "pointer",
                          }}
                          onClick={prevPotencyVideo}
                        >
                          chevron_left
                        </i>
                        <div
                          style={{
                            position: "relative",
                            marginLeft: 0,
                            marginTop: 0,
                            textAlign: "center",
                            fontSize: 12,
                          }}
                        >
                          <video
                            ref={videoRef}
                            width={600}
                            height={320}
                            controls
                          >
                            <source
                              src={`https://storage.solusinet.id/assets/${media}`}
                            />
                          </video>
                        </div>
                        <i
                          className="material-symbols-outlined"
                          style={{
                            fontSize: 34,
                            marginLeft: 10,
                            cursor: "pointer",
                          }}
                          onClick={nextPotencyVideo}
                        >
                          chevron_right
                        </i>
                      </div>
                    )}
                    <div style={{ textAlign: "center" }}>
                      <strong>{title}</strong>
                    </div>
                    <div style={{ textAlign: "center", fontSize: 12 }}>
                      <div>{name}</div>
                      <div>
                        Hari/Tanggal: {moment(dateTime).format("L, LT")}
                      </div>

                      {/* if data still fetching show Loading then show reverseGeocodeData */}
                      <div>
                        Lokasi:&nbsp;
                        {reverseGeocodeLoading === true
                          ? "Loading..."
                          : reverseGeocodeData}
                      </div>
                      <div>
                        Pengirim:&nbsp;
                        <strong style={{ textTransform: "uppercase" }}>
                          {userDetailData}
                        </strong>
                      </div>
                      <div>Lat: {lat}</div>
                      <div>Lng: {lng}</div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Tutup
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <>
          <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header className={`${styles["modal-header"]}`}>
              <Modal.Title>
                <h5 className={`${styles["modal-title"]}`}>{modalTitle}</h5>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {eventItems?.length > 0 ? (
                <>
                  <Tab.Container id="left-tabs-example" defaultActiveKey="foto">
                    <Nav variant="tabs">
                      <Nav.Item>
                        <Nav.Link
                          className={`${styles["nav-link"]} nav-link`}
                          eventKey="foto"
                        >
                          {modalTitle}
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link
                          className={`${styles["nav-link"]} nav-link`}
                          eventKey="kebutuhan"
                        >
                          Kebutuhan
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="foto">
                        <div style={{ height: 480 }}>
                          <div className="row">
                            <div className="col-md-12">
                              {modalTitle === "Foto" ? (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                >
                                  <i
                                    className="material-symbols-outlined"
                                    style={{
                                      fontSize: 34,
                                      marginRight: 10,
                                      cursor: "pointer",
                                    }}
                                    onClick={prevPhoto}
                                  >
                                    chevron_left
                                  </i>
                                  <div
                                    style={{
                                      textAlign: "center",
                                      fontSize: "12px",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <img
                                      className="img-responsive"
                                      src={`https://storage.solusinet.id/assets/${media}`}
                                      alt=""
                                      style={{
                                        width: "600px",
                                        height: "320px",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </div>
                                  <i
                                    className="material-symbols-outlined"
                                    style={{
                                      fontSize: 34,
                                      marginLeft: 10,
                                      cursor: "pointer",
                                    }}
                                    onClick={nextPhoto}
                                  >
                                    chevron_right
                                  </i>
                                </div>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                  }}
                                >
                                  <i
                                    className="material-symbols-outlined"
                                    style={{
                                      fontSize: 34,
                                      marginRight: 10,
                                      cursor: "pointer",
                                    }}
                                    onClick={prevVideo}
                                  >
                                    chevron_left
                                  </i>
                                  <div
                                    style={{
                                      position: "relative",
                                      marginLeft: 0,
                                      marginTop: 10,
                                      textAlign: "center",
                                      fontSize: 12,
                                    }}
                                  >
                                    <video
                                      ref={videoRef}
                                      width={600}
                                      height={320}
                                      controls
                                    >
                                      <source
                                        src={`https://storage.solusinet.id/assets/${media}`}
                                      />
                                    </video>
                                  </div>
                                  <i
                                    className="material-symbols-outlined"
                                    style={{
                                      fontSize: 34,
                                      marginLeft: 10,
                                      cursor: "pointer",
                                    }}
                                    onClick={nextVideo}
                                  >
                                    chevron_right
                                  </i>
                                </div>
                              )}
                              <div style={{ textAlign: "center" }}>
                                <strong>{title}</strong>
                              </div>
                              <div
                                style={{ textAlign: "center", fontSize: 12 }}
                              >
                                <div>{name}</div>
                                <div>
                                  Hari/Tanggal:{" "}
                                  {moment(dateTime).format("L, LT")}
                                </div>

                                {/* if data still fetching show Loading then show reverseGeocodeData */}
                                <div>
                                  Lokasi:&nbsp;
                                  {reverseGeocodeLoading === true
                                    ? "Loading..."
                                    : reverseGeocodeData}
                                </div>
                                <div>
                                  Pengirim:&nbsp;
                                  <strong
                                    style={{ textTransform: "uppercase" }}
                                  >
                                    {userDetailData}
                                  </strong>
                                </div>
                                <div>Lat: {lat}</div>
                                <div>Lng: {lng}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="kebutuhan">
                        <div style={{ height: 480 }}>
                          <div className="row">
                            <div className="col-12">
                              <Table
                                className={`${styles["table"]}`}
                                style={{
                                  marginTop: 10,
                                }}
                                striped
                                bordered
                                hover
                                size="sm"
                              >
                                <thead style={{ textAlign: "center" }}>
                                  <tr>
                                    <th>No.</th>
                                    <th>Tanggal</th>
                                    <th>Kecamatan</th>
                                    <th>Kelurahan</th>
                                    <th>Desa</th>
                                    <th>Jenis Barang</th>
                                    <th>Jumlah</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {eventItems.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>
                                          {moment(item?.createdAt).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </td>
                                        <td>{district}</td>
                                        <td>{subdistrict}</td>
                                        <td>{village}</td>
                                        <td>{item?.item_name}</td>
                                        <td>{item?.value}</td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </Table>
                              <div
                                style={{
                                  alignItems: "center",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <a
                                  href={`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`}
                                  target="_blank"
                                >
                                  <Button size="sm">
                                    <img
                                      alt="icon_marker"
                                      src={require(`../../../img/gmap.png`)}
                                      style={{ width: 20 }}
                                    ></img>{" "}
                                    Buka di Google Maps
                                  </Button>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </>
              ) : (
                <>
                  <div style={{ height: 480 }}>
                    <div className="row">
                      <div className="col-md-12">
                        {modalTitle === "Foto" ? (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          >
                            <i
                              className="material-symbols-outlined"
                              style={{
                                fontSize: 34,
                                marginRight: 10,
                                cursor: "pointer",
                              }}
                              onClick={prevPhoto}
                            >
                              chevron_left
                            </i>
                            <div
                              style={{
                                textAlign: "center",
                                fontSize: "12px",
                                marginTop: "20px",
                              }}
                            >
                              <img
                                className="img-responsive"
                                src={`https://storage.solusinet.id/assets/${media}`}
                                alt=""
                                style={{
                                  width: "600px",
                                  height: "320px",
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                            <i
                              className="material-symbols-outlined"
                              style={{
                                fontSize: 34,
                                marginLeft: 10,
                                cursor: "pointer",
                              }}
                              onClick={nextPhoto}
                            >
                              chevron_right
                            </i>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          >
                            <i
                              className="material-symbols-outlined"
                              style={{
                                fontSize: 34,
                                marginRight: 10,
                                cursor: "pointer",
                              }}
                              onClick={prevVideo}
                            >
                              chevron_left
                            </i>
                            <div
                              style={{
                                position: "relative",
                                marginLeft: 0,
                                marginTop: 0,
                                textAlign: "center",
                                fontSize: 12,
                              }}
                            >
                              <video
                                ref={videoRef}
                                width={600}
                                height={320}
                                controls
                              >
                                <source
                                  src={`https://storage.solusinet.id/assets/${media}`}
                                />
                              </video>
                            </div>
                            <i
                              className="material-symbols-outlined"
                              style={{
                                fontSize: 34,
                                marginLeft: 10,
                                cursor: "pointer",
                              }}
                              onClick={nextVideo}
                            >
                              chevron_right
                            </i>
                          </div>
                        )}
                        <div style={{ textAlign: "center" }}>
                          <strong>{title}</strong>
                        </div>
                        <div style={{ textAlign: "center", fontSize: 12 }}>
                          <div>{name}</div>
                          <div>
                            Hari/Tanggal: {moment(dateTime).format("L, LT")}
                          </div>

                          {/* if data still fetching show Loading then show reverseGeocodeData */}
                          <div>
                            Lokasi:&nbsp;
                            {reverseGeocodeLoading === true
                              ? "Loading..."
                              : reverseGeocodeData}
                          </div>
                          <div>
                            Pengirim:&nbsp;
                            <strong style={{ textTransform: "uppercase" }}>
                              {userDetailData}
                            </strong>
                          </div>
                          <div>Lat: {lat}</div>
                          <div>Lng: {lng}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Tutup
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default ModalPhotoVideoComponent;

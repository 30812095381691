import { AxiosError } from "axios";
import { ApiDisaster } from "../../../api/api";
import { ProvinceResultInterface } from "../interfaces/province-result.interface";

export interface GetProvinceParams {}

export const GetProvince = async (): Promise<ProvinceResultInterface> => {
  try {
    const response = await ApiDisaster().get("/provinces-non-paging");
    return response.data as ProvinceResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    throw error;
  }
}
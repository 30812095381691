import create from "zustand";
import { SubduksiData } from "../interfaces/subduksi-result.interface";

interface SubduksiState {
  loading: boolean;
  subduksiMap?: google.maps.Data;
  setSubduksiMap?: (map: any) => void;
  subduksis: SubduksiData[];
  subduksiActive: boolean;
  getSubduksi: (map: any) => void;
  closeSubduksi: () => void;
}

export const useSubduksiStore = create<SubduksiState>((set, get) => ({
  loading: false,
  subduksis: [],
  subduksiMap: undefined,
  setSubduksiMap: (map: any) => {
    set({
      subduksiMap: new google.maps.Data({
        map: map,
      }),
    });
  },
  subduksiActive: false,
  closeSubduksi: () => {
    get().subduksiMap.setMap(null);
    set({ subduksis: [], subduksiActive: false });
  },
  getSubduksi: async (map: any) => {
    set({ loading: true, subduksiActive: true });
    get().subduksiMap.setMap(map);
    // load geojson
    get().subduksiMap.loadGeoJson("/assets/Subduksi.geojson");
    // line styling
    get().subduksiMap.setStyle({
      strokeColor: "#add8e6",
      strokeWeight: 2,
    });
    set({ loading: false });
  },
}));

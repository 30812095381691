import { Link } from "react-router-dom";
import navHome from "../../../img/nav/home.png";
import { useEventStore } from "../../home/states/disaster-event.state";

const DisasterNavigationComponent = () => {
  // call useEventStore and setIsOnDetail, setEventDetail state
  const { setIsOnDetail, setEventDetail } = useEventStore();

  return (
    <div>
      <nav
        id=""
        className="circle-nav-wrapper"
        style={{
          marginTop: 100,
          float: "right",
          marginRight: 10,
        }}
      >
        <Link
          type="a"
          to={"/"}
          onClick={(e) => {
            // when back to home or route '/' set isOnDetail to false and eventDetail to undefined
            setIsOnDetail(false);
            setEventDetail(undefined);
            localStorage.removeItem("eventId");
          }}
        >
          <img src={navHome} alt="" title="Beranda" style={{ height: 50 }} />
        </Link>
      </nav>
    </div>
  );
};

export default DisasterNavigationComponent;

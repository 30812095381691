import create from "zustand";
import { Gempa } from "../interfaces/earthquake-result.interface";
import { GetEarthquakeExternalData } from "../providers/earthquake.provider";

interface EarthquakeState {
  loading: boolean;
  earthquakes: Gempa[];
  earthquakeActive: boolean;
  getEarthquake: () => void;
  closeEarthquake: () => void;
}

export const useEarthquakeStore = create<EarthquakeState>((set, get) => ({
  loading: false,
  earthquakes: [],
  earthquakeActive: false,
  closeEarthquake: () => {
    set({ earthquakes: [], earthquakeActive: false });
  },
  getEarthquake: async () => {
    set({ loading: true, earthquakeActive: true });
    const response = await GetEarthquakeExternalData();
    if (response) {
      if (response.status) {
        let earthquakes: Gempa[] = [];
        const parsedEarthquakes = response.data.items.map(
          (item, index) => item.earthquake
        );
        parsedEarthquakes.forEach((item, index) => {
          item.Infogempa.gempa.forEach((itemm, indexx) => {
            earthquakes.push(itemm);
          });
        });
        set({ earthquakes: earthquakes });
      } else {
        set({ earthquakes: [] });
      }
    } else {
      set({ earthquakes: [] });
    }
    set({ loading: false });
  },
}));

import { AxiosError } from "axios";
import { ApiDisaster } from "../../../api/api";
import { CityResultInterface } from "../interfaces/city-result.interface";

export interface GetCityParams {
  prov_code: string;
}

export const GetCity = async (params: GetCityParams): Promise<CityResultInterface> => {
  try {
    const response = await ApiDisaster().get("/cities-non-paging", { params });
    return response.data as CityResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    throw error;
  }
}
import { useEffect } from "react";
import HeaderComponent from "../../home/components/header.component";
import PotencyMapComponent from "../../maps/components/potency-maps.component";
import PotencyNavigationComponent from "../components/potency-navigation.component";
import { usePotencyStore } from "../states/potency.state";

const PotencyScreen = () => {
  const {
    getPotencyPhoto,
    getPotencyVideo,
    potencyPhotoItems,
    potencyVideoItems,
    isOnPotency,
    setIsOnPotency,
  } = usePotencyStore();

  useEffect(() => {
    setIsOnPotency(true);
  }, [isOnPotency]);
  return (
    <>
      <HeaderComponent />
      <PotencyNavigationComponent />
      <PotencyMapComponent />
    </>
  );
};
export default PotencyScreen;

import { toast } from "react-toastify";
import create from "zustand";
import {
  DisasterData,
  DisasterEventInterface,
} from "../interfaces/disaster-event.interface";
import {
  DeleteEvent,
  DisasterEventFilter,
  GetEvents,
} from "../providers/disaster-event.provider";

interface EventTableState {
  eventTableInterface: DisasterEventInterface;
  eventTableInterfaceExport: DisasterEventInterface;
  eventTableItems: DisasterData[];
  eventTableItemsExport: DisasterData[];
  loading: boolean;
  loadingExport: boolean;
  getEventsTable: (eventFilter: DisasterEventFilter) => void;
  getEventsTableExport: (eventFilter: DisasterEventFilter) => void;
  deleteEvent: (id: string) => void;

  eventTableInterfaceTerra: DisasterEventInterface;
  eventTableInterfaceExportTerra: DisasterEventInterface;
  eventTableItemsTerra: DisasterData[];
  eventTableItemsExportTerra: DisasterData[];
  loadingTerra: boolean;
  loadingExportTerra: boolean;
  getEventsTableTerra: (eventFilter: DisasterEventFilter) => void;
  getEventsTableExportTerra: (eventFilter: DisasterEventFilter) => void;
}

export const useEventTableStore = create<EventTableState>((set, get) => ({
  eventTableInterface: undefined,
  eventTableInterfaceExport: undefined,
  eventTableItems: [],
  eventTableItemsExport: [],
  loading: false,
  loadingExport: false,
  eventTableInterfaceTerra: undefined,
  eventTableInterfaceExportTerra: undefined,
  eventTableItemsTerra: [],
  eventTableItemsExportTerra: [],
  loadingTerra: false,
  loadingExportTerra: false,
  eventFilter: undefined,
  eventFilterTerra: undefined,
  getEventsTable: async (eventFilter: DisasterEventFilter) => {
    set({ eventTableItems: [], loading: true });
    const response = await GetEvents(eventFilter);
    if (response.status) {
      if (response.data.items.length > 0) {
        set({ eventTableItems: response.data.items });
      } else {
        set({ eventTableItems: [] });
      }
    } else {
      set({ eventTableItems: [] });
    }
    set({ eventTableInterface: response, loading: false });
  },
  getEventsTableExport: async (eventFilter: DisasterEventFilter) => {
    set({ eventTableItemsExport: [], loadingExport: true });
    const response = await GetEvents(eventFilter);
    if (response.status) {
      if (response.data.items.length > 0) {
        set({ eventTableItemsExport: response.data.items });
      } else {
        set({ eventTableItemsExport: [] });
      }
    } else {
      set({ eventTableItemsExport: [] });
    }
    set({ eventTableInterfaceExport: response, loadingExport: false });
  },
  getEventsTableTerra: async (eventFilterTerra: DisasterEventFilter) => {
    set({ eventTableItemsTerra: [], loadingTerra: true });
    const response = await GetEvents(eventFilterTerra);
    if (response.status) {
      if (response.data.items.length > 0) {
        set({ eventTableItemsTerra: response.data.items });
      } else {
        set({ eventTableItemsTerra: [] });
      }
    } else {
      set({ eventTableItemsTerra: [] });
    }
    set({ eventTableInterfaceTerra: response, loadingTerra: false });
  },
  getEventsTableExportTerra: async (eventFilterTerra: DisasterEventFilter) => {
    set({ eventTableItemsExportTerra: [], loadingExportTerra: true });
    const response = await GetEvents(eventFilterTerra);
    if (response.status) {
      if (response.data.items.length > 0) {
        set({ eventTableItemsExportTerra: response.data.items });
      } else {
        set({ eventTableItemsExportTerra: [] });
      }
    } else {
      set({ eventTableItemsExportTerra: [] });
    }
    set({
      eventTableInterfaceExportTerra: response,
      loadingExportTerra: false,
    });
  },
  deleteEvent: async (id: string) => {
    set({ loading: true });
    const response = await DeleteEvent(id);
    if (response.status) {
      toast.success(response.message);
      get().getEventsTable({ size: 15, page: 0 });
    } else {
      toast.error(response.message);
    }
    set({ loading: false });
  },
}));

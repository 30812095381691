import { useEffect } from "react";
import MapComponent from "../../maps/components/maps.component";
import DataComponent from "../components/data.component";
import HeaderComponent from "../components/header.component";
import NavigationComponent from "../components/navigation.component";
import { useSocketStore } from "../states/socket.state";

const DashboardScreen = () => {
  useEffect(() => {
    localStorage.removeItem("eventId");
  }, []);

  return (
    <>
      <HeaderComponent />
      <MapComponent />
      <DataComponent />
      <NavigationComponent />
    </>
  );
};

export default DashboardScreen;

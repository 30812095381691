import create from "zustand";
import { PatahanData } from "../interfaces/patahan-result.interface";

interface PatahanState {
  loading: boolean;
  patahanMap?: google.maps.Data;
  setPatahanMap?: (map: any) => void;
  patahans: PatahanData[];
  patahanActive: boolean;
  getPatahan: (map: any) => void;
  closePatahan: () => void;
}

export const usePatahanStore = create<PatahanState>((set, get) => ({
  loading: false,
  patahans: [],
  patahanMap: undefined,
  setPatahanMap: (map: any) => {
    set({
      patahanMap: new google.maps.Data({
        map: map,
      }),
    });
  },
  patahanActive: false,
  closePatahan: () => {
    get().patahanMap.setMap(null);
    set({ patahans: [], patahanActive: false });
  },
  getPatahan: async (map: any) => {
    set({ loading: true, patahanActive: true });
    get().patahanMap.setMap(map);
    // load geojson
    get().patahanMap.loadGeoJson("/assets/patahan.geojson");
    // line styling
    get().patahanMap.setStyle({
      strokeColor: "#add8e6",
      strokeWeight: 2,
    });
    set({ loading: false });
  },
}));

import { AxiosError } from "axios";
import { ApiDisaster } from "../../../api/api";
import { EventPhotoInterface } from "../interfaces/event-photo.interface";

export interface EventPhotoParam {
  event_id: string;
  page: number;
  size: number;
}

export interface TrackingPhotoParam {
  event_id: string;
  page: number;
  size: number;
  user_id?: string;
}

export const getEventPhotos = async (
  param: EventPhotoParam
): Promise<EventPhotoInterface> => {
  try {
    const response = await ApiDisaster().get("/event-photos", {
      params: {
        event_id: param.event_id,
        page: param.page,
        size: param.size,
      },
    });
    return response.data as EventPhotoInterface;
  } catch (e) {
    const error = e as AxiosError;
    throw error;
  }
};

export const getTrackingPhotos = async (
  param: TrackingPhotoParam
): Promise<EventPhotoInterface> => {
  try {
    const response = await ApiDisaster().get("/event-photos", {
      params: {
        event_id: param.event_id,
        page: param.page,
        size: param.size,
        user_id: param.user_id,
      },
    });
    return response.data as EventPhotoInterface;
  } catch (e) {
    const error = e as AxiosError;
    throw error;
  }
};

import GoogleMapReact from "google-map-react";
import { useEffect, useRef, useState } from "react";
import { useMapStore } from "../states/maps.state";
import { useResourceStore } from "../../home/states/resource.state";
import styled from "styled-components";
import styles from "../../home/assets/home.module.css";
import { Arrow, useLayer } from "react-laag";
import { useUserStore } from "../../home/states/user.state";
import { useProvinceStore } from "../../home/states/province.state";
import { Button, Modal, Table } from "react-bootstrap";
import { UserVolunteerFilter } from "../../home/providers/user.provider";
import { UserData } from "../../home/interfaces/user-v2.interface";

const ResourceMapComponent = () => {
  const { apiKey, defaultCoords, zoom, center, handleChange, setMap } =
    useMapStore();
  const googleMapsRef = useRef<GoogleMapReact>(null);
  const { resourceId, getResource, resourceData } = useResourceStore();
  const { getProvince, provinceItems } = useProvinceStore();
  const { userInterface, getUsers, userItems, loading, deleteUser } =
    useUserStore();
  const [userFilter, setUserFilter] = useState<UserVolunteerFilter>({
    user_organization_id: undefined,
    province_id: undefined,
    city_id: undefined,
    name: undefined,
    size: 1000,
  });

  useEffect(() => {
    if (
      resourceId !== undefined &&
      resourceId === "e0db66eb-467e-47a3-8a32-eae8a964a008"
    ) {
      getResource({
        resource_type_group_id: resourceId,
        size: 1000,
      });
      getProvince();
      getUsers(userFilter);
    } else if (
      resourceId != undefined &&
      resourceId !== "e0db66eb-467e-47a3-8a32-eae8a964a008"
    ) {
      getResource({
        resource_type_group_id: resourceId,
        size: 1000,
      });
    }
  }, [resourceId]);
  let userProvince = userInterface?.data?.items?.map(
    (item) => item.province_id
  );
  let uniqueProvince = [...new Set(userProvince)];
  let provinceResult = provinceItems.filter((item) =>
    uniqueProvince.includes(item.id)
  );

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: -999,
      }}
    >
      {resourceId === "e0db66eb-467e-47a3-8a32-eae8a964a008" ? (
        <>
          <GoogleMapReact
            ref={googleMapsRef}
            bootstrapURLKeys={{
              key: apiKey,
              libraries: "drawing",
            }}
            defaultCenter={{
              lat: defaultCoords.lat,
              lng: defaultCoords.lng,
            }}
            center={{
              lat: +defaultCoords.lat,
              lng: +defaultCoords.lng,
            }}
            zoom={zoom}
            options={(maps) => ({
              mapTypeId: "hybrid",
              fullscreenControl: false,
              zoomControl: true,
              zoomControlOptions: {
                position: maps.ControlPosition.RIGHT_BOTTOM,
              },
              mapTypeControl: true,
              mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.RIGHT_BOTTOM,
              },
            })}
            yesIWantToUseGoogleMapApiInternals={true}
            onChange={handleChange}
            onGoogleApiLoaded={({ map, maps }) => {
              setMap(map);
            }}
          >
            {provinceResult?.map((item, index) => {
              if (
                item?.lat === "" ||
                item?.lng === "" ||
                item?.lat === "0" ||
                item?.lng === "0"
              ) {
                return <></>;
              }
              return (
                <MarkerRelawan
                  key={index}
                  location={item?.name}
                  lat={+item?.lat ?? 0}
                  lng={+item?.lng ?? 0}
                  provId={item.id}
                />
              );
            })}
          </GoogleMapReact>
        </>
      ) : (
        <>
          <GoogleMapReact
            ref={googleMapsRef}
            bootstrapURLKeys={{
              key: apiKey,
              libraries: "drawing",
            }}
            defaultCenter={{
              lat: defaultCoords.lat,
              lng: defaultCoords.lng,
            }}
            center={{
              lat: +defaultCoords.lat,
              lng: +defaultCoords.lng,
            }}
            zoom={zoom}
            options={(maps) => ({
              mapTypeId: "hybrid",
              fullscreenControl: false,
              zoomControl: true,
              zoomControlOptions: {
                position: maps.ControlPosition.RIGHT_BOTTOM,
              },
              mapTypeControl: true,
              mapTypeControlOptions: {
                style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: maps.ControlPosition.RIGHT_BOTTOM,
              },
            })}
            yesIWantToUseGoogleMapApiInternals={true}
            onChange={handleChange}
            onGoogleApiLoaded={({ map, maps }) => {
              setMap(map);
            }}
          >
            {resourceData?.data?.items?.map((item, index) => {
              if (
                item?.lat === "" ||
                item?.lng === "" ||
                item?.lat === "0" ||
                item?.lng === "0"
              ) {
                return <></>;
              }
              return (
                <MarkerResource
                  key={index}
                  resourceName={item?.resource_type_group.name}
                  name={item?.name}
                  location={item?.location}
                  lat={+item?.lat ?? 0}
                  lng={+item?.lng ?? 0}
                  icon={
                    <img
                      src={require(`../../../img/icons_new/${
                        item?.resource_type?.icon === ""
                          ? "point.png"
                          : item?.resource_type?.icon
                      }`)}
                    ></img>
                  }
                />
              );
            })}
          </GoogleMapReact>
        </>
      )}
    </div>
  );
};

const StyledMarker = {
  width: 24,
  height: 24,
  border: 0,
  padding: 0,
  margin: 0,
  maxWidth: "none",
  cursor: "pointer",
};

const InfoWindow = styled.div`
  padding: 5px;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border: 4px solid #da251c;
  border-radius: 5px;
`;

interface MarkerResourceProps {
  resourceName: string;
  name: string;
  location: string;
  lat: number;
  lng: number;
  icon: React.ReactNode;
}

interface MarkerRelawanProps {
  location: string;
  lat: number;
  lng: number;
  provId: string;
}

export const MarkerResource: React.FC<MarkerResourceProps> = ({
  resourceName,
  name,
  location,
  lat,
  lng,
  icon,
}) => {
  const [isOpen, setOpen] = useState(false);
  const { triggerProps, layerProps, arrowProps, renderLayer } = useLayer({
    isOpen,
    triggerOffset: 10,
    auto: true,
    overflowContainer: false,
    onOutsideClick: () => setOpen(false),
  });
  return (
    <>
      <div
        style={StyledMarker}
        {...triggerProps}
        onClick={() => setOpen((prev) => !prev)}
      >
        {icon}
      </div>
      {isOpen &&
        renderLayer(
          <InfoWindow
            {...layerProps}
            style={{ ...layerProps.style, width: 300, height: 170 }}
          >
            <div
              className={`${styles["si-frame"]} ${styles["si-content-wrapper"]}`}
            >
              <button
                type="button"
                className={`${styles["si-close-button"]}`}
                onClick={() => setOpen(false)}
              >
                ×
              </button>
              <div
                className={`${styles["si-content"]}`}
                style={{
                  maxWidth: 242,
                  maxHeight: 486,
                  fontSize: 12,
                }}
              >
                <h1>{resourceName}</h1>
                <p>
                  Nama: {name}
                  <br />
                  Lokasi: {location}
                </p>
              </div>
            </div>
            {/* @ts-ignore */}
            <Arrow {...arrowProps} roundness={0} />
          </InfoWindow>
        )}
    </>
  );
};

const MarkerRelawan: React.FC<MarkerRelawanProps> = ({
  location,
  lat,
  lng,
  provId,
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { userItems, loading } = useUserStore();
  const [count, setCount] = useState(0);

  const UserVolunteers = () => {
    return (
      <>
        {userItems
          .filter((el) => el.province_id === provId)
          .map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.role_name}</td>
              <td>{item.fullName}</td>
              <td>
                {item?.gender === "m"
                  ? (item?.gender).replace("m", "Laki-laki")
                  : (item?.gender).replace("f", "Perempuan")}
              </td>
              <td>{item.phone_number}</td>
              <td>{item.province_name}</td>
              <td>{item.city_name}</td>
            </tr>
          ))}
      </>
    );
  };

  return (
    <>
      <div
        style={{
          width: 15,
          height: 15,
          border: 0,
          padding: 0,
          margin: 0,
          maxWidth: "none",
          cursor: "pointer",
        }}
        onClick={(e) => {
          setShow(true);
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={require("../../../img/location.png")}
            alt="marker_user"
            style={{ width: 30 }}
          />
          <div
            style={{
              width: 150,
              backgroundColor: "rgba(0, 0, 0, 0.2)",

              textAlign: "center",
              padding: 5,
              color: "white",
              fontWeight: 700,
            }}
          >
            {location}
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header className={`${styles["modal-header"]}`}>
          <Modal.Title>
            <h5 className={`${styles["modal-title"]}`}>Relawan</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div style={{ textAlign: "center" }}>
                <strong>{location}</strong>
                <Table
                  className={`${styles["table"]}`}
                  style={{
                    marginTop: 10,
                  }}
                  striped
                  bordered
                  hover
                  size="sm"
                >
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Grup</th>
                      <th>Nama</th>
                      <th>Jenis Kelamin</th>
                      <th>Telp</th>
                      <th>Provinsi</th>
                      <th>Kab/Kota</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading && (
                      <tr>
                        <td colSpan={7} style={{ textAlign: "center" }}>
                          <img src={require(`../../../img/loading.gif`)}></img>
                        </td>
                      </tr>
                    )}
                    <UserVolunteers />
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Tutup
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ResourceMapComponent;

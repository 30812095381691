import { ReactNode, useEffect, useState } from "react";
import styles from "../assets/disaster.module.css";
import { Modal, Button } from "react-bootstrap";
import moment from "moment";
import { useModalPhotoVideoStore } from "../states/modal-photo-video.state";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { EventItem } from "../interfaces/event-photo.interface";

interface EventPhotoProps {
  lat: number;
  lng: number;
  name: string;
  media: string;
  title: string;
  dateTime: Date;
  userId: string;
  id: string;
  eventItems: EventItem[];
  district?: string;
  subdistrict?: string;
  village?: string;
}

interface EventVideoProps {
  thumbnail: string;
  lat: number;
  lng: number;
  name: string;
  media: string;
  title: string;
  dateTime: Date;
  userId: string;
  id: string;
  eventItems: EventItem[];
  district?: string;
  subdistrict?: string;
  village?: string;
}

interface EventGeneralInfoProps {
  createdAt: Date;
  title: string;
  source: string;
  Tnum: ReactNode;
  content: string;
}

interface EventEventItemsProps {
  itemType: string;
  itemAmount: string;
  Tnum: ReactNode;
}

export function EventPhoto({
  lat,
  lng,
  name,
  media,
  title,
  dateTime,
  userId,
  id,
  eventItems,
  district,
  subdistrict,
  village,
}: EventPhotoProps) {
  const { handleOpen } = useModalPhotoVideoStore();

  return (
    <>
      <img
        src={`https://storage.solusinet.id/assets/${media}`}
        style={{
          height: 50,
          width: 80,
          marginTop: 5,
          marginRight: 5,
          cursor: "pointer",
        }}
        onClick={(e) =>
          handleOpen(
            "Foto",
            lat,
            lng,
            name,
            media,
            title,
            dateTime,
            userId,
            id,
            eventItems,
            district,
            subdistrict,
            village
          )
        }
      />
    </>
  );
}

export function EventVideo({
  lat,
  lng,
  name,
  media,
  title,
  dateTime,
  thumbnail,
  userId,
  id,
  eventItems,
  district,
  subdistrict,
  village,
}: EventVideoProps) {
  const { handleOpen } = useModalPhotoVideoStore();

  return (
    <>
      <img
        src={`https://storage.solusinet.id/${thumbnail}`}
        style={{
          height: 50,
          width: 80,
          marginTop: 5,
          marginRight: 5,
          cursor: "pointer",
        }}
        onClick={(e) =>
          handleOpen(
            "Video",
            lat,
            lng,
            name,
            media,
            title,
            dateTime,
            userId,
            id,
            eventItems,
            district,
            subdistrict,
            village
          )
        }
      />
    </>
  );
}

export function EventBeritaTv({
  Tnum,
  createdAt,
  title,
  source,
  content,
}: EventGeneralInfoProps) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <tr>
        <th
          style={{
            fontSize: 10,
            fontWeight: "normal",
            borderRight: "1px solid #000",
            borderBottom: "1px solid #000",
            textAlign: "center",
          }}
        >
          {Tnum}
        </th>
        <th
          style={{
            fontSize: 10,
            fontWeight: "normal",
            borderRight: "1px solid #000",
            borderBottom: "1px solid #000",
            textAlign: "center",
          }}
        >
          {moment(createdAt).format("L LT")}
        </th>
        <th
          style={{
            fontSize: 10,
            fontWeight: "normal",
            borderRight: "1px solid #000",
            borderBottom: "1px solid #000",
            textAlign: "center",
            cursor: "pointer",
          }}
          className={`${styles["detail-link"]}`}
          onClick={handleShow}
        >
          {title}
        </th>
        <th
          style={{
            fontSize: 10,
            fontWeight: "normal",
            borderBottom: "1px solid #000",
            textAlign: "center",
          }}
        >
          {source}
        </th>
      </tr>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header className={`${styles["modal-header"]}`}>
          <Modal.Title>
            <h5 className={`${styles["modal-title"]}`}>{title} - Berita TV</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <iframe
              width={"100%"}
              height={415}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "85%",
              }}
              src={content}
              allowFullScreen
            ></iframe>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Tutup
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export function EventSiaranLangsung({
  Tnum,
  createdAt,
  title,
  source,
  content,
}: EventGeneralInfoProps) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <tr>
        <th
          style={{
            fontSize: 10,
            fontWeight: "normal",
            borderRight: "1px solid #000",
            borderBottom: "1px solid #000",
            textAlign: "center",
          }}
        >
          {Tnum}
        </th>
        <th
          style={{
            fontSize: 10,
            fontWeight: "normal",
            borderRight: "1px solid #000",
            borderBottom: "1px solid #000",
            textAlign: "center",
          }}
        >
          {moment(createdAt).format("L LT")}
        </th>
        <th
          style={{
            fontSize: 10,
            fontWeight: "normal",
            borderRight: "1px solid #000",
            borderBottom: "1px solid #000",
            textAlign: "center",
            cursor: "pointer",
          }}
          className={`${styles["detail-link"]}`}
          onClick={handleShow}
        >
          {title}
        </th>
        <th
          style={{
            fontSize: 10,
            fontWeight: "normal",
            borderBottom: "1px solid #000",
            textAlign: "center",
          }}
        >
          {source}
        </th>
      </tr>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header className={`${styles["modal-header"]}`}>
          <Modal.Title>
            <h5 className={`${styles["modal-title"]}`}>
              {title} - Siaran Langsung
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <iframe
              width={"100%"}
              height={415}
              style={{
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                width: "85%",
              }}
              src={content}
              allowFullScreen
            ></iframe>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Tutup
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export function EventInformasiUmum({
  Tnum,
  createdAt,
  title,
  source,
  content,
}: EventGeneralInfoProps) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [mimeType, setMimeType] = useState<string>(null);
  const [mimeTypeError, setMimeTypeError] = useState<string>(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    changePage(-1);
  }

  function nextPage(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    changePage(1);
  }

  const downloadPDF = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // using Java Script method to get PDF file
    fetch(`https://storage.solusinet.id/assets/${content}`).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = `${title}.pdf`;
        alink.click();
      });
    });
  };

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    fetch(`https://storage.solusinet.id/assets/${content}`)
      .then((response) => response.headers.get("Content-Type"))
      .then(
        (result) => {
          setMimeType(result);
        },
        (error) => {
          setMimeTypeError(error);
        }
      );
  }, []);

  return (
    <>
      <tr>
        <th
          style={{
            fontSize: 10,
            fontWeight: "normal",
            borderRight: "1px solid #000",
            borderBottom: "1px solid #000",
            textAlign: "center",
          }}
        >
          {Tnum}
        </th>
        <th
          style={{
            fontSize: 10,
            fontWeight: "normal",
            borderRight: "1px solid #000",
            borderBottom: "1px solid #000",
            textAlign: "center",
          }}
        >
          {moment(createdAt).format("L LT")}
        </th>
        <th
          style={{
            fontSize: 10,
            fontWeight: "normal",
            borderRight: "1px solid #000",
            borderBottom: "1px solid #000",
            textAlign: "center",
            cursor: "pointer",
          }}
          className={`${styles["detail-link"]}`}
          onClick={handleShow}
        >
          {title}
        </th>
        <th
          style={{
            fontSize: 10,
            fontWeight: "normal",
            borderBottom: "1px solid #000",
            textAlign: "center",
          }}
        >
          {source}
        </th>
      </tr>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header className={`${styles["modal-header"]}`}>
          <Modal.Title>
            <h5 className={`${styles["modal-title"]}`}>
              {title} - Informasi Umum
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="row">
              <div className="col-md-12">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "12px",
                    }}
                  >
                    {mimeType === "application/pdf" ? (
                      <>
                        <Document
                          file={`https://storage.solusinet.id/assets/${content}`}
                          onLoadSuccess={onDocumentLoadSuccess}
                        >
                          <Page pageNumber={pageNumber} width={750} />
                        </Document>
                        <p>
                          Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                          {numPages || "--"}
                        </p>
                        <Button
                          variant="secondary"
                          size="sm"
                          disabled={pageNumber <= 1}
                          onClick={previousPage}
                        >
                          Previous
                        </Button>{" "}
                        <Button
                          variant="secondary"
                          size="sm"
                          disabled={pageNumber >= numPages}
                          onClick={nextPage}
                        >
                          Next
                        </Button>{" "}
                        <Button
                          variant="secondary"
                          size="sm"
                          onClick={downloadPDF}
                        >
                          <img
                            src="/assets/download.svg"
                            alt=""
                            style={{ width: "70%" }}
                          />
                        </Button>
                      </>
                    ) : (
                      <>
                        <img
                          className="img-responsive"
                          src={`https://storage.solusinet.id/assets/${content}`}
                          alt=""
                          style={{
                            width: "600px",
                            height: "320px",
                            objectFit: "contain",
                          }}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Tutup
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export function EventEventItems({
  Tnum,
  itemAmount,
  itemType,
}: EventEventItemsProps) {
  return (
    <>
      <tr>
        <th
          style={{
            fontSize: 10,
            fontWeight: "normal",
            borderRight: "1px solid #000",
            borderBottom: "1px solid #000",
            textAlign: "center",
          }}
        >
          {Tnum}
        </th>
        <th
          style={{
            fontSize: 10,
            fontWeight: "normal",
            borderRight: "1px solid #000",
            borderBottom: "1px solid #000",
            textAlign: "center",
          }}
        >
          {itemType}
        </th>
        <th
          style={{
            fontSize: 10,
            fontWeight: "normal",
            borderBottom: "1px solid #000",
            textAlign: "center",
          }}
        >
          {itemAmount}
        </th>
      </tr>
    </>
  );
}

import { AxiosError } from "axios";
import { ApiResource } from "../../../api/api";
import { GeneralInterface } from "../../../helpers/interfaces/general.interface";
import { ResourceInterface } from "../interfaces/resource.interface";
import { ResourceNearbyInterface } from "../interfaces/resource-nearby.interface";

export interface GetResourceParams {
  resource_type_group_id?: string;
  province_code?: string;
  city_code?: string;
  name?: string;
  page?: number;
  size?: number;
}

export interface GetResourceNearbyParams {
  lat?: string;
  lng?: string;
  radius?: number;
  resource_type_group_id?: string;
}

export const getResources = async (
  params: GetResourceParams
): Promise<ResourceInterface> => {
  try {
    const response = await ApiResource().get("/resources", { params });
    return response.data as ResourceInterface;
  } catch (e) {
    const error = e as AxiosError;
    throw error;
  }
};

export const getResourcesNearby = async (
  params: GetResourceNearbyParams
): Promise<ResourceNearbyInterface> => {
  try {
    const response = await ApiResource().get("/resources-nearby", { params });
    return response.data as ResourceNearbyInterface;
  } catch (e) {
    const error = e as AxiosError;
    throw error;
  }
};

export const DeleteResource = async (id: string): Promise<GeneralInterface> => {
  try {
    const response = await ApiResource().delete("/resources/" + id);
    return response.data as GeneralInterface;
  } catch (e) {
    let a = e as AxiosError;
    let response: GeneralInterface = a.response?.data;
    return response;
  }
};

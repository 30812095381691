import { AxiosError } from "axios";
import { ApiExternal } from "../../../api/api";
import { EarthquakeResultInterface } from "../interfaces/earthquake-result.interface";

export const GetEarthquakeExternalData =
  async (): Promise<EarthquakeResultInterface> => {
    try {
      const response = await ApiExternal().get("/public/earthquake");
      return response.data as EarthquakeResultInterface;
    } catch (e) {
      const error = e as AxiosError;
      throw error;
    }
  };

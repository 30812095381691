import { AxiosError } from "axios";
import { ApiAuth } from "../../../api/api";
import { UserOrganizationInterface } from "../interfaces/user-organization.interface";

export const GetUserOrganization =
  async (): Promise<UserOrganizationInterface> => {
    try {
      const response = await ApiAuth().get("/user-organizations-non-paging");
      return response.data as UserOrganizationInterface;
    } catch (e) {
      const error = e as AxiosError;
      throw error;
    }
  };

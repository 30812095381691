import create from "zustand";
import GoogleMapReact from "google-map-react";
import {
  DEFAULT_LATITUDE,
  DEFAULT_LONGITUDE,
  DEFAULT_ZOOM,
  GOOGLE_MAPS_API_KEY,
} from "../../../constants/constants";

interface DisasterMapState {
  apiKey?: string;
  loading: boolean;
  defaultCoords: GoogleMapReact.Coords;
  zoom: number;
  center: GoogleMapReact.Coords;
  handleChange: (change: GoogleMapReact.ChangeEventValue) => void;
  map?: any;
  setMap?: (map: any) => void;
}

export const useDisasterMapStore = create<DisasterMapState>((set, get) => ({
  apiKey: GOOGLE_MAPS_API_KEY,
  loading: false,
  defaultCoords: {
    lat: DEFAULT_LATITUDE,
    lng: DEFAULT_LONGITUDE,
  },
  zoom: DEFAULT_ZOOM,
  center: {
    lat: DEFAULT_LATITUDE,
    lng: DEFAULT_LONGITUDE,
  },
  handleChange: (change: GoogleMapReact.ChangeEventValue) => {
    set({
      center: {
        lat: change.center.lat,
        lng: change.center.lng,
      },
    });
  },
  setMap: (map: any) => {
    set({ map: map });
  },
  map: undefined,
}));

import { AxiosError } from "axios";
import { ApiReport } from "../../../api/api";
import { GeneralInterface } from "../../../helpers/interfaces/general.interface";
import { ReportResultInterface } from "../interfaces/report-result.interface";
import { ReportSingleResultInterface } from "../interfaces/report-single-result.interface";

export interface ReportParam {
  size: number;
  page: number;
  event_id: string;
}

export interface ReportFormProps {
  description: string;
  fatalities: string;
  material_loss: string;
  signal_condition: string;
  response_handling: string;
  issued_at: string;
  event_id: string;
}

export const GetReports = async (
  param: ReportParam
): Promise<ReportResultInterface> => {
  try {
    const result = await ApiReport().get("/reports", {
      params: param,
    });
    return result.data as ReportResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error.response?.data as GeneralInterface;
  }
};

export const GetReport = async (
  id: string
): Promise<ReportSingleResultInterface> => {
  try {
    let response = await ApiReport().get("/reports/" + id);
    return response.data as ReportSingleResultInterface;
  } catch (e) {
    let error = e as AxiosError;
    let response: ReportSingleResultInterface = error.response?.data;
    return response;
  }
};

export const SubmitReport = async (
  form: any
): Promise<ReportSingleResultInterface> => {
  try {
    let response = await ApiReport().post("/reports", form);
    return response.data as ReportSingleResultInterface;
  } catch (e) {
    let error = e as AxiosError;
    let response: ReportSingleResultInterface = error.response?.data;
    return response;
  }
};

export const UpdateReport = async (
  id: string,
  form: any
): Promise<ReportSingleResultInterface> => {
  try {
    let response = await ApiReport().put("/reports/" + id, form);
    return response.data as ReportSingleResultInterface;
  } catch (e) {
    let error = e as AxiosError;
    let response: ReportSingleResultInterface = error.response?.data;
    return response;
  }
};

export const DeleteReport = async (id: string): Promise<GeneralInterface> => {
  try {
    let response = await ApiReport().get("/reports/" + id);
    return response.data as GeneralInterface;
  } catch (e) {
    let error = e as AxiosError;
    let response: GeneralInterface = error.response?.data;
    return response;
  }
};

import { AxiosError } from "axios";
import { ApiReverseGeocode } from "../../../api/api";
import { ReverseGeocodeInterface } from "../interfaces/reverse-geocode.interface";

export interface ReverseGeocodeParam {
  latlng: string;
  key: string;
}

export const getReverseGeocodes = async (
  param: ReverseGeocodeParam
): Promise<ReverseGeocodeInterface> => {
  try {
    const response = await ApiReverseGeocode().get("/geocode/json", {
      params: {
        latlng: param.latlng,
        key: param.key,
      },
    });
    return response.data as ReverseGeocodeInterface;
  } catch (e) {
    const error = e as AxiosError;
    throw error;
  }
};

import { ReportResultInterface } from "../interfaces/report-result.interface";
import create from "zustand";
import {
  GetReports,
  SubmitReport,
  UpdateReport,
  ReportParam,
  ReportFormProps,
} from "../providers/report.provider";
import { ReportData } from "../interfaces/report-single-result.interface";
import { toast } from "react-toastify";

interface ReportState {
  reports: ReportData[];
  report: ReportData;
  reportInterface?: ReportResultInterface;
  reportLoading: boolean;
  getReports: (reportParam: ReportParam) => void;
  selectedReport?: ReportData;
  setSelectedReport?: (data: ReportData) => void;
  description: string;
  setDescription: (desc: string) => void;
}

export const useReportStore = create<ReportState>((set, get) => ({
  reports: [],
  report: undefined,
  reportInterface: undefined,
  reportLoading: false,
  setSelectedReport: (data: ReportData) => {
    set({ selectedReport: data });
  },
  selectedReport: undefined,
  description: "",
  setDescription: (desc: string) => {
    set({ description: desc });
  },
  getReports: async (reportParam: ReportParam) => {
    set({ reportLoading: true });
    const result = await GetReports(reportParam);
    set({ reportInterface: result });
    if (result) {
      if (result.status) {
        if (result.data.items.length > 0) {
          set({ reports: result.data.items, report: result.data.items[0] });
        } else {
          set({ reports: [], report: undefined });
        }
      } else {
        set({ reports: [], report: undefined });
      }
    } else {
      set({ reports: [], report: undefined });
    }
    set({ reportLoading: false });
  },
}));

interface ReportFormState {
  reportFormLoading: boolean;
  submitReport: (reportFormProps: ReportFormProps) => void;
  updateReport: (id: string, reportFormProps: ReportFormProps) => void;
}

export const useReportFormStore = create<ReportFormState>((set, get) => ({
  reportFormLoading: false,
  submitReport: async (reportFormProps: ReportFormProps) => {
    set({ reportFormLoading: true });
    const response = await SubmitReport(reportFormProps);
    if (response.status) {
      toast.success("Report posted");
    } else {
      toast.error(response.status);
    }
    set({ reportFormLoading: false });
  },
  updateReport: async (id: string, reportFormProps: ReportFormProps) => {
    set({ reportFormLoading: true });
    const response = await UpdateReport(id, reportFormProps);
    if (response.status) {
      toast.success("Report updated");
    } else {
      toast.error(response.status);
    }
    set({ reportFormLoading: false });
  },
}));

import { CityData } from "../interfaces/city-result.interface";
import create from 'zustand';
import { GetCity, GetCityParams } from "../providers/cities.provider";

interface CityState {
  cityLoading: boolean;
  getCity: (params: GetCityParams) => void;
  cityItems: CityData[];
}

export const useCityStore = create<CityState>((set, get) => ({
  cityLoading: false,
  getCity: async (params: GetCityParams) => {
    set({ cityLoading: true });
    const response = await GetCity(params);
    if (response.status) {
      if (response.data.length > 0) {
        set({ cityItems: response.data });
      } else {
        set({ cityItems: [] });
      }
    } else {
      set({ cityItems: [] });
    }
    set({ cityLoading: false });
  },
  cityItems: [],
}))
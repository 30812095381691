import { AxiosError } from "axios";
import { ApiChat } from "../../../api/api";
import { MessageResultInterface } from "../interfaces/message-result.interface";
import { MessageSingleResultInterface } from "../interfaces/message-single-result.interface";
import { RoomSingleResultInterface } from "../interfaces/room-single-result.interface";

export interface GetMessageParam {
  room_id: string;
}

export const GetMessageByRoom = async (params: GetMessageParam): Promise<MessageResultInterface> => {
  try {
    const response = await ApiChat().get("/messages", { 
      params: params
     });
    return response.data as MessageResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    throw error;
  }
}

export const SendMessage = async (form: any): Promise<MessageSingleResultInterface> => {
  try {
    const response = await ApiChat().post("/messages", form);
    return response.data as MessageSingleResultInterface;
  } catch (e) {
    const error = e as AxiosError;
    throw error;
  }
}
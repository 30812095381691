import create from "zustand";
import { WeatherArea } from "../interfaces/weather-result.interface";
import { GetWeatherExternalData } from "../providers/weather.provider";

interface WeatherState {
  loading: boolean;
  weathers: WeatherArea[];
  weatherActive: boolean;
  getWeather: () => void;
  closeWeather: () => void;
}

export const useWeatherStore = create<WeatherState>((set, get) => ({
  loading: false,
  weathers: [],
  weatherActive: false,
  closeWeather: () => {
    set({ weathers: [], weatherActive: false });
  },
  getWeather: async () => {
    set({ loading: true, weatherActive: true });
    const response = await GetWeatherExternalData();
    if (response) {
      if (response.status) {
        if (response.data.items.length > 0) {
          let weathers: WeatherArea[] = [];
          const parsedWeathers = response.data.items.map(
            (item, index) => item.weather
          );
          console.log("zxczxc", parsedWeathers);
          parsedWeathers?.forEach((item, index) => {
            item?.area?.forEach((itemm, indexx) => {
              weathers?.push(itemm);
            });
          });
          set({ weathers: weathers });
        }
      } else {
        set({ weathers: [] });
      }
    } else {
      set({ weathers: [] });
    }
    set({ loading: false });
  },
}));

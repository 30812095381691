import { AxiosError } from "axios";
import { ApiDisaster } from "../../../api/api";
import { EventVideoInterface } from "../interfaces/event-video.interface";

export interface EventVideoParam {
  event_id: string;
  page: number;
  size: number;
}

export interface TrackingVideoParam {
  event_id: string;
  page: number;
  size: number;
  user_id?: string;
}

export const getEventVideos = async (
  param: EventVideoParam
): Promise<EventVideoInterface> => {
  try {
    const response = await ApiDisaster().get("/event-videos", {
      params: {
        event_id: param.event_id,
        page: param.page,
        size: param.size,
      },
    });
    return response.data as EventVideoInterface;
  } catch (e) {
    const error = e as AxiosError;
    throw error;
  }
};

export const getTrackingVideos = async (
  param: TrackingVideoParam
): Promise<EventVideoInterface> => {
  try {
    const response = await ApiDisaster().get("/event-videos", {
      params: {
        event_id: param.event_id,
        page: param.page,
        size: param.size,
        user_id: param.user_id,
      },
    });
    return response.data as EventVideoInterface;
  } catch (e) {
    const error = e as AxiosError;
    throw error;
  }
};

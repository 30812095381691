import { AxiosError } from "axios";
import { ApiDisaster } from "../../../api/api";
import { GeneralInfoInterface } from "../interfaces/general-info.interface";

export interface GeneralInfoParam {
  event_id: string;
  code: number;
}

export const getGeneralInfos = async (
  param: GeneralInfoParam
): Promise<GeneralInfoInterface> => {
  try {
    const response = await ApiDisaster().get("/event-general-infos", {
      params: {
        event_id: param.event_id,
        code: param.code,
      },
    });
    return response.data as GeneralInfoInterface;
  } catch (e) {
    const error = e as AxiosError;
    throw error;
  }
};

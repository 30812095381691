import create from "zustand";
import {
  DisasterData,
  DisasterEventNonPagingInterface,
} from "../interfaces/disaster-event.interface";
import {
  DisasterEventFilter,
  GetEvents,
  GetEventsNonPaging,
} from "../providers/disaster-event.provider";

interface EventState {
  eventInterface: DisasterEventNonPagingInterface;
  eventItems: DisasterData[];
  loading: boolean;
  getEvents: (eventFilter: DisasterEventFilter) => void;
  // isOnDetail is use to inform the apps when route is on the detailed marker
  isOnDetail: boolean;
  setIsOnDetail: (isOnDetail: boolean) => void;
  // add eventDetail to store event detail when button detail on marker clicked
  eventDetail?: DisasterData;
  setEventDetail?: (eventDetail: DisasterData) => void;
}

export const useEventStore = create<EventState>((set, get) => ({
  eventInterface: undefined,
  loading: true,
  isOnDetail: false,
  eventItems: [],
  eventFilter: undefined,
  // set eventDetail first condition to undefined
  // cause we dont know detail event at the first apps boot
  eventDetail: undefined,
  setEventDetail: (eventDetail: DisasterData) => {
    // set the event detail when button detail on marker clicked
    set({ eventDetail: eventDetail });
  },
  getEvents: async (eventFilter: DisasterEventFilter) => {
    set({ loading: true });
    const response = await GetEventsNonPaging(eventFilter);
    if (response.status) {
      if (response.data.length > 0) {
        set({ eventItems: response.data });
      } else {
        set({ eventItems: [] });
      }
    } else {
      set({ eventItems: [] });
    }
    set({
      eventInterface: response,
      loading: false,
    });
  },
  setIsOnDetail: (isOnDetail: boolean) => {
    set({ isOnDetail: isOnDetail });
  },
}));

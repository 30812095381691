import moment from "moment";
import styles from "../assets/disaster.module.css";
import { Accordion, Card, useAccordionButton } from "react-bootstrap";
import { useDisasterDetailStore } from "../states/disaster.state";
import { useGeneralInfoStore } from "../states/general-info.state";
import { useEventVideoStore } from "../states/event-video.state";
import { useEventPhotoStore } from "../states/event-photo.state";
import { useEffect } from "react";
import {
  EventBeritaTv,
  EventEventItems,
  EventInformasiUmum,
  EventPhoto,
  EventSiaranLangsung,
  EventVideo,
} from "./disaster-data-modal.component";
import { useSocketStore } from "../../home/states/socket.state";
import { EventPhotoData } from "../interfaces/event-photo.interface";
import { EventVideoData } from "../interfaces/event-video.interface";
import { useEventItemsStore } from "../states/event-items.state";

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey);

  return (
    <button
      type="button"
      className="btn btn-link"
      style={{
        border: "none",
        outline: "none",
        marginBottom: 0,
        color: "#000",
        fontSize: 12,
      }}
      onClick={decoratedOnClick}
    >
      {children}
    </button>
  );
}

const DisasterDataComponent = () => {
  const { disasterData } = useDisasterDetailStore();
  const {
    getGeneralInfo,
    beritaTvItems,
    siaranLangsungItems,
    informasiUmumItems,
  } = useGeneralInfoStore();
  const { getEventVideo, eventVideoData, appendEventVideos } =
    useEventVideoStore();
  const { getEventPhoto, eventPhotoData, appendEventPhotos } =
    useEventPhotoStore();
  const { getEventItems, eventItems } = useEventItemsStore();
  const { socket } = useSocketStore();

  useEffect(() => {
    socket.connect();
    socket.on("receiveAssessmentPhoto", (msg) => {
      const data: EventPhotoData = JSON.parse(msg);
      console.log("receiveAssessmentPhoto", data);
      console.log(
        "receiveAssessmentPhoto",
        localStorage.getItem("eventId"),
        data.event_id
      );
      if (
        data.action === "create" &&
        localStorage.getItem("eventId") === data.event_id
      ) {
        appendEventPhotos(data);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    socket.connect();
    socket.on("receiveAssessmentVideo", (msg) => {
      const data: EventVideoData = JSON.parse(msg);
      console.log("receiveAssessmentVideo", data);
      console.log(
        "receiveAssessmentVideo",
        localStorage.getItem("eventId"),
        data.event_id
      );
      if (
        data.action === "create" &&
        localStorage.getItem("eventId") === data.event_id
      ) {
        appendEventVideos(data);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  // useEffect
  useEffect(() => {
    if (disasterData !== undefined) {
      getGeneralInfo({
        event_id: disasterData.id,
        code: 10,
      });
      getGeneralInfo({
        event_id: disasterData.id,
        code: 11,
      });
      getGeneralInfo({
        event_id: disasterData.id,
        code: 12,
      });
      getEventPhoto({
        event_id: disasterData.id,
        page: 0,
        size: 1000,
      });
      getEventVideo({
        event_id: disasterData.id,
        page: 0,
        size: 1000,
      });
      getEventItems(disasterData.id);
    }
  }, [disasterData]);

  return (
    <Accordion
      defaultActiveKey="0"
      style={{
        width: 300,
        position: "absolute",
        top: 100,
        left: 10,
        zIndex: 100,
        color: "rgb(0, 0, 0)",
      }}
    >
      {/* Card Info */}
      <Card className={`${styles["card"]}`}>
        <Card.Header
          className={`${styles["card-header"]}`}
          style={{
            height: 30,
            padding: 0,
          }}
        >
          <CustomToggle eventKey="0">Info</CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body
            className="card-body"
            style={{
              height: 130,
              backgroundColor: "#fff",
              padding: 10,
              fontSize: 11,
            }}
          >
            <strong>{disasterData?.name}</strong>
            <br />
            <span>KIB: {disasterData?.event_code}</span>
            <br />
            <span>
              Tanggal: {moment(disasterData?.date_time).format("L LT")} WIB
            </span>
            <br />
            <span>Kab/Kota: {disasterData?.city?.name}</span>
            <br />
            <span>Provinsi: {disasterData?.province?.name}</span>
            <br />
            <span>Latitude : {disasterData?.lat}</span>
            <br />
            <span>Longitude : {disasterData?.lng}</span>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/* Card Berita Tv */}
      <Card className={`${styles["card"]}`}>
        <Card.Header
          className={`${styles["card-header"]}`}
          style={{
            height: 30,
            padding: 0,
          }}
        >
          <CustomToggle eventKey="1">
            Berita TV (<span>{beritaTvItems.length}</span>)
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="1">
          <Card.Body
            className="card-body"
            style={{
              height: 180,
              backgroundColor: "#fff",
              padding: 10,
              fontSize: 11,
            }}
          >
            <div style={{ height: 160, overflowY: "scroll" }}>
              <table
                style={{ marginTop: 10, width: 250, border: "1px solid #000" }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        fontSize: 10,
                        fontWeight: "normal",
                        borderRight: "1px solid #000",
                        borderBottom: "1px solid #000",
                        textAlign: "center",
                      }}
                    >
                      No
                    </th>
                    <th
                      style={{
                        fontSize: 10,
                        fontWeight: "normal",
                        borderRight: "1px solid #000",
                        borderBottom: "1px solid #000",
                        textAlign: "center",
                      }}
                    >
                      Tanggal
                    </th>
                    <th
                      style={{
                        fontSize: 10,
                        fontWeight: "normal",
                        borderRight: "1px solid #000",
                        borderBottom: "1px solid #000",
                        textAlign: "center",
                      }}
                    >
                      Judul
                    </th>
                    <th
                      style={{
                        fontSize: 10,
                        fontWeight: "normal",
                        borderBottom: "1px solid #000",
                        textAlign: "center",
                      }}
                    >
                      Sumber
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {beritaTvItems?.map((item, index) => {
                    return (
                      <EventBeritaTv
                        key={index}
                        Tnum={index + 1}
                        createdAt={item?.createdAt}
                        title={item?.title}
                        source={item?.info_source.name}
                        content={(item?.content).replace(
                          "https://youtu.be/",
                          "https://www.youtube.com/embed/"
                        )}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/* Card Siaran Langsung */}
      <Card className={`${styles["card"]}`}>
        <Card.Header
          className={`${styles["card-header"]}`}
          style={{
            height: 30,
            padding: 0,
          }}
        >
          <CustomToggle eventKey="2">
            Siaran Langsung (<span>{siaranLangsungItems.length}</span>)
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="2">
          <Card.Body
            className="card-body"
            style={{
              height: 180,
              backgroundColor: "#fff",
              padding: 10,
              fontSize: 11,
            }}
          >
            <div style={{ height: 160, overflowY: "scroll" }}>
              <table
                style={{ marginTop: 10, width: 250, border: "1px solid #000" }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        fontSize: 10,
                        fontWeight: "normal",
                        borderRight: "1px solid #000",
                        borderBottom: "1px solid #000",
                        textAlign: "center",
                      }}
                    >
                      No
                    </th>
                    <th
                      style={{
                        fontSize: 10,
                        fontWeight: "normal",
                        borderRight: "1px solid #000",
                        borderBottom: "1px solid #000",
                        textAlign: "center",
                      }}
                    >
                      Tanggal
                    </th>
                    <th
                      style={{
                        fontSize: 10,
                        fontWeight: "normal",
                        borderRight: "1px solid #000",
                        borderBottom: "1px solid #000",
                        textAlign: "center",
                      }}
                    >
                      Judul
                    </th>
                    <th
                      style={{
                        fontSize: 10,
                        fontWeight: "normal",
                        borderBottom: "1px solid #000",
                        textAlign: "center",
                      }}
                    >
                      Sumber
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {siaranLangsungItems?.map((item, index) => {
                    return (
                      <EventSiaranLangsung
                        key={index}
                        Tnum={index + 1}
                        createdAt={item?.createdAt}
                        title={item?.title}
                        source={item?.info_source.name}
                        content={(item?.content).replace(
                          "https://youtu.be/",
                          "https://www.youtube.com/embed/"
                        )}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/* Card Informasi Umum */}
      <Card className={`${styles["card"]}`}>
        <Card.Header
          className={`${styles["card-header"]}`}
          style={{
            height: 30,
            padding: 0,
          }}
        >
          <CustomToggle eventKey="3">
            Informasi Umum (<span>{informasiUmumItems.length}</span>)
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="3">
          <Card.Body
            className="card-body"
            style={{
              height: 180,
              backgroundColor: "#fff",
              padding: 10,
              fontSize: 11,
            }}
          >
            <div style={{ height: 160, overflowY: "scroll" }}>
              <table
                style={{ marginTop: 10, width: 250, border: "1px solid #000" }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        fontSize: 10,
                        fontWeight: "normal",
                        borderRight: "1px solid #000",
                        borderBottom: "1px solid #000",
                        textAlign: "center",
                      }}
                    >
                      No
                    </th>
                    <th
                      style={{
                        fontSize: 10,
                        fontWeight: "normal",
                        borderRight: "1px solid #000",
                        borderBottom: "1px solid #000",
                        textAlign: "center",
                      }}
                    >
                      Tanggal
                    </th>
                    <th
                      style={{
                        fontSize: 10,
                        fontWeight: "normal",
                        borderRight: "1px solid #000",
                        borderBottom: "1px solid #000",
                        textAlign: "center",
                      }}
                    >
                      Judul
                    </th>
                    <th
                      style={{
                        fontSize: 10,
                        fontWeight: "normal",
                        borderBottom: "1px solid #000",
                        textAlign: "center",
                      }}
                    >
                      Sumber
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {informasiUmumItems?.map((item, index) => {
                    return (
                      <EventInformasiUmum
                        key={index}
                        Tnum={index + 1}
                        createdAt={item?.createdAt}
                        title={item?.title}
                        source={item?.info_source.name}
                        content={(item?.content).replace(
                          "https://youtu.be/",
                          "https://www.youtube.com/embed/"
                        )}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/* Card Kebutuhan */}
      <Card className={`${styles["card"]}`}>
        <Card.Header
          className={`${styles["card-header"]}`}
          style={{
            height: 30,
            padding: 0,
          }}
        >
          <CustomToggle eventKey="8">
            Kebutuhan (<span>{eventItems.length}</span>)
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="8">
          <Card.Body
            className="card-body"
            style={{
              height: 180,
              backgroundColor: "#fff",
              padding: 10,
              fontSize: 11,
            }}
          >
            <div style={{ height: 160, overflowY: "scroll" }}>
              <table
                style={{ marginTop: 10, width: 250, border: "1px solid #000" }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        fontSize: 10,
                        fontWeight: "normal",
                        borderRight: "1px solid #000",
                        borderBottom: "1px solid #000",
                        textAlign: "center",
                      }}
                    >
                      No
                    </th>
                    <th
                      style={{
                        fontSize: 10,
                        fontWeight: "normal",
                        borderRight: "1px solid #000",
                        borderBottom: "1px solid #000",
                        textAlign: "center",
                      }}
                    >
                      Jenis Barang
                    </th>
                    <th
                      style={{
                        fontSize: 10,
                        fontWeight: "normal",
                        borderBottom: "1px solid #000",
                        textAlign: "center",
                      }}
                    >
                      Jumlah
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {eventItems?.map((item, index) => {
                    return (
                      <EventEventItems
                        key={index}
                        Tnum={index + 1}
                        itemType={item?.item_name}
                        itemAmount={item?.total_value}
                      />
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/* Card Foto */}
      <Card className={`${styles["card"]}`}>
        <Card.Header
          className={`${styles["card-header"]}`}
          style={{
            height: 30,
            padding: 0,
          }}
        >
          <CustomToggle eventKey="4">
            Foto (<span>{eventPhotoData.length}</span>)
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="4">
          <Card.Body
            style={{
              height: 180,
              backgroundColor: "#fff",
              padding: 10,
              fontSize: 11,
            }}
          >
            <div style={{ height: 160, overflowY: "scroll" }}>
              {disasterData !== undefined ? (
                eventPhotoData?.map((item, index) => {
                  return (
                    <EventPhoto
                      key={index}
                      lat={+item?.lat}
                      lng={+item?.lng}
                      name={item?.content}
                      media={item?.media_id}
                      title={item?.title}
                      dateTime={item?.createdAt}
                      userId={item?.user_id}
                      id={item?.id}
                      eventItems={item?.event_items}
                      district={item?.district}
                      subdistrict={item?.subdistrict}
                      village={item?.village}
                    />
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/* Card Video */}
      <Card className={`${styles["card"]}`}>
        <Card.Header
          className={`${styles["card-header"]}`}
          style={{
            height: 30,
            padding: 0,
          }}
        >
          <CustomToggle eventKey="5">
            Video (<span>{eventVideoData.length}</span>)
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="5">
          <Card.Body
            className="card-body"
            style={{
              height: 180,
              backgroundColor: "#fff",
              padding: 10,
              fontSize: 11,
            }}
          >
            <div style={{ height: 160, overflowY: "scroll" }}>
              {disasterData !== undefined ? (
                eventVideoData?.map((item, index) => {
                  return (
                    <EventVideo
                      key={index}
                      thumbnail={item?.thumbnail}
                      lat={+item?.lat}
                      lng={+item?.lng}
                      name={item?.content}
                      media={item?.media_id}
                      title={item?.title}
                      dateTime={item?.createdAt}
                      userId={item?.user_id}
                      id={item?.id}
                      eventItems={item?.event_items}
                      district={item?.district}
                      subdistrict={item?.subdistrict}
                      village={item?.village}
                    />
                  );
                })
              ) : (
                <></>
              )}
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/* Card Gambar Peta */}
      <Card className={`${styles["card"]}`}>
        <Card.Header
          className={`${styles["card-header"]}`}
          style={{
            height: 30,
            padding: 0,
          }}
        >
          <CustomToggle eventKey="6">
            Gambar Peta (<span>0</span>)
          </CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="6">
          <Card.Body
            className="card-body"
            style={{
              height: 180,
              backgroundColor: "#fff",
              padding: 10,
              fontSize: 11,
            }}
          >
            <div style={{ height: 160, overflowY: "scroll" }}></div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
      {/* Card Laporan */}
      <Card className={`${styles["card"]}`}>
        <Card.Header
          className={`${styles["card-header"]}`}
          style={{
            height: 30,
            padding: 0,
          }}
        >
          <CustomToggle eventKey="7">Laporan</CustomToggle>
        </Card.Header>
        <Accordion.Collapse eventKey="7">
          <Card.Body
            className="card-body"
            style={{
              height: 50,
              backgroundColor: "#fff",
              padding: 10,
              fontSize: 11,
            }}
          >
            <a href={`/report/${disasterData?.id}`}>Laporan kejadian bencana</a>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default DisasterDataComponent;

import { toast } from "react-toastify";
import create from "zustand";
import {
  ResourceData,
  ResourceInterface,
} from "../interfaces/resource.interface";
import {
  getResources,
  GetResourceParams,
  DeleteResource,
  GetResourceNearbyParams,
  getResourcesNearby,
} from "../providers/resource.provider";
import { ResourceNearbyData } from "../interfaces/resource-nearby.interface";

interface ResourceState {
  resourceLoading: boolean;
  resourceLoadingExport: boolean;
  resourceData: ResourceInterface;
  resourceDataExport: ResourceInterface;
  resourceItems: ResourceData[];
  resourceNearbyTniItems: ResourceNearbyData[];
  resourceNearbyPolriItems: ResourceNearbyData[];
  resourceNearbyPlnItems: ResourceNearbyData[];
  resourceItemsExport: ResourceData[];
  resourceId: string;
  setResourceId: (resourceId: string) => void;
  getResource: (params?: GetResourceParams) => void;
  getResourceNearbyTni: (params?: GetResourceNearbyParams) => void;
  getResourceNearbyPolri: (params?: GetResourceNearbyParams) => void;
  getResourceNearbyPln: (params?: GetResourceNearbyParams) => void;
  getResourceExport: (params?: GetResourceParams) => void;
  deleteResource: (id: string) => void;
}

export const useResourceStore = create<ResourceState>((set, get) => ({
  resourceLoading: false,
  resourceLoadingExport: false,
  resourceData: undefined,
  resourceDataExport: undefined,
  resourceItems: [],
  resourceNearbyTniItems: [],
  resourceNearbyPolriItems: [],
  resourceNearbyPlnItems: [],
  resourceItemsExport: [],
  resourceId: undefined,
  setResourceId: (resourceId: string) => {
    set({ resourceId: resourceId });
  },
  getResource: async (params?: GetResourceParams) => {
    set({ resourceItems: [], resourceLoading: true });
    const result = await getResources(params);
    set({
      resourceData: result,
      resourceItems: result.data.items,
      resourceLoading: false,
    });
  },
  getResourceNearbyTni: async (params?: GetResourceNearbyParams) => {
    set({ resourceNearbyTniItems: [] });
    const result = await getResourcesNearby(params);
    set({ resourceNearbyTniItems: result.data });
  },
  getResourceNearbyPolri: async (params?: GetResourceNearbyParams) => {
    set({ resourceNearbyPolriItems: [] });
    const result = await getResourcesNearby(params);
    set({ resourceNearbyPolriItems: result.data });
  },
  getResourceNearbyPln: async (params?: GetResourceNearbyParams) => {
    set({ resourceNearbyPlnItems: [] });
    const result = await getResourcesNearby(params);
    set({ resourceNearbyPlnItems: result.data });
  },
  getResourceExport: async (params?: GetResourceParams) => {
    set({ resourceItemsExport: [], resourceLoadingExport: true });
    const result = await getResources(params);
    set({
      resourceDataExport: result,
      resourceItemsExport: result.data.items,
      resourceLoadingExport: false,
    });
  },
  deleteResource: async (id: string) => {
    set({ resourceLoading: true });
    const result = await DeleteResource(id);
    if (result.status) {
      toast.success(result.message);
      get().getResource({ size: 15, page: 0 });
    } else {
      toast.error(result.message);
    }
    set({ resourceLoading: false });
  },
}));

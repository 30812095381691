import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ResourceMapComponent from "../../maps/components/resource-maps.component";
import HeaderComponent from "../components/header.component";
import ResourceNavigationComponent from "../components/resource-navigation.component";
import { useResourceStore } from "../states/resource.state";

const ResourceScreen = () => {
  const { resourceId } = useParams();
  const { setResourceId } = useResourceStore();

  useEffect(() => {
    if (resourceId !== undefined) {
      setResourceId(resourceId);
    }
  }, [resourceId]);

  return (
    <>
      <HeaderComponent />
      <ResourceMapComponent />
      <ResourceNavigationComponent />
    </>
  );
};

export default ResourceScreen;

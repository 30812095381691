import { Link } from "react-router-dom";
import navHome from "../../../img/nav/home.png";
import { useResourceStore } from "../states/resource.state";

const ResourceNavigationComponent = () => {
  const { setResourceId } = useResourceStore();

  return (
    <div>
      <nav
        id=""
        className="circle-nav-wrapper"
        style={{
          marginTop: 100,
          float: "right",
          marginRight: 10,
        }}
      >
        <Link
          type="a"
          to={"/"}
          onClick={(e) => {
            setResourceId(undefined);
          }}
        >
          <img src={navHome} alt="" title="Beranda" style={{ height: 50 }} />
        </Link>
      </nav>
    </div>
  );
};

export default ResourceNavigationComponent;

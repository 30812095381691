import { DisasterData } from "../../home/interfaces/disaster-event.interface";
import create from "zustand";
import { GetEvent } from "../../home/providers/disaster-event.provider";

interface DisasterDetailState {
  disasterLoading: boolean;
  disasterData?: DisasterData;
  getDisasterDetail: (eventId: string) => void;
}

export const useDisasterDetailStore = create<DisasterDetailState>(
  (set, get) => ({
    disasterLoading: false,
    disasterData: undefined,
    getDisasterDetail: async (eventId: string) => {
      set({ disasterLoading: true });
      const response = await GetEvent(eventId);
      if (response.status) {
        set({ disasterData: response.data });
      } else {
        set({ disasterData: undefined });
      }
      set({ disasterLoading: false });
    },
  })
);

import { useAuthStore } from "../../auth/states/auth.state";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import styles from "../assets/home.module.css";
import navHome from "../../../img/nav/home.png";
import navData from "../../../img/nav/data.png";
import navNetwork from "../../../img/nav/network.png";
import navExternalData from "../../../img/nav/external-data.png";
import navLogout from "../../../img/nav/logout.png";
import {
  ModalData,
  ModalDataEksternal,
  ModalJaringan,
} from "./modal.component";

const NavigationComponent = () => {
  const { logout } = useAuthStore();
  const [showData, setShowData] = useState(false);
  const [showJaringan, setShowJaringan] = useState(false);
  const [showDataEksternal, setShowDataEksternal] = useState(false);

  const handleClose = () => {
    setShowData(false);
    setShowJaringan(false);
    setShowDataEksternal(false);
  };
  const handleShow = (type: string) => {
    if (type === "data") {
      setShowData(true);
    }
    if (type === "jaringan") {
      setShowJaringan(true);
    }
    if (type === "data_eksternal") {
      setShowDataEksternal(true);
    }
  };
  return (
    <div>
      <nav
        id=""
        className="circle-nav-wrapper"
        style={{
          marginTop: 100,
          width: 250,
          float: "right",
        }}
      >
        <a href="">
          <img
            src={navHome}
            alt=""
            title="Beranda"
            style={{ height: 50, marginRight: 3 }}
          />
        </a>
        <a
          href=""
          onClick={(e) => {
            e.preventDefault();
            handleShow("data");
          }}
        >
          <img
            src={navData}
            alt=""
            title="Data"
            style={{ height: 50, marginRight: 3 }}
          />
        </a>
        <a
          href=""
          onClick={(e) => {
            e.preventDefault();
            handleShow("jaringan");
          }}
        >
          <img
            src={navNetwork}
            alt=""
            title="Jaringan"
            style={{ height: 50, marginRight: 3 }}
          />
        </a>
        <a
          href=""
          onClick={(e) => {
            e.preventDefault();
            handleShow("data_eksternal");
          }}
        >
          <img
            src={navExternalData}
            alt=""
            title="Data Eksternal"
            style={{ height: 50, marginRight: 3 }}
          />
        </a>
        <a href="">
          <img
            src={navLogout}
            alt=""
            title="Keluar"
            style={{ height: 50, marginRight: 3 }}
            onClick={(e) => {
              logout();
            }}
          />
        </a>
      </nav>
      <ModalData show={showData} onHide={handleClose} onClick={handleClose} />
      <ModalJaringan
        show={showJaringan}
        onHide={handleClose}
        onClick={handleClose}
      />
      <ModalDataEksternal
        show={showDataEksternal}
        onHide={handleClose}
        onClick={handleClose}
      />
    </div>
  );
};

export default NavigationComponent;

import { toast } from 'react-toastify';
import create from 'zustand';
import { MessageData } from '../interfaces/message-result.interface';
import { GetMessageByRoom, SendMessage } from '../providers/message.provider';

interface MessageState {
  messageLoading: boolean;
  messageItems: MessageData[];
  getMessages: (roomId: string) => void;
  messageReset: () => void;
  sendMessage: (senderId: string, receiverId: string, content: string, roomId: string) => void;
}

export const useMessageStore = create<MessageState>((set, get) => ({
  messageLoading: false,
  messageItems: [],
  getMessages: async (roomId: string) => {
    set({ messageLoading: true });
    const response = await GetMessageByRoom({ room_id: roomId });
    if (response.status) {
      if (response.data.length > 0) {
        set({ messageItems: response.data });
      } else {
        set({ messageItems: [] });
      }
    } else {
      set({ messageItems: [] });
    }
    set({ messageLoading: false });
  },
  messageReset: () => {
    set({
      messageItems: [],
      messageLoading: false,
    });
  },
  sendMessage: async (senderId: string, receiverId: string, content: string, roomId: string) => {
    const form = {
      sender_id: senderId,
      receiver_id: receiverId,
      content: content,
      room_id: roomId
    };
    const response = await SendMessage(form);
    if (response.status) {
      toast.success(response.message);
    } else {
      toast.error(response.message);
    }
  }
}));
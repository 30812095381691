import GoogleMapReact from "google-map-react";
import { useEffect, useRef, useState } from "react";
import { useDisasterDetailStore } from "../../disaster/states/disaster.state";
import { useEventPhotoStore } from "../../disaster/states/event-photo.state";
import { useEventVideoStore } from "../../disaster/states/event-video.state";
import { useTrackingStore } from "../../trackings/states/tracking.state";
import { useTrackingMapStore } from "../states/tracking-maps.state";
import Marker, { MarkerHospital, MarkerUser } from "./marker.component";
import ModalPhotoVideoComponent from "./modal-photo-video.component";
import MarkerClusterer from "@google/markerclusterer";
import { useEventStore } from "../../home/states/disaster-event.state";
import { useModalPhotoVideoStore } from "../../disaster/states/modal-photo-video.state";
import { useResourceStore } from "../../home/states/resource.state";
import { MarkerResource } from "./resource-maps.component";
import { useEventPlacesNearbyStore } from "../../disaster/states/event-places-nearby.state";

const photoIcon = require("../../../img/marker-detail/photo.png");
const videoIcon = require("../../../img/marker-detail/video.png");

const TrackingMapComponent = () => {
  const { apiKey, defaultCoords, handleChange, setMap } = useTrackingMapStore();
  const {
    getResourceNearbyTni,
    getResourceNearbyPolri,
    getResourceNearbyPln,
    resourceNearbyTniItems,
    resourceNearbyPolriItems,
    resourceNearbyPlnItems,
  } = useResourceStore();
  const googleMapsRef = useRef<GoogleMapReact>(null);
  const mapRef = useRef();
  const { disasterData } = useDisasterDetailStore();
  const { getEventPhoto, eventPhotoData, trackingPhotoData } =
    useEventPhotoStore();
  const { getEventVideo, eventVideoData, trackingVideoData } =
    useEventVideoStore();
  const { isOnDetail } = useEventStore();
  const { trackingUsers, trackingSingleUser } = useTrackingStore();
  const [theMap, setTheMap] = useState<any>(undefined);
  const [theMaps, setTheMaps] = useState<any>(undefined);
  const { handleOpen } = useModalPhotoVideoStore();
  const { getEventPlacesNearby, eventPlacesNearbyItems } =
    useEventPlacesNearbyStore();

  useEffect(() => {
    if (disasterData !== undefined) {
      getEventPlacesNearby({
        lat: disasterData?.lat,
        lng: disasterData?.lng,
        type: "hospital",
      });
    }
  }, [disasterData]);

  useEffect(() => {
    if (disasterData !== undefined) {
      getResourceNearbyTni({
        lat: disasterData.lat,
        lng: disasterData.lng,
        radius: 20,
        resource_type_group_id: "2ae0ae08-9712-11ed-a8fc-0242ac120002",
      });
      getResourceNearbyPolri({
        lat: disasterData.lat,
        lng: disasterData.lng,
        radius: 20,
        resource_type_group_id: "2ae0ab4c-9712-11ed-a8fc-0242ac120002",
      });
      getResourceNearbyPln({
        lat: disasterData.lat,
        lng: disasterData.lng,
        radius: 20,
        resource_type_group_id: "2ae0b632-9712-11ed-a8fc-0242ac120002",
      });
    }
  }, [disasterData]);

  useEffect(() => {
    if (theMap !== undefined && theMaps !== undefined) {
      if (trackingPhotoData?.length > 0 || trackingVideoData?.length > 0) {
        handleTrackingMarkers(theMap, theMaps);
      }
    }
  }, [theMap, theMaps, trackingPhotoData, trackingVideoData]);

  const handleTrackingMarkers = (map: any, maps: any) => {
    const OverlappingMarkerSpiderfier = require("overlapping-marker-spiderfier");
    var oms = new OverlappingMarkerSpiderfier(map, {
      markersWontMove: true,
      markersWontHide: true,
      basicFormatEvents: true,
      nearbyDistance: 40,
      circleSpiralSwitchover: 8,
      spiralFootSeparation: 20,
      spiralLengthStart: 16,
      spiralLengthFactor: 12,
      circleFootSeparation: 50,
      circleStartAngle: 180,
      keepSpiderfied: true,
    });

    let allMarkers = [];

    const openPhotoVideoMarkerModal = (marker) => {
      if (theMap !== undefined && theMaps !== undefined) {
        if (marker.markerType === "Foto") {
          oms.addListener("click", function (marker) {
            handleOpen(
              marker?.markerType,
              marker?.lat,
              marker?.lng,
              marker?.name,
              marker?.media,
              marker?.title,
              marker?.dateTime,
              marker?.userId,
              marker?.id,
              marker?.eventItems,
              marker?.district,
              marker?.subdistrict,
              marker?.village
            );
          });
        } else if (marker.markerType === "Video") {
          oms.addListener("click", function (marker) {
            handleOpen(
              marker?.markerType,
              marker?.lat,
              marker?.lng,
              marker?.name,
              marker?.media,
              marker?.title,
              marker?.dateTime,
              marker?.userId,
              marker?.id,
              marker?.eventItems,
              marker?.district,
              marker?.subdistrict,
              marker?.village
            );
          });
        }
      }
    };

    if (trackingPhotoData?.length > 0) {
      trackingPhotoData.map((item) => {
        const data = {
          lat: parseFloat(item?.lat),
          lng: parseFloat(item?.lng),
          url: photoIcon,
          name: item?.content,
          media: item?.media_id,
          title: item?.title,
          dateTime: item?.createdAt,
          userId: item?.user_id,
          id: item?.id,
          eventItems: item?.event_items,
          district: item?.district,
          subdistrict: item?.subdistrict,
          village: item?.village,
          markerType: "Foto",
        };
        allMarkers.push(data);
      });
    }

    if (trackingVideoData?.length > 0) {
      trackingVideoData.map((item) => {
        const data = {
          lat: parseFloat(item?.lat),
          lng: parseFloat(item?.lng),
          url: videoIcon,
          name: item?.content,
          media: item?.media_id,
          title: item?.title,
          dateTime: item?.createdAt,
          userId: item?.user_id,
          id: item?.id,
          eventItems: item?.event_items,
          district: item?.district,
          subdistrict: item?.subdistrict,
          village: item?.village,
          markerType: "Video",
        };
        allMarkers.push(data);
      });
    }

    const markerCluster = new MarkerClusterer(map, [], {
      imagePath:
        "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
      maxZoom: 15,
      minimumClusterSize: 2,
    });

    allMarkers?.map((item) => {
      const markerPhotoVideo = new maps.Marker({
        position: { lat: item?.lat, lng: item?.lng },
        icon: item?.url,
        markerType: item?.markerType,
        lat: item?.lat,
        lng: item?.lng,
        name: item?.name,
        media: item?.media,
        title: item?.title,
        dateTime: item?.dateTime,
        userId: item?.userId,
        id: item?.id,
        eventItems: item?.eventItems,
        district: item?.district,
        subdistrict: item?.subdistrict,
        village: item?.village,
      });
      openPhotoVideoMarkerModal(markerPhotoVideo);
      if (item?.lat !== 0 && item?.lng !== 0) {
        oms.addMarker(markerPhotoVideo);
        markerCluster.addMarker(markerPhotoVideo);
      }
    });
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        zIndex: -999,
      }}
    >
      {apiKey !== undefined && disasterData !== undefined && isOnDetail ? (
        <GoogleMapReact
          ref={googleMapsRef}
          bootstrapURLKeys={{
            key: apiKey,
            libraries: "drawing",
          }}
          defaultCenter={{
            lat: defaultCoords.lat,
            lng: defaultCoords.lng,
          }}
          center={{
            lat: +disasterData?.lat,
            lng: +disasterData?.lng,
          }}
          zoom={15}
          options={(maps) => ({
            mapTypeId: "hybrid",
            fullscreenControl: false,
            zoomControl: true,
            zoomControlOptions: {
              position: maps.ControlPosition.RIGHT_BOTTOM,
            },
            mapTypeControl: true,
            mapTypeControlOptions: {
              style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
              position: maps.ControlPosition.RIGHT_BOTTOM,
            },
          })}
          yesIWantToUseGoogleMapApiInternals
          onChange={handleChange}
          onGoogleApiLoaded={({ map, maps }) => {
            setMap(map);
            setTheMap(map);
            setTheMaps(maps);
          }}
        >
          {/* Marker */}
          <Marker
            lat={parseFloat(disasterData?.lat)}
            lng={parseFloat(disasterData?.lng)}
            icon={disasterData?.event_type?.icon}
            icon2={disasterData?.event_type?.icon.replace(".png", "2.png")}
            isTerra={disasterData?.is_terra}
            event={disasterData?.name}
            desc={disasterData?.description}
            prov={disasterData?.province?.name}
            city={disasterData?.city?.name}
            dateTime={disasterData?.date_time}
            eventCode={disasterData?.event_code}
            eventDetail={disasterData}
            csrAssistance={disasterData?.total_csr_assistance}
            totalPhotos={disasterData?.total_photos}
          />

          {/* Marker Hospital */}
          {eventPlacesNearbyItems?.map((item, index) => {
            return (
              <MarkerHospital
                key={index}
                lat={item?.geometry?.location?.lat}
                lng={item?.geometry?.location?.lng}
                name={item?.name}
                location={item?.vicinity}
              />
            );
          })}

          {resourceNearbyPolriItems?.map((item, index) => {
            if (
              item?.lat === "" ||
              item?.lng === "" ||
              item?.lat === "0" ||
              item?.lng === "0"
            ) {
              return <></>;
            }
            return (
              <MarkerResource
                key={index}
                resourceName={item?.group_name}
                name={item?.resource_name}
                location={item?.resource_location}
                lat={+item?.lat ?? 0}
                lng={+item?.lng ?? 0}
                icon={
                  <img
                    src={require(`../../../img/icons_new/${item?.icon}`)}
                  ></img>
                }
              />
            );
          })}

          {resourceNearbyTniItems?.map((item, index) => {
            if (
              item?.lat === "" ||
              item?.lng === "" ||
              item?.lat === "0" ||
              item?.lng === "0"
            ) {
              return <></>;
            }
            return (
              <MarkerResource
                key={index}
                resourceName={item?.group_name}
                name={item?.resource_name}
                location={item?.resource_location}
                lat={+item?.lat ?? 0}
                lng={+item?.lng ?? 0}
                icon={
                  <img
                    src={require(`../../../img/icons_new/${item?.icon}`)}
                  ></img>
                }
              />
            );
          })}

          {resourceNearbyPlnItems?.map((item, index) => {
            if (
              item?.lat === "" ||
              item?.lng === "" ||
              item?.lat === "0" ||
              item?.lng === "0"
            ) {
              return <></>;
            }
            return (
              <MarkerResource
                key={index}
                resourceName={item?.group_name}
                name={item?.resource_name}
                location={item?.resource_location}
                lat={+item?.lat ?? 0}
                lng={+item?.lng ?? 0}
                icon={
                  <img
                    src={require(`../../../img/icons_new/${item?.icon}`)}
                  ></img>
                }
              />
            );
          })}

          {trackingUsers?.map((item, index) => {
            return (
              <MarkerUser
                key={index}
                fullName={item.user?.fullName ?? "Fulan"}
                lat={item.lat}
                lng={item.lng}
                group={item.user?.user_detail?.user_organization_id?.name ?? ""}
                phone={item.user?.phone_number ?? ""}
                userId={item.user?.id}
              />
            );
          })}
        </GoogleMapReact>
      ) : (
        <></>
      )}
      <ModalPhotoVideoComponent />
    </div>
  );
};

export default TrackingMapComponent;

import create from "zustand";
import { VolcanoData } from "../interfaces/volcano-result.interface";
import { GetVolcanoExternalData } from "../providers/volcano.provider";

interface VolcanoState {
  loading: boolean;
  volcanos: VolcanoData[];
  volcanoActive: boolean;
  getVolcano: () => void;
  closeVolcano: () => void;
}

export const useVolcanoStore = create<VolcanoState>((set, get) => ({
  loading: false,
  volcanos: [],
  volcanoActive: false,
  closeVolcano: () => {
    set({ volcanos: [], volcanoActive: false });
  },
  getVolcano: async () => {
    set({ loading: true, volcanoActive: true });
    const response = await GetVolcanoExternalData();
    if (response) {
      if (response.status) {
        if (response.data.items.length > 0) {
          let volcanos: VolcanoData[] = [];
          response.data.items.map((item, index) =>
            item.volcano.forEach((itemm, indexx) => {
              volcanos.push(itemm);
            })
          );
          set({ volcanos: volcanos });
        } else {
          set({ volcanos: [] });
        }
      } else {
        set({ volcanos: [] });
      }
    } else {
      set({ volcanos: [] });
    }
    set({ loading: false });
  },
}));

import create from "zustand";
import { User } from "../../assignments/interfaces/assignment-result.interface";
import { TrackingData } from "../interfaces/tracking-result.interface";
import { GetTracking, GetTrackingParams } from "../providers/tracking.provider";

export interface TrackingState {
  trackingLoading: boolean;
  trackingSingleLoading: boolean;
  trackingItems: TrackingData[];
  trackingPolylines: PolyLine[];
  trackingIds: string[];
  trackingUserIds: string[];
  trackingUsers: TrackingUserData[];
  trackingSingleUser: TrackingUserData;
  updateTrackingUser?: (
    userId: string,
    lat: string,
    lng: string,
    trailColor: string
  ) => void;
  getTrackings: (map: any, eventId: string, users: User[]) => void;
  getTracking: (map: any, eventId: string, user: User) => void;
  setTrackingMaps?: () => void;
  resetTracking?: () => void;
  trackingsMaps?: google.maps.Polyline[];
  livePolylines?: LivePolylines[];
}

interface LivePolylines {
  userId: string;
  trailColor: string;
  latLngs: PolyLine[];
}

interface PolyLine {
  lat: number;
  lng: number;
}

interface TrackingUserData {
  user: User;
  lat: number;
  lng: number;
}

export const useTrackingStore = create<TrackingState>((set, get) => ({
  trackingLoading: false,
  trackingSingleLoading: false,
  trackingItems: [],
  trackingPolylines: [],
  trackingIds: [],
  trackingUserIds: [],
  trackingUsers: [],
  trackingSingleUser: undefined,
  livePolylines: [],
  updateTrackingUser: (
    userId: string,
    lat: string,
    lng: string,
    trailColor: string
  ) => {
    if (get().trackingUsers.length > 0) {
      let users = [...get().trackingUsers];
      users = users.filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.user?.id === value.user?.id)
      );
      // const userFilters = users.filter((el) => el.user?.id === userId);
      const userIndex = users.findIndex((el) => el.user?.id === userId);
      if (userIndex > -1) {
        users[userIndex] = {
          ...users[userIndex],
          lat: +lat,
          lng: +lng,
        };

        // add to livePolyline
        // check is user exist
        const test = get().livePolylines.findIndex(
          (el) => el.userId === userId
        );
        if (test === -1) {
          const test2 = [...get().livePolylines];
          test2.push({
            userId: userId,
            trailColor: trailColor ?? `#da251c`,
            latLngs: [
              {
                lat: +lat,
                lng: +lng,
              },
            ],
          });
          set({ livePolylines: test2 });
        } else {
          const test2 = [...get().livePolylines];
          test2[test] = {
            ...test2[test],
            latLngs: [
              ...test2[test].latLngs,
              {
                lat: +lat,
                lng: +lng,
              },
            ],
          };
          set({ livePolylines: test2 });
        }
      }
      set({ trackingUsers: users });
    }
  },

  getTrackings: async (map: any, eventId: string, users: User[]) => {
    get().setTrackingMaps();
    set({
      trackingLoading: true,
      trackingUsers: [],
      trackingsMaps: [],
      trackingSingleUser: undefined,
    });
    if (users.length > 0) {
      for (const [index, item] of users.entries()) {
        const randomColor = Math.floor(Math.random() * 16777215).toString(16);

        const response = await GetTracking({
          event_id: eventId,
          user_id: item.id,
        });

        if (response.data.tracks.length > 0) {
          // add user latest position
          set({
            trackingUsers: [
              ...get().trackingUsers,
              {
                user: item,
                lat: response.data?.tracking_latest_user?.lat,
                lng: response.data?.tracking_latest_user?.lng,
              },
            ],
          });

          for (const itemm of response.data.tracks) {
            const paths = new google.maps.Polyline({
              path: itemm.trackings as PolyLine[],
              strokeColor: item.trail_color ?? `#da251c`,
              strokeOpacity: 1.0,
              strokeWeight: 5,
            });
            paths.setMap(map);
            set({ trackingsMaps: [...get().trackingsMaps, paths] });
          }
        }
      }
    }
    set({ trackingLoading: false });
  },

  getTracking: async (map: any, eventId: string, user: User) => {
    get().setTrackingMaps();
    set({
      trackingSingleLoading: true,
      trackingSingleUser: undefined,
      trackingsMaps: [],
    });

    const randomColor = Math.floor(Math.random() * 16777215).toString(16);

    const response = await GetTracking({
      event_id: eventId,
      user_id: user.id,
    });

    if (response.data.tracks.length > 0) {
      // add user latest position
      set({
        trackingSingleUser: {
          user: user,
          lat: response.data?.tracking_latest_user?.lat,
          lng: response.data?.tracking_latest_user?.lng,
        },
      });

      for (const itemm of response.data.tracks) {
        const paths = new google.maps.Polyline({
          path: itemm.trackings as PolyLine[],
          strokeColor: "#da251c",
          strokeOpacity: 1.0,
          strokeWeight: 5,
        });
        paths.setMap(map);
        set({ trackingsMaps: [...get().trackingsMaps, paths] });
      }
    }

    set({ trackingSingleLoading: false });
  },

  trackingsMaps: [],
  setTrackingMaps: () => {
    const maps = [...get().trackingsMaps];
    for (const item of maps) {
      item.setMap(null);
    }
    set({ trackingUsers: [] });
  },
  resetTracking: () => {
    set({
      trackingUsers: [],
      trackingsMaps: [],
    });
  },
}));

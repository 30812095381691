import { ProvinceData } from "../interfaces/province-result.interface";
import create from 'zustand';
import { GetProvince } from "../providers/provinces.provider";

interface ProvinceState {
  provinceLoading: boolean;
  getProvince: () => void;
  provinceItems: ProvinceData[];
}

export const useProvinceStore = create<ProvinceState>((set, get) => ({
  provinceLoading: false,
  getProvince: async () => {
    set({ provinceLoading: true });
    const response = await GetProvince();
    if (response.status) {
      if (response.data.length > 0) {
        set({ provinceItems: response.data });
      } else {
        set({ provinceItems: [] });
      }
    } else {
      set({ provinceItems: [] });
    }
    set({ provinceLoading: false });
  },
  provinceItems: [],
}))
import { AxiosError } from "axios";
import { ApiAuth } from "../../../api/api";
import { GeneralInterface } from "../../../helpers/interfaces/general.interface";
import { MeModelInterface } from "../interfaces/me-model.interface";
import { SuccessLoginInterface } from "../interfaces/success-login.interface";

export const Login = async (
  username: string,
  password: string
): Promise<SuccessLoginInterface> => {
  try {
    const doLogin = await ApiAuth().post("/login-dashboard", {
      username: username,
      password: password,
    });
    return doLogin.data as SuccessLoginInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error.response?.data as GeneralInterface;
  }
};

export const GetMe = async (): Promise<MeModelInterface> => {
  try {
    const doLogin = await ApiAuth().get("/me");
    return doLogin.data as MeModelInterface;
  } catch (e) {
    const error = e as AxiosError;
    return error.response?.data as MeModelInterface;
  }
};

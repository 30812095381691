import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAssignmentStore } from "../../assignments/states/assignment.state";
import HeaderComponent from "../../home/components/header.component";
import { useTrackingStore } from "../states/tracking.state";
import { useSocketStore } from "../../home/states/socket.state";
import { SocketTrackingResultInterface } from "../interfaces/socket-tracking-result.interface";
import { Accordion, Button, Card, useAccordionButton } from "react-bootstrap";
import styles from "../assets/tracking.module.css";
import TrackingMapComponent from "../../maps/components/tracking-maps.component";
import { useTrackingMapStore } from "../../maps/states/tracking-maps.state";
import TrackingDataComponent from "../components/tracking-data.component";
import { useEventPhotoStore } from "../../disaster/states/event-photo.state";
import { useEventVideoStore } from "../../disaster/states/event-video.state";

const TrackingScreen = () => {
  const navigate = useNavigate();
  const { disasterId } = useParams();
  const {
    getTrackings,
    trackingLoading,
    livePolylines,
    setTrackingMaps,
    updateTrackingUser,
    resetTracking,
    trackingUsers,
  } = useTrackingStore();
  const { map } = useTrackingMapStore();
  const { getAssignments, assignments, assignmentLoading } =
    useAssignmentStore();
  const [paths, setPaths] = useState<google.maps.Polyline[]>([]);
  const { socket } = useSocketStore();
  const { getEventPhoto, getTrackingPhoto } = useEventPhotoStore();
  const { getEventVideo, getTrackingVideo } = useEventVideoStore();

  useEffect(() => {
    socket.connect();
    socket.on("receiveTracking", (msg) => {
      console.log(msg);
      const data: SocketTrackingResultInterface = JSON.parse(msg);
      if (data.action === "create") {
        updateTrackingUser(data.user_id, data.lat, data.lng, "#da251c");
      }
    });
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    for (const item of paths) {
      item.setMap(null);
    }
    if (livePolylines?.length > 0) {
      for (const item of livePolylines) {
        const path = new google.maps.Polyline({
          path: item.latLngs,
          strokeColor: item.trailColor ?? `#da251c`,
          strokeOpacity: 1.0,
          strokeWeight: 5,
        });
        path.setMap(map);
        setPaths([...paths, path]);
      }
    }
  }, [livePolylines]);

  useEffect(() => {}, [paths]);

  useEffect(() => {
    return () => {
      setTrackingMaps();
      for (const item of paths) {
        item.setMap(null);
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      setTrackingMaps();
      for (const item of paths) {
        item.setMap(null);
      }
    };
  }, []);

  useEffect(() => {
    if (disasterId !== undefined) {
      getAssignments({
        size: 100,
        page: 0,
        event_id: disasterId,
      });
    }
  }, [disasterId]);

  // useEffect(() => {
  //   if (disasterId !== undefined) {
  //     getTrackingPhoto({
  //       event_id: disasterId,
  //       page: 0,
  //       size: 1000,
  //     });
  //     getTrackingVideo({
  //       event_id: disasterId,
  //       page: 0,
  //       size: 1000,
  //     });
  //   }
  // }, [disasterId]);

  useEffect(() => {
    if (
      disasterId !== undefined &&
      assignments.length > 0 &&
      map !== undefined
    ) {
      const users = assignments.map((item) => item.user);
      getTrackings(map, disasterId, users);
      getTrackingPhoto({
        event_id: disasterId,
        page: 0,
        size: 1000,
      });
      getTrackingVideo({
        event_id: disasterId,
        page: 0,
        size: 1000,
      });
    }
  }, [disasterId, assignments, map]);

  return (
    <>
      <HeaderComponent />
      <TrackingMapComponent />
      <TrackingDataComponent />
      <div
        style={{
          bottom: 0,
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {trackingLoading ? (
            <Button
              style={{
                marginBottom: 5,
                marginLeft: 5,
                bottom: 0,
                cursor: "pointer",
                position: "absolute",
                backgroundColor: "rgb(218, 37, 28)",
                border: "2px solid rgb(218, 37, 28)",
                borderRadius: "4px",
              }}
              disabled
            >
              <div
                style={{
                  fontSize: 12,
                  padding: "2px 8px",
                  color: "white",
                }}
              >
                <span
                  style={{
                    fontSize: 18,
                  }}
                >
                  Memuat Data...
                </span>
              </div>
            </Button>
          ) : (
            <Button
              style={{
                marginBottom: 5,
                marginLeft: 5,
                bottom: 0,
                cursor: "pointer",
                position: "absolute",
                backgroundColor: "rgb(218, 37, 28)",
                border: "2px solid rgb(218, 37, 28)",
                borderRadius: "4px",
              }}
              onClick={(e) => {
                navigate(-1);
                resetTracking();
              }}
            >
              <div
                style={{
                  fontSize: 12,
                  padding: "2px 8px",
                  color: "white",
                }}
              >
                <span
                  style={{
                    fontSize: 18,
                  }}
                >
                  Hentikan Lacak Posisi
                </span>
              </div>
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default TrackingScreen;
